import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UserCard from '../UserCard/UserCard';
import { connect } from 'react-redux';
import { deleteUser, activateUser, deactivateUser } from '../../actions/profileAction';
import { confirmAlert } from 'react-confirm-alert';
import {Link} from 'react-router-dom';

class ProfileItem extends Component {
  onDeleteClick(id) {
    confirmAlert({
      title: 'Suspenduj korisnika',
      message: 'Da li sigurno zelite da suspendujete?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (this.props.deactivateUser(id))
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  onActivateClick(id) {
    confirmAlert({
      title: 'Aktiviraj korisnika',
      message: 'Da li sigurno zelite da aktivirate?',
      buttons: [
        {
          label: 'Da',
          onClick: () => (this.props.activateUser(id))
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }

  render() {
    const { profile } = this.props;
   //console.log(profile);
    const profilestats =
      profile.deleted === 0 ? (
        <button
          style={{ marginLeft: '3px' }}
          onClick={this.onDeleteClick.bind(this, profile._id)}
          className="btn-fill btn btn-sm btn-danger"
        >
          Suspenduj
        </button>
      ) : (
        <button
          style={{ marginLeft: '3px' }}
          onClick={this.onActivateClick.bind(this, profile._id)}
          className="btn-fill btn btn-sm btn-primary"
        >
          Aktiviraj
        </button>
      );
    
    const profileedit =
        <Link
          style={{ marginLeft: '3px'}}
          to={`/profiles/${profile._id}`}
          className="btn-fill btn btn-sm btn-primary">
          Izmeni nalog
        </Link>

    return (
      <UserCard
        bgImage="https://www.ambientum.com/wp-content/uploads/2018/08/via-lactea.jpg"
        avatar={profile.avatar}
        name={profile.name}
        userName={profile.email}
        description={
          <span>
            Nav sifra: {profile.NavCode}
            <br />
            {profile.position === 'Dist' ? (
                <span>
                </span>) : (
                <span>
                </span>
              )
            }
            <br />
            {profile.position === 'PM' ? (
              <span
                className="btn-fill btn-xs btn-round btn-warning"
                style={{ marginLeft: '3px' }}
              >
              Pozicija: Korisnik
              </span>) : (
              <span
                className="btn-fill btn-xs btn-round btn-warning"
                style={{ marginLeft: '3px' }}
              >
                Pozicija: {profile.position}
              </span>)
            }
            <br />
            <br />
            {this.props.auth.user.position === 'Admin' ? profilestats : null}
            {this.props.auth.user.position === 'Admin' ? profileedit : null}
          </span>
        }
        socials={
          <div>
            {profile.deleted === 0 ? (
              <span
                className="btn-fill btn-xs btn-primary"
                style={{ marginLeft: '3px' }}
              >
                Nalog je Aktivan
              </span>
            ) : (
              <span
                className="btn-fill btn-xs btn-danger"
                style={{ marginLeft: '3px' }}
              >
                Nalog je Suspendovan
              </span>
            )}
          </div>
        }
      />
    );
  }
}

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  activateUser: PropTypes.func.isRequired,
  deactivateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(
  mapStateToProps,
  { deleteUser, activateUser, deactivateUser }
)(ProfileItem);

import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getLicenceIdHistory } from '../../actions/clientActions';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Card from '../Card/Card';
import { Link } from 'react-router-dom';


class HistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    if (this.props.match.params.cbLicenceId) {
      this.props.getLicenceIdHistory(this.props.match.params.cbLicenceId);
    }
  }
  render() {
    const { loading } = this.props.history;
    const clientHistory = this.props.history.historyOfLicence;

    let historyItems;
    if (clientHistory === null || loading) {
      historyItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );

    } else {
      if (clientHistory.length > 0) {
        historyItems = clientHistory.map(history => (
          <tr key={history._id}>
            <td>{history.DateOfStatus.split('T00:00:00.000Z')}</td>
            <td>{(() => {
              switch (history.licenceStatus) {
                case 'Aktivna':
                  return (
                    <span className="text-success">
                      Aktivrana
                    </span>
                  );
                case 'Neaktivna':
                  return (
                    <span className="text-danger">
                      Deaktivirana
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td>{history.NavCode}</td>
          </tr>
        ));
      }
      else {
        historyItems = (
          <tr>
            <td>Nema stavki za prikaz...</td>
          </tr>
        );
      }
    }

    return (
      <div className='content'>
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title="Istorija licence"
                category="Informacije o istoriji licence"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Datum promene statusa</th>
                        <th>Postavljeni status</th>
                        <th>Nav sifra promenioca statusa</th>
                      </tr>
                    </thead>
                    <tbody>{historyItems}</tbody>
                  </Table>
                }
              />

            </Col>
            <div className="row">
              <div className="col-md-6">
                <Link
                  to="/clients"
                  className="btn-fill col-md-12 pull-center btn btn-default"
                  style={{ margin: '3px' }}
                >
                  Nazad
                </Link>
              </div>
            </div>
            <div className="clearfix"> </div>
          </Row>
        </Container>
      </div>
    );
  }
}

HistoryTable.propTypes = {
  getLicenceIdHistory: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  history: state.history
});

export default connect(
  mapStateToProps,
  { getLicenceIdHistory }
)(HistoryTable);
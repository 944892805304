import {
    GET_VENDOR_COUNTER,
    GET_SIM_DIST_COUNTER,
    STATISTICS_LOADING,
    GET_DIST_COUNTER
} from '../actions/types';

const initialState = {
    statDistCounter: [],
    statVenCounter: [],
    statDistCounterSim: [],
    statisticTable: {},
    statistics: {},
    statisticsDistCount: {},
    statistic: {},
    loading: false
}
export default function FuncState(state = initialState, action) {
    switch (action.type) {
        case GET_DIST_COUNTER:
            return {
                ...state,
                statDistCounter: action.payload,
                loading: false
            };
        case STATISTICS_LOADING:
            return {
                ...state,
                loading: true
            };
        case GET_VENDOR_COUNTER:
            return {
                ...state,
                statVenCounter: action.payload,
                loading: false
            };
        case GET_SIM_DIST_COUNTER: 
        return {
            ...state,
            statDistCounterSim: action.payload,
            loading:false
        }
        default:
            return state;
    }
}
import axios from 'axios';
import Swal from 'sweetalert2';
import {
	GET_ERRORS,
	GET_ALL_INVOICES,
	INVOICES_LOADING,
	GET_NUMBER_OF_E_INVOICES,
	GET_ALL_INVOICES_VENDOR,
	GET_ALL_INVOICES_PAGE,
	GET_INVOICE_BY_ID,
} from './types';
// Change invoice data
export const changeInvoiceData = (data) => dispatch => {
	dispatch(setInvoiceLoading());
	axios
	.post(`/api/invoices/changeInvoiceData/`, data)
	.then(res => {
		Swal.fire({
			position: 'top-end',
			icon: 'success',
			title: 'Izmene su sačuvane',
			showConfirmButton: false,
			timer: 3000
		})
		setTimeout(function () {
			window.location.reload()
		}, 3000);
	})
	.catch(err => {
		Swal.fire({
			position: 'top-end',
			icon: 'error',
			title: 'Greška',
			showConfirmButton: false,
			timer: 3000
		})
		setTimeout(function () {
			window.location.reload()
		}, 3000);
	})
}

//Get invoice by id
export const getInvoiceById = (_id) => dispatch => {
	dispatch(setInvoiceLoading());
	axios
		.get(`/api/invoices/getInvoiceById/${_id}`)
		.then(res => {
			dispatch({
				type: GET_INVOICE_BY_ID,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_INVOICE_BY_ID,
				payload: null
			})
		})
}

//Get number of e invoice licences
export const getNumberOfEInvoices = () => dispatch => {
	dispatch(setInvoiceLoading());
	axios
		.get('/api/invoices/getNumberOfEInvoices')
		.then(res => {
			dispatch({
				type: GET_NUMBER_OF_E_INVOICES,
				payload: res.data
			})
		}).catch(err => {
			dispatch({
				type: GET_NUMBER_OF_E_INVOICES,
				payload: null
			})
		})
}

//Add invoice user
export const createInvoice = (clientData, history) => dispatch => {
	axios
		.post('/api/invoices', clientData)
		.then(console.log(clientData))
		.then(res => history.push('/iconfirmation')
		)
		.catch(err =>
			dispatch({
				type: GET_ERRORS,
				payload: err.response.data
			})
		);
};
//get all invoices search by vatid and navcode
export const getAllInvoices = (WhatToLookFor, WhereToLookFor) => dispatch => {
	dispatch(setInvoiceLoading());
	axios
		.get(`/api/invoices/getAllInvoices/${WhatToLookFor}/${WhereToLookFor}`)
		.then(res => {
			dispatch({
				type: GET_ALL_INVOICES,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_ALL_INVOICES,
				payload: null
			})
		})
}
//get all invoices with pagination
export const getAllInvoicesPage = (page, perPage) => dispatch => {
	dispatch(setInvoiceLoading());
	axios
		.get(`/api/invoices/getAllInvoicesPage/${page}/${perPage}`)
		.then(res => {
			dispatch({
				type: GET_ALL_INVOICES_PAGE,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_ALL_INVOICES_PAGE,
				payload: null
			})
		})
}
//get all invoices searched by NavCode (vendor)
export const getAllInvoicesVendor = NavCode => dispatch => {
	dispatch(setInvoiceLoading());
	axios
		.get(`/api/invoices/getAllInvoicesVendor/${NavCode}`)
		.then(res => {
			dispatch({
				type: GET_ALL_INVOICES_VENDOR,
				payload: res.data
			})
		})
		.catch(err => {
			dispatch({
				type: GET_ALL_INVOICES_VENDOR,
				payload: null
			})
		})
}

export const setInvoiceLoading = () => {
	return {
		type: INVOICES_LOADING
	};
};
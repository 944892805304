import axios from 'axios';
import {
    GET_PROMOCODE,
    GET_PROMOCODES,
    PROMOCODES_LOADING
} from './types';

// Get all promo codes
export const getPromoCodes = () => dispatch => {
    dispatch(setPromoCodesLoading());
    axios
        .get('/api/promocodes/all')
        .then(res => 
            dispatch({
                type: GET_PROMOCODES,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_PROMOCODES,
                payload: null
            })
        );
}; 

// Get promo code by id
export const getPromoCodesById = _id => dispatch => {
    dispatch(setPromoCodesLoading());
    axios
        .get(`/api/promocodes/${_id}`)
        .then(res =>
            dispatch({
                type: GET_PROMOCODE,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_PROMOCODE,
                payload: null
            })
        );
};

// promo codes loading
export const setPromoCodesLoading = () => {
    return {
        type: PROMOCODES_LOADING
    };
}
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  GET_DEBTOR,
  GET_ERRORS,
  CLIENTS_LOADING,
  GET_CLIENT,
  GET_DELETED_CLIENTS,
  GET_CLIENT_BY_COMPID,
  GET_CLIENTS_BY_VENDOR,
  GET_CLIENTS_BY_VENDOR_COUNTER,
  GET_LICENCEID_HISTORY,
  GET_DELETED_CLIENTS_BY_DATE,
  GET_DELETED_CLIENTS_BY_VENDOR,
  GET_CLIENTS_CALL_HISTORY,
  GET_ALL_PERSONAL_LOGS,
  GET_ALL_LOGS_BY_DATE,
  GET_ALL_UNSEEN_LOGS,
} from './types';

// gel all responses from chief
export const getUnseenLogs = () => dispatch => {
  axios.post(`/api/utility/getAllUnseenComments/`)
    .then(res => {
      dispatch({
        type: GET_ALL_UNSEEN_LOGS,
        payload: res.data
      })
    })
    .catch(error => {
      dispatch({
        type: GET_ALL_UNSEEN_LOGS,
        payload: null
      })
    })
}

// Change Client
export const createClient = (clientData, history, userRole, userName) => dispatch => {
  axios
    .post(`/api/clients/licenceChange/${userRole}/${userName}`, clientData)
    .then(res => {
      history.push(`/`);
      window.location.reload();
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Send warning mail
export const sendWarningMail = (usermail, iznos, faktura) => dispatch => {
  axios
    .post(`/api/utility/sendMail/`, { iznos: iznos, usermail: usermail, faktura: faktura })
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Mail je uspešno poslat',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    }
    )
    .catch(error => {
      Swal.fire({
        position: 'top-end',
        icon: 'eror',
        title: 'Mail nije poslat',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    }
    );
}
// Get client by VendorName
export const getClientsByVendorOnCall = (searchCriteria, searchWhat) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/vendor/getClientsByVendorOnCall/${searchCriteria}/${searchWhat}`)
    .then(res =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: null
      })
    );
};

// Get client by VendorName
export const getClientsByVendor = (vendorName, searchCriteria, page, perPage) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/vendor/getClientsByVendor/${vendorName}/${searchCriteria}/${page}/${perPage}`)
    .then(res =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: null
      })
    );
};

// Create new licence
export const createLicence = (licenceData, history) => dispatch => {
  axios
    .post('/api/clients/newLicence/', licenceData)
    .then(res => {
      console.log(res.data._id)
      const data = {
        id: res.data._id
      }
      axios.post(`/api/clients/checkIfIdExist/`, data)
        .then(res => {
          console.log(res.data)
          if (res.data.code === true) {
            history.push(`/confirmation`)
          } else {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Licenca nije aktivirana, pokušajte ponovo. Ukoliko se problem ponovi kontaktirajte tehničku podršku na 0800 111 123',
              showConfirmButton: false,
              timer: 8000
            })
          }
        })
        .catch(err => {

          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Greška',
            showConfirmButton: false,
            timer: 8000
          })
          console.log(err)
        })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Get client bt compVatId
export const getClientByCompVatId = (compVatId) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/clientsGetById/clientCompVatId/${compVatId}`)
    .then(res =>
      dispatch({
        type: GET_CLIENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENT,
        payload: null
      })
    );
};

export const getClientByCompVatIdDist = (compVatId, NavCode) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/clientsGetById/clientCompVatIdDist/${compVatId}/${NavCode}`)
    .then(res =>
      dispatch({
        type: GET_CLIENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENT,
        payload: null
      })
    );
};

// Get all debtors
export const getClients = (page, perPage) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/admin/all/things/${page}/${perPage}`)
    .then(res =>
      dispatch({
        type: GET_DEBTOR,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DEBTOR,
        payload: null
      })
    );
};

// Get all clients
export const getClientsByNavCode = (NavCode, page) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/admin/all/byNavCode/${NavCode}/${page}`)
    .then(res =>
      dispatch({
        type: GET_DEBTOR,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_DEBTOR,
        payload: null
      })
    );
};

// Get client by compid
export const getClientByCompId = (whatToLook, whereToLook) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/admin/galebSearch/${whatToLook}/${whereToLook}`)
    .then(res =>
      dispatch({
        type: GET_CLIENT_BY_COMPID,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENT_BY_COMPID,
        payload: null
      })
    );
};

// Get client by id
export const getClientById = _id => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/clientsGetById/${_id}`)
    .then(res =>
      dispatch({
        type: GET_CLIENT,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENT,
        payload: null
      })
    );
};

//Change licence
export const changeLicence = (cbLicenceId, lincenceStatus) => dispatch => {
  axios
    .post(`/api/clients/changeLicence/${cbLicenceId}/${lincenceStatus}`,)
    .then(res => {
      console.log(res.request.statusText)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Status licence je uspešno promenjen',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    }
    )
    .catch(error => {
      console.log(error)
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.request.response,
        showConfirmButton: false,
        timer: 3000
      })
    }
    );
};

// getDeletedClients
export const getDeletedClients = () => dispatch => {
  axios
    .get(`/api/clients/getDeletedClients`)
    .then(res =>
      dispatch({
        type: GET_DELETED_CLIENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
};

//get deleted clients by vatid and sn
export const getDeletedClientsByFilter = (filter) => dispatch => {
  axios
    .post(`/api/admin/getDeletedClientsByFilter/${filter}`)
    .then(res =>
      dispatch({
        type: GET_DELETED_CLIENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
}
// get deleted clients by date ie from 10.10.2022 to 20.10.2022
export const getDeletedClientsByDate = (searchCriteria) => dispatch => {
  axios
    .post(`/api/admin/getDeteledClientsByDate`, searchCriteria)
    .then(res => {
      dispatch({
        type: GET_DELETED_CLIENTS_BY_DATE,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_DELETED_CLIENTS_BY_DATE,
        payload: null
      })
    })
}
// get deleted clients by date ie from 10.10.2022 to 20.10.2022
export const getDeletedClientsByVendorSearch = (NavCode) => dispatch => {
  axios
    .post(`/api/admin/getDeletedClientsByVendorSearch/${NavCode}`)
    .then(res => {
      dispatch({
        type: GET_DELETED_CLIENTS_BY_VENDOR,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_DELETED_CLIENTS_BY_VENDOR,
        payload: null
      })
    })
}
//get deleted clients by vatid and sn
export const getDeletedClientsByVendor = (filter) => dispatch => {
  axios
    .post(`/api/vendor/getDeletedClientsByVendor/${filter}`)
    .then(res =>
      dispatch({
        type: GET_DELETED_CLIENTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: null
      })
    );
}
//Download excel file with vendorName parameter
export const downloadExcel = (vendorName) => dispatch => {
  var fileDownload = require('js-file-download');
  dispatch(setClientsLoading());
  axios
    .get(`/api/utility/downloadExcel/${vendorName}`, { responseType: 'blob' })
    .then(res => {
      var currentDate = new Date();
      var date = new Intl.DateTimeFormat('en-GB').format(currentDate);
      var filename = "Spisak licenci " + date + ".xlsx";
      fileDownload(res.data, filename);
    })
    .catch(err => {
      console.log('Greska prilikom preuzimanja fajla')
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    })
};

// Delete Client
export const deleteClient = (removalRequest, history) => dispatch => {
  axios
    .post(`/api/utility/deleteClient/`, removalRequest)
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Brisanje je uspešno',
        showConfirmButton: false,
        timer: 3000
      })
      history.push(`/`);
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err
      })
    );
};

// Get number of vendor devices
export const getClientsByVendorCounter = (vendorName) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/vendor/getClientsByVendorCounter/${vendorName}`)
    .then(res =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR_COUNTER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR_COUNTER,
        payload: null
      })
    );
};

// Get all clients by vendor
export const exportExcelVendorClients = (vendorName, userMail) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post('/api/utility/exportExcelVendorClients/', { vendorName: vendorName, userMail: userMail })
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Mail sa spiskom licenci je uspešno poslat na Vaš mail',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    })
    .catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška, mail nije poslat!',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    })
};
// Kompenzacija
export const clientCompensation = (id, ime) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post(`/api/utility/clientCompensation/${id}/${ime}`)
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Uspešno',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    }
    )
    .catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška, pokušajte ponovo kasnije!',
        showConfirmButton: false,
        timer: 3000
      })
    }
    );
};

// Zasticen
export const clientProtected = (id, ime) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post(`/api/utility/clientProtected/${id}/${ime}`)
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Uspešno',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    }
    )
    .catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška, pokušajte ponovo kasnije!',
        showConfirmButton: false,
        timer: 3000
      })
    }
    );
};

// Platio
export const debtorPaid = (id, ime) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post(`/api/utility/debtorPaid/${id}/${ime}`)
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Uspešno',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
    }
    )
    .catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška, pokušajte ponovo kasnije!',
        showConfirmButton: false,
        timer: 3000
      })
    }
    );
};

// get licenceid history
export const getLicenceIdHistory = id => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/utility/getLicenceIdHistory/${id}`)
    .then(res =>
      dispatch({
        type: GET_LICENCEID_HISTORY,
        payload: res.data
      })
    ).catch(err =>
      dispatch({
        type: GET_LICENCEID_HISTORY,
        payload: null
      })
    );
}

export const getSendRemovalMail = (removalRequest, history) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post(`/api/utility/getSendRemovalMail/`, removalRequest)
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Zahtev za brisanje licence je poslat',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        history.push(`/`)
      }, 3000);
    })
    .catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška, pokušajte ponovo kasnije!',
        showConfirmButton: false,
        timer: 3000
      })
    });
}

export const getClientsByVendorFiltered = (vendorName, licenceStatus) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post(`/api/vendor/getClientsByVendorFiltered/`, { vendorName: vendorName, licenceStatus: licenceStatus })
    .then(res =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: res.data
      })
    ).catch(err =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: null
      })
    );
}
export const getClientFiltered = (licenceStatus) => dispatch => {
  dispatch(setClientsLoading())
  axios.post(`/api/vendor`)
}

export const getClietnsSimCardStats = (vendorName, filter) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post(`/api/vendor/getClietnsSimCardStats/${vendorName}/${filter}`)
    .then(res =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: res.data
      })
    ).catch(err =>
      dispatch({
        type: GET_CLIENTS_BY_VENDOR,
        payload: null
      })
    );
}
// izvestaj petkovaca
export const getFridayStats = () => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/reportMail/FridayStatistic/`)
    .then(res => {
      console.log('hello')
    })
    .catch(err => {
      console.log('sad hello')
    })
}

// za evidenciju poziva ko je zvao sto je zvao i ko se javio
export const createNewHistoryLog = (data, navCode) => dispatch => {
  dispatch(setClientsLoading());
  axios
    .post(`/api/utility/createHistoryLog/${navCode}`, data)
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Zapis unet',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload();
      }, 3000);
    })
    .catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška',
        showConfirmButton: false,
        timer: 3000
      })
    })
}

// procitaj sve evidenciej za pib
export const getAllHistoryLogs = (_id) => dispatch => {
  dispatch(setClientsLoading())
  axios
    .post(`/api/utility/getAllLogsForVatId/${_id}`)
    .then(res => {
      dispatch({
        type: GET_CLIENTS_CALL_HISTORY,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_CLIENTS_CALL_HISTORY,
        payload: null
      })
    })
}

// menja status u history log u resen
export const callIsSolved = id => dispatch => {
  //dispatch(setClientsLoading())
  axios
    .post(`/api/utility/solvedCall/${id}`)
    .then(res => {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Status promenjen',
        showConfirmButton: false,
        timer: 3000
      })
      //setTimeout(function () {
      //  window.location.reload();
      //}, 3000);
    })
    .catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška',
        showConfirmButton: false,
        timer: 3000
      })
    })
}
//get excel file with deleted licences between two dates with reasan for 
export const getDeletedLicences = (data) => dispatch => {
  dispatch(setClientsLoading())
  var fileDownload = require('js-file-download');
  axios
    .post(`/api/statistics/getDeletedLicences/`, data, { responseType: 'blob' })
    .then(res => {
      var currentDate = new Date();
      var date = new Intl.DateTimeFormat('en-GB').format(currentDate);
      var filename = "Spisak obrisanih licenci " + date + ".xlsx";
      fileDownload(res.data, filename);
    }).catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška',
        showConfirmButton: false,
        timer: 3000
      })
    })
}

// get excel file with new licences between two dates 
export const getNewLicences = (data) => dispatch => {
  dispatch(setClientsLoading())
  const fileDownload = require('js-file-download');
  axios
    .post(`/api/statistics/getNewLicences/`, data, { responseType: 'blob' })
    .then(res => {
      const currentDate = new Date();
      const date = new Intl.DateTimeFormat('en-GB').format(currentDate);
      const filename = "Spisak novih licenci " + date + ".xlsx";
      fileDownload(res.data, filename);
    }).catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška',
        showConfirmButton: false,
        timer: 3000
      })
    })
}

// get excel file with new licences between two dates 
export const getDBRep = (data) => dispatch => {
  dispatch(setClientsLoading())
  const fileDownload = require('js-file-download');
  axios
    .post(`/api/statistics/getDBRep/`, data, { responseType: 'blob' })
    .then(res => {
      const currentDate = new Date();
      const date = new Intl.DateTimeFormat('en-GB').format(currentDate);
      const filename = "Trenutno stanje " + date + ".xlsx";
      fileDownload(res.data, filename);
    }).catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Greška',
        showConfirmButton: false,
        timer: 3000
      })
    })
}

// get all logs for an agent
export const getPersonalLogs = NavCode => dispatch => {
  dispatch(setClientsLoading());
  axios
    .get(`/api/utility/getPersonalLogs/${NavCode}`)
    .then(res => {
      dispatch({
        type: GET_ALL_PERSONAL_LOGS,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_PERSONAL_LOGS,
        payload: null
      })
    })
}

//get logs from between 2 dates
export const getLogsByDates = (dates) => dispatch => {
  dispatch(setClientsLoading())
  axios
    .post(`/api/utility/getLogsByDates`, dates)
    .then(res => {
      dispatch({
        type: GET_ALL_LOGS_BY_DATE,
        payload: res.data
      })
    })
    .catch(err => {
      dispatch({
        type: GET_ALL_LOGS_BY_DATE,
        payload: null
      })
    })
}

// clients loading
export const setClientsLoading = () => {
  return {
    type: CLIENTS_LOADING
  };
};

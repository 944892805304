import {
  GET_ALL_INVOICES,
  GET_ALL_INVOICES_PAGE,
  ADD_INVOICE,
  CLIENTS_LOADING,
  GET_NUMBER_OF_E_INVOICES,
  GET_ALL_INVOICES_VENDOR,
  GET_INVOICE_BY_ID,
} from '../actions/types';

const initialState = {
  getNumberOfEInvoices: [],
  getAllInvoicesVendor: [],
  getAllInvoices: [],
  getAllInvoicesPage: [],
  invoices: [],
  invoice: {},
  loading: false
};

export default function FuncState(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_INVOICES_VENDOR:
      return {
        ...state,
        getAllInvoicesVendor: action.payload,
        loading: false
      }
    case GET_NUMBER_OF_E_INVOICES:
      return {
        ...state,
        getNumberOfEInvoices: action.payload,
        loading: false
      }
    case GET_ALL_INVOICES:
      return {
        ...state,
        getAllInvoices: action.payload,
        loading: false
      }
    case GET_ALL_INVOICES_PAGE:
      return {
        ...state,
        getAllInvoicesPage: action.payload,
        loading: false
      }
    case CLIENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_INVOICE_BY_ID:
      return {
        ...state,
        invoice: action.payload,
        loading: false
      };
    case ADD_INVOICE:
      return {
        ...state,
        invoice: [action.payload, ...state.invoices]
      };
    default:
      return state;
  }
}

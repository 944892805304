import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';
import classnames from 'classnames';
import { getzipCodes } from '../../actions/zipCodesActions';
import SelectListGroup from '../common/SelectListGroup';
import isEmpty from '../../validation/is-empty';
import Select from 'react-select';
import { getCurrentProfile, getDKR } from '../../actions/profileAction';
import axios from 'axios';
import Swal from 'sweetalert2'
const Validator = require('validator');

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

class NewLicenceRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compName: '',
      compVatId: '',
      compId: '',
      activityCode: '',
      activityName: '',
      compAddrStreet: '',
      compAddrNumber: '',
      compAddrZip: '',
      compAddrCity: '',
      compPhone: '',
      compEmail: '',
      deviceAddress: '',
      deviceAddrZip: '',
      deviceAddrCity: '',
      deviceVendor: '',
      vendorName: '',
      licence_id: '',
      selectedVendor: null,
      fetchedClient: null,
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    //
    document.getElementById("btn").disabled = true;
    setTimeout(function () { document.getElementById("btn").disabled = false; }, 1000);
    //

    if (this.state.licence_id.length != 12 && this.state.licence_id.length != 16) {
      Toast.fire({
        icon: 'error',
        title: "Neispravna dužina licence"
      })
      return;
    }
    const newClient = {
      activityCode: (' ' + this.state.activityCode).trim(),
      compName: Validator.stripLow(this.state.compName.trim()),
      compVatId: Validator.stripLow(this.state.compVatId.trim()),
      compId: Validator.stripLow(this.state.compId.trim()),
      compAddrStreet: Validator.stripLow(this.state.compAddrStreet.trim()),
      compAddrNumber: Validator.stripLow(this.state.compAddrNumber.trim()),
      compAddrZip: this.state.compAddrZip,
      compAddrCity: this.state.compAddrCity,
      compPhone: Validator.stripLow(this.state.compPhone.trim()),
      compEmail: Validator.normalizeEmail(this.state.compEmail.trim(), { gmail_remove_dots: false }),
      deviceAddress: Validator.stripLow(this.state.deviceAddress.trim()),
      deviceAddrZip: this.state.deviceAddrZip,
      vendorName: this.state.vendorName,
      deviceAddrCity: this.state.deviceAddrCity,
      deviceVendor: this.state.deviceVendor,
      licence_id: this.state.licence_id
    };

    console.log(newClient);
    axios.post(`/api/NewLicences/newLicence/updated/`, newClient)
      .then(resp => {
        Toast.fire({
          icon: 'success',
          title: resp.data.message,
        })
      })
      .catch(error => {
        Toast.fire({
          icon: 'error',
          title: error.response.data.error
        })
        console.error(error);
      })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentDidMount() {
    this.props.getzipCodes();
    this.props.getCurrentProfile();
    this.props.getDKR();
    const userPosition = this.props.auth.user.position
    if (userPosition === 'Dist' || userPosition === 'Komercijalista' || userPosition === 'RegOnly') {
      this.setState({ vendorName: this.props.auth.user.NavCode })
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const name = e.target.name;
      const value = e.target.value
      if (name == 'activityCode' && value.length == 4) {
        const payLoad = { sifra: value };
        axios.post(`/api/activityname/activity/`, payLoad)
          .then(resp => {
            this.setState({ activityName: resp.data });
          })
          .catch(error => {
            console.error(error);
          })
      }
      if (name == 'compVatId' && value.length == 9) {
        axios.get(`/api/clientsGetById/clientCompVatId/${value}`)
          .then(response => {
            const client = response.data;
            this.setState({
              compName: client.compName,
              compId: client.compId,
              compAddrStreet: client.compAddrStreet,
              compAddrZip: client.compAddrZip,
              compAddrNumber: client.compAddrNumber,
              compEmail: client.compEmail,
              userFirstName: client.userFirstName,
              userLastName: client.userLastName,
              userEmail: client.userEmail,
              // compPhone: client.compPhone,
              // userPhone: client.userPhone,
              activityCode: client.activityCode,
            });

          })
          .catch(error => {
            console.error(error);
          });
      }
    })
  }

  handleChange = selectedVendor => {
    this.setState({ selectedVendor });
    this.setState({ vendorName: selectedVendor !== null ? selectedVendor.value : '' });
    console.log(selectedVendor.value)
  };

  render() {
    const userPosition = this.props.auth.user.position
    const { errors } = this.state;
    const { zipcodes } = this.props.zipcode;
    const profile = this.props.profile.profiles;

    // console.log(this.props)

    let { optionsVendors, selectedVendor } = this.state;
    const optionszipCodes = zipcodes.map(zipcodes => ({
      label: zipcodes.addrZip,
      value: zipcodes.addrZip
    }));

    let optionsSoftware = [];
    optionsSoftware.push({ label: 'Odaberite softver *', value: '' });
    optionsSoftware.push({ label: "LPFR", value: "LPFR" })
    optionsSoftware.push({ label: "ESIR", value: "ESIR" })
    optionsSoftware.push({ label: "LPFR i ESIR", value: "LPFRiESIR" })
    optionszipCodes.unshift({ label: '* Izaberite poštanski br.', value: '' });

    optionsVendors = profile.map(profile => ({
      label: profile.name,
      value: profile.NavCode
    }))

    optionsVendors.push({ label: "Nada Ćosović", value: "790" });
    optionsVendors.push({ label: "Srđan Perić", value: "2133" })

    const notDist = userPosition !== 'Dist' && userPosition !== 'Komercijalista' && userPosition !== 'RegOnly' ?
      <div className="col-md-6">
        <label className="control-label">Prodavac</label>

        <Select
          defaultValue=""
          placeholder="Selektuj prodavca..."
          isClearable
          name='vendorName'
          value={selectedVendor}
          onChange={this.handleChange}
          options={optionsVendors}
        />
        <div className="text-danger">
          {errors.vendorName}
        </div>
      </div> : null
    const SearchBar = userPosition === 'Dist' || userPosition === 'Komercijalista' || userPosition === 'PM' || this.props.auth.position === 'Admin' ?
      <Card
        title="Pib za pretragu"
        content={
          <TextFieldGroup
            label="Pib za pretragu"
            placeholder="Pib za pretragu"
            name="compVatId"
            value={this.state.compVatId}
            onChange={this.onChange}
          />
        }
      />
      : null
    const city = this.props.zipcode.zipcodes.filter(zipcodes => {
      return !isEmpty(this.state.compAddrZip)
        ? zipcodes.addrZip
          .toLowerCase()
          .indexOf(this.state.compAddrZip.toLowerCase()) !== -1
        : zipcodes;
    });

    if (!isEmpty(this.state.compAddrZip)) {
      // eslint-disable-next-line
      this.state.compAddrCity = city[0].addrCity;
    };

    const devicecity = this.props.zipcode.zipcodes.filter(zipcodes => {
      return !isEmpty(this.state.deviceAddrZip)
        ? zipcodes.addrZip
          .toLowerCase()
          .indexOf(this.state.deviceAddrZip.toLowerCase()) !== -1
        : zipcodes;
    });

    if (!isEmpty(this.state.deviceAddrZip)) {
      // eslint-disable-next-line
      this.state.deviceAddrCity = devicecity[0].addrCity;
    };

    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              {SearchBar}
              <Card
                title="Podaci o preduzeću"
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Naziv preduzeća *"
                          placeholder="Naziv Preduzeća"
                          name="compName"
                          value={this.state.compName}
                          onChange={this.onChange}
                          error={errors.compName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="PIB *"
                          placeholder="PIB"
                          name="compVatId"
                          value={this.state.compVatId}
                          onChange={this.onChange}
                          error={errors.compVatId}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Matični broj *"
                          placeholder="Matični broj"
                          name="compId"
                          value={this.state.compId}
                          onChange={this.onChange}
                          error={errors.compId}
                        />
                      </div>
                    </div>

                    <div className='row'>
                      <div className="col-md-3">
                        <TextFieldGroup
                          label="Šifra delatnosti *"
                          placeholder="Šifra delatnosti"
                          name="activityCode"
                          value={this.state.activityCode}
                          onChange={this.onChange}
                          error={errors.activityCode}
                        />
                      </div >
                      <div className="col-md-9">
                        <TextFieldGroup
                          label="Naziv delatnosti"
                          placeholder="Naziv delatnosti"
                          name="activityName"
                          disabled
                          value={this.state.activityName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <TextFieldGroup
                          label="Adresa *"
                          placeholder="Adresa"
                          name="compAddrStreet"
                          value={this.state.compAddrStreet}
                          onChange={this.onChange}
                          error={errors.compAddrStreet}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Broj *"
                          placeholder="Broj"
                          name="compAddrNumber"
                          value={this.state.compAddrNumber}
                          onChange={this.onChange}
                          error={errors.compAddrNumber}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <SelectListGroup
                          labels="Poštanski broj *"
                          placeholder="Poštanski broj"
                          name="compAddrZip"
                          value={this.state.compAddrZip}
                          options={optionszipCodes}
                          onChange={this.onChange}
                          error={errors.compAddrZip}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="compAddrCity"
                          value={this.state.compAddrCity}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon"
                          placeholder="Telefon"
                          name="compPhone"
                          value={this.state.compPhone}
                          onChange={this.onChange}
                          error={errors.compPhone}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="E-mail adresa preduzeća *"
                          placeholder="E-mail adresa preduzeća"
                          name="compEmail"
                          type="compEmail"
                          value={this.state.compEmail}
                          onChange={this.onChange}
                          error={errors.compEmail}
                        />
                      </div>
                    </div>
                  </form>
                }
              />
              <Card
                title="Podaci o softveru"
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Licenca *"
                          placeholder="Id licence"
                          name="licence_id"
                          value={this.state.licence_id}
                          onChange={this.onChange}
                          error={errors.licence_id}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <SelectListGroup
                          labels="Poštanski broj *"
                          placeholder="Poštanski broj"
                          name="deviceAddrZip"
                          value={this.state.deviceAddrZip}
                          options={optionszipCodes}
                          onChange={this.onChange}
                          error={errors.deviceAddrZip}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="deviceAddrCity"
                          value={this.state.deviceAddrCity}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Adresa lokacije na kojoj se uređaj nalazi *"
                          placeholder="Adresa lokacije na kojoj se uređaj nalazi"
                          name="deviceAddress"
                          value={this.state.deviceAddress}
                          onChange={this.onChange}
                          error={errors.deviceAddress}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label className="control-label">Softver kupljen kod</label>
                        <select
                          className={classnames('form-control', {
                            'is-invalid': errors.deviceVendor
                          })}
                          name="deviceVendor"
                          value={this.state.deviceVendor}
                          onChange={this.onChange}
                        >
                          <option value="">* Izaberite</option>
                          <option value="Galeb">Galeb</option>
                          <option value="GalebDistributer">Galeb distributer</option>
                          <option value="Telekom">Telekom</option>
                          <option value="Telenor">Telenor</option>
                        </select>
                        <div className="text-danger">
                          {errors.deviceVendor}
                        </div>
                      </div>
                      {notDist}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          //
                          id="btn"
                          //
                          type="submit"
                          value="Aktiviraj licencu"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                    </div>
                    <div className="stats" >* su označena obavezna polja</div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

NewLicenceRegistration.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  getzipCodes: PropTypes.func.isRequired,
  getDKR: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  zipcode: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth,
  client: state.client,
  zipcode: state.zipcode,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getzipCodes, getCurrentProfile, getDKR }
)(withRouter(NewLicenceRegistration));



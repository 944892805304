import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllBlackListDevices, unsuspendDeviceAdmin, getBlacklistedDevice } from '../../actions/blacklistActions';
import Card from '../Card/Card';
import { getCurrentProfile } from '../../actions/profileAction';
import Spinner from '../common/Spinner';
import TextFieldGroup from '../common/TextFieldGroup';

class BlackListTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deviceSerialNo: '',
		};
	}

	componentDidMount() {
		this.props.getAllBlackListDevices();
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	searchForBlacklistDevice() {
		if (this.state.deviceSerialNo !== "") {
			this.props.getBlacklistedDevice(this.state.deviceSerialNo);
		}

	}

	onUnsuspendClick(_id) {
		Swal.fire({
			title: 'Ukloni uređaj sa liste suspendovanih uređaja?',
			text: "Da li sigurno želite da uklonite uređaj sa spiska suspendovanih uređaja i omogucite ponovnu registraciju?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Ne',
			confirmButtonText: 'Da'
		}).then((result) => {
			if (result.isConfirmed) {
				(this.props.unsuspendDeviceAdmin(_id))
			}
		})
	}

	render() {
		const { loading } = this.props.blockedDevice;
		const specificBlacklistedDevice = this.props.blockedDevice.blackListedDevice;
		const blockedDevice = this.props.blockedDevice.BlackListDevices;
		let bdItems;

		if (blockedDevice === null || loading) {
			bdItems = (
				<tr>
					<td>
						<Spinner />
					</td>
				</tr>
			);
		} else if (specificBlacklistedDevice.length >= 1) {
			bdItems = specificBlacklistedDevice.map(blockedDevice => (
				<tr key={blockedDevice._id}>
					<td>{blockedDevice.deviceSerialNo}</td>
					<td>{(() => {
						switch (blockedDevice.Status) {
							case '1':
								return (
									<span className="text-warning">
										Suspendovan
									</span>
								);
							default:
								return null;
						}
					})()}</td>
					<td>{blockedDevice.StatusChangedBy}</td>
					<td>
						<button
							type="button"
							className="btn-fill btn btn-sm btn-success"
							onClick={this.onUnsuspendClick.bind(this, blockedDevice._id)}
						>
							Dozvoli ponovno registrovanje
						</button>
					</td>
					<td>{blockedDevice.reason}</td>
				</tr>
			));
		} else {
			if (blockedDevice.length > 0) {
				bdItems = blockedDevice.map(blockedDevice => (
					<tr key={blockedDevice._id}>
						<td>{blockedDevice.deviceSerialNo}</td>
						<td>{(() => {
							switch (blockedDevice.Status) {
								case '1':
									return (
										<span className="text-warning">
											Suspendovan
										</span>
									);
								default:
									return null;
							}
						})()}</td>
						<td>{blockedDevice.StatusChangedBy}</td>
						<td>
							<button
								type="button"
								className="btn-fill btn btn-sm btn-success"
								onClick={this.onUnsuspendClick.bind(this, blockedDevice._id)}
							>
								Dozvoli ponovno registrovanje
							</button>
						</td>
						<td>{blockedDevice.reason}</td>
					</tr>
				));
			}
			else {
				bdItems = (
					<tr>
						<td>Nema stavki za prikaz</td>
					</tr>
				);
			}
		}

		return (
			<div className="content">
				<Container fluid>
					<Row className='justify-content-center'>
						<div className="col-md-2">
							<TextFieldGroup
								type="text"
								value={this.state.deviceSerialNo}
								placeholder="Serijski broj..."
								name="deviceSerialNo"
								style={{ marginBottom: '5px' }}
								onChange={this.onChange.bind(this)}
							/>
						</div>
						<Col md={2}>
							<div className="row">
								<div className="col-md-12">
									<input
										type="submit"
										value="Pretrazi"
										onClick={this.searchForBlacklistDevice.bind(this)}
										className="btn-fill col-md-12 pull-center btn btn-info"
									/>
								</div>
							</div>
						</Col>
					</Row>
					<br></br>
					<Row>
						<Col md={12}>
							<Card
								title="Blokirani uređaji"
								category="Informacije o blokiranim uređajima"
								ctTableFullWidth
								ctTableResponsive
								content={
									<Table striped hover>
										<thead>
											<tr>
												<th>Serijski broj</th>
												<th>Status</th>
												<th>Promenio status</th>
												<th></th>
												<th>Razlog</th>
											</tr>
										</thead>
										<tbody>{bdItems}</tbody>
									</Table>
								}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

BlackListTable.propTypes = {
	unsuspendDeviceAdmin: PropTypes.func.isRequired,
	getAllBlackListDevices: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
	getBlacklistedDevice: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	client: state.client,
	profile: state.profile,
	debtor: state.debtor,
	auth: state.auth,
	blockedDevice: state.blockedDevice
});

export default connect(
	mapStateToProps,
	{ getAllBlackListDevices, getCurrentProfile, unsuspendDeviceAdmin, getBlacklistedDevice }
)(BlackListTable);

import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import profileReducer from './profileReducer';
import zipcodesReducer from './zipcodeReducers';
import clientReducer from './clientReducer';
import invoiceReducer from './invoiceReducer';
import promocodesReducer from './promocodesReducer';
import statisticsReducer from './statisticsReducer';
import blackListReducer from './blackListReducer';

export default combineReducers({
  blockedDevice: blackListReducer,
  auth: authReducer,
  errors: errorReducer,
  zipcode: zipcodesReducer,
  profile: profileReducer,
  client: clientReducer,
  history: clientReducer,
  deleted: clientReducer,
  invoice: invoiceReducer,
  promocode: promocodesReducer,
  statistic: statisticsReducer,
});

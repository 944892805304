import React, {Component} from 'react';
import { Container, Row, Col, FormLabel } from 'react-bootstrap';
import { Card } from '../Card/Card';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import logo from '../../assets/img/Logo-Galeb-1.png';
import checksign from '../../assets/img/check-green.png';

class Invoice extends Component {
    render() {
        return (
            <div className="content">
            <Container fluid>
                <Row>
                    <Col md={1}>
                    </Col>
                    <Col md={2}>
                        <div className='bg-image' style={{ maxWidth: '24rem' }}>
                            <img src={checksign} width="200" rounded alt="" />
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className='bg-image' style={{ maxWidth: '24rem' }}>
                            <img src={logo} width="200" rounded alt="" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Card
                            title=""
                            content={
                                <form>
                                    <div className="col-md-18">
                                        <FormLabel>
                                            <p>Vaša licenca je uspešno aktivirana.</p>
                                            <p>Hvala Vam što ste odabrali Galeb.</p>
                                            <p>Koristeći pristupne parametere koje ste izabrali možete pristupiti našoj aplikaciji za E-fakture </p>
                                        </FormLabel>
                                    </div>
                                    <div className="col-md-12">
                                        <Link
                                        to="/"
                                        //
                                        id="btn"
                                        //
                                        className="btn-fill col-md-12 pull-center btn btn-info"
                                        style={{ margin: '3px' }}
                                        >
                                        Nazad na aktivacionu stranicu
                                        </Link>
                                    </div>
                                </form>    
                            } 
                        />
                    </Col>
                </Row>
            </Container>
            </div>
        );
    }
}

export default connect(
  )(withRouter(Invoice));
  

import axios from 'axios';
import {
  GET_VENDOR_COUNTER,
  GET_DIST_COUNTER,
  STATISTICS_LOADING,
  GET_SIM_DIST_COUNTER
} from './types'

export const getDistStatsSimCard = (exportCode) => dispatch => {
  dispatch(setStatisticsLoading());
  axios
    .get(`/api/statistics/distStatisticsSimCard/${exportCode}`)
    .then(res =>
      dispatch({
        type: GET_SIM_DIST_COUNTER,
        payload: res.data
      })
    )
    .catch(res =>
      dispatch({
        type: GET_SIM_DIST_COUNTER,
        payload: null
      })
    );
};

export const getClientsStats = () => dispatch => {
  dispatch(setStatisticsLoading());
  axios
    .get(`/api/statistics/stats`)
    .then(res =>
      dispatch({
        type: GET_VENDOR_COUNTER,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_VENDOR_COUNTER,
        payload: null
      })
    );
};

export const getDistStats = (exportCode) => dispatch => {
  dispatch(setStatisticsLoading());
  axios
    .get(`/api/statistics/distStatistics/${exportCode}`)
    .then(res =>
      dispatch({
        type: GET_DIST_COUNTER,
        payload: res.data
      })
    )
    .catch(res =>
      dispatch({
        type: GET_DIST_COUNTER,
        payload: null
      })
    );
};
// Profile loading
export const setStatisticsLoading = () => {
  return {
    type: STATISTICS_LOADING
  };
};
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBlackListDevicesPerVendor, unsuspendDeviceVendor } from '../../actions/blacklistActions';
import Card from '../Card/Card';
import { getCurrentProfile } from '../../actions/profileAction';
import Spinner from '../common/Spinner';

class BlackListDistTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: ''
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange = d => {
		this.setState({
			search: d.target.value
		}, () => { console.log(this.state.search) })
	}

	componentDidMount() {
		this.props.getCurrentProfile();
		const vendorName = this.props.auth.user.NavCode;
		this.props.getBlackListDevicesPerVendor(vendorName);
	}

	onUnsuspendClick(_id) {
		Swal.fire({
			title: 'Ukloni uređaj sa liste suspendovanih uređaja?',
			text: "Da li sigurno želite da uklonite uređaj sa spiska suspendovanih uređaja i omogucite ponovnu registraciju?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			cancelButtonText: 'Ne',
			confirmButtonText: 'Da'
		  }).then((result) => {
			if (result.isConfirmed) {
			  (this.props.unsuspendDeviceVendor(_id))
			}
		  })
	}

	render() {
		const { loading } = this.props.blockedDevice;
		const blockedDevice = this.props.blockedDevice.BlackListDevicesPerVendor.filter(BlackListDevicesPerVendor => {
			return (
				BlackListDevicesPerVendor.deviceSerialNo.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
			)
		});
		let bdItems;
		if (blockedDevice === null || loading) {
			bdItems = (
				<tr>
					<td>
						<Spinner />
					</td>
				</tr>
			);

		} else {
			if (blockedDevice.length > 0) {
				bdItems = blockedDevice.map(blockedDevice => (
					<tr key={blockedDevice._id}>
						<td>{blockedDevice.deviceSerialNo}</td>
						<td>{(() => {
							switch (blockedDevice.Status) {
								case '1':
									return (
										<span className="text-warning">
											Suspendovan
										</span>
									);
								default:
									return null;
							}
						})()}</td>
						<td>
							<button
								type="button"
								className="btn-fill btn btn-sm btn-success"
								onClick={this.onUnsuspendClick.bind(this, blockedDevice._id)}
							>
								Dozvoli ponovno registrovanje
							</button>
						</td>
					</tr>
				));
			}
			else {
				bdItems = (
					<tr>
						<td>Nema stavki za prikaz</td>
					</tr>
				);
			}
		}

		return (
			<div className="content">
				<Container fluid>
				<Row>
						<Col md={6}>
							<input
								className="form-control form-control-lg col-md-11"
								type="text"
								placeholder="Pretraga suspendovanih uređaja"
								name="search"
								style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
								onChange={this.onChange.bind(this)}
							/>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Card
								title="Blokirani uređaji"
								category="Informacije o blokiranim uređajima"
								ctTableFullWidth
								ctTableResponsive
								content={
									<Table striped hover>
										<thead>
											<tr>
												<th>Serijski broj</th>
												<th>Status</th>
												<th></th>
											</tr>
										</thead>
										<tbody>{bdItems}</tbody>
									</Table>
								}
							/>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

BlackListDistTable.propTypes = {
	unsuspendDeviceVendor: PropTypes.func.isRequired,
	getBlackListDevicesPerVendor: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	client: state.client,
	profile: state.profile,
	debtor: state.debtor,
	auth: state.auth,
	blockedDevice: state.blockedDevice
});

export default connect(
	mapStateToProps,
	{ getBlackListDevicesPerVendor, getCurrentProfile, unsuspendDeviceVendor }
)(BlackListDistTable);

import React, { useEffect, useState } from 'react'
import AdminMenu from './AdminMenu'
import axios from 'axios'
import Select from 'react-select';
import Swal from 'sweetalert2';


const CashOrders = () => {
    const [orders, setOrders] = useState()
    const [status, setStatus] = useState([
        "Obrada", "Poslato", "Dostavljeno", "Odbijeno"
    ]);
    const [changeStatus, setChangeStatus] = useState("");
    const [statusValue, setStatusValue] = useState()
    const [orderNo, setOrderNo] = useState()
    useEffect(() => {
        getOrders();
    }, [])
    // console.log(orders)

    const searchOrder = async () => {
        const response = await axios.post('/backendeshop/api/v1/auth/searchOrder', { orderNo: orderNo })
        setOrders(response.data)
        console.log(response.data)
    }

    const getOrders = async () => {
        const response = await axios.post(`/backendeshop/api/v1/auth/getOrderCash`);
        const allorders = response.data
        console.log(allorders)
        for (let order in allorders) {
            //console.log(allorders)
            //console.log(allorders[2]._id)
        }
        setOrders(allorders)

        //console.log(allorders)
    }

    //zaokruzivanje na 2
    function roundToTwoDecimal(number) {
        return Math.round(number * 100) / 100;
    }
    function formatirajDatum(datum) {
        const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
        const lokalizovaniDatum = new Date(datum).toLocaleDateString('en-GB', options);
        return lokalizovaniDatum.split('.').join('.');
    }

    const handleChange = async (orderId, status) => {
        try {
            // Provera da li korisnik želi da promeni status sa 1 na 2
            if (status === 2) {
                const { value: postOrderNo } = await Swal.fire({
                    title: 'Unesite broj pošiljke pošte',
                    input: 'text',
                    inputLabel: 'Broj pošiljke',
                    showCancelButton: true,
                    confirmButtonText: 'Potvrdi',
                    cancelButtonText: 'Odustani',
                    inputValidator: (value) => {
                        if (!value) {
                            return 'Morate uneti broj pošiljke!'
                        }
                        // Ovde možete dodatno proveriti validnost unesene 
                        // Na primer, možete koristiti regularne izraze za validaciju.
                    }
                });

                if (postOrderNo) {
                    // Dodajte kod za ažuriranje statusa sa IP adresom
                    const updateStatusResponse = await axios.put(`/backendeshop/api/v1/auth/updateOrderStatus/${orderId}`, {
                        status,
                        postOrderNo
                    });

                    // Proverite da li je ažuriranje statusa bilo uspešno
                    if (updateStatusResponse.status === 200) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Status porudžbine je uspešno promenjen',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        getOrders()
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Došlo je do greške prilikom ažuriranja statusa porudžbine.',
                        });
                    }
                }
            } else {
                // Ako korisnik ne menja status sa 1 na 2, izvršava se obično ažuriranje statusa
                const { data } = await axios.put(`/backendeshop/api/v1/auth/updateOrderStatus/${orderId}`, {
                    status
                });
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Status porudžbine je uspešno promenjen',
                    showConfirmButton: false,
                    timer: 1500
                });
                getOrders()
            }
        } catch (error) {
            // Obrada grešaka
            Swal.fire({
                icon: 'error',
                title: 'Došlo je do greške prilikom ažuriranja statusa porudžbine.',
            });
        }
    };

    const options = [
        { label: 'Obrada', value: 1 },
        { label: 'Isporučeno', value: 2 },
        { label: 'Dostavljeno', value: 3 },
        { label: 'Odbijeno', value: 4 }
    ]

    let tBody = (
        <tr>
            <td>Trenutno nema porudžbina</td>
        </tr>)
    if (orders) {
        tBody = orders.map((order) => (
            <tr key={order._id}>

                <td><p>Broj pošiljke:</p>
                    <b>{order.orderNo}</b><br /><br />
                    <p>Poručeno sa uredjaja:<br />
                        {order.deviceSerialNo}</p>
                    <p>Broj pošiljke u pošti<br></br>
                        <b>{order.postOrderNo}</b>
                    </p>
                </td>
                <td>
                    {order.status === '1' && (
                        <p>Trenutni status: <p className="text-warning">Obrada</p></p>
                    )}
                    {order.status === '2' && (
                        <p>Trenutni status: <p className="text-primary">Isporučeno</p></p>
                    )}
                    {order.status === '3' && (
                        <p>Trenutni status: <p className="text-success">Dostavljeno</p></p>
                    )}
                    {order.status === '4' && (
                        <p>Trenutni status: <p className="text-danger">Odbijeno</p></p>
                    )}
                    <Select
                        value={options.find((opt) => opt.value === order.status)}
                        options={options}
                        placeholder='Izaberi status'
                        onChange={(e) => setStatusValue(e.value)}
                    />
                    <br />
                    <button
                        type="button"
                        className="btn btn-fill btn-primary"
                        onClick={() => handleChange(order._id, statusValue)}
                    >
                        Promeni status
                    </button><br /><br />
                    <p>Poslednja promena: <br /><b>{formatirajDatum(order.updatedAt)}</b></p>
                </td>

                <td>
                    <table className="table table-bordered table-striped border-dark">
                        <thead >
                            <tr>
                                <th className='text-dark'><b>Naziv proizvoda</b></th>
                                <th className='text-dark'><b>Cena po komadu</b></th>
                                <th className='text-dark'><b>Veličina paketa</b></th>
                                <th className='text-dark'><b>Količina</b></th>
                                <th className='text-dark'><b>Ukupna cena</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.products.map((product) => (
                                <tr key={product._id}>
                                    <td>{product.name}</td>
                                    <td>{roundToTwoDecimal(product.price)} RSD</td>
                                    <td>{product.quantity} kom</td>
                                    <td>{product.pack_quantity} kom</td>
                                    <td>{roundToTwoDecimal(product.totalPrice)} RSD</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <p>Način plaćanja: {order.paymentMethod}</p>
                    <p>Ukupna cena porudžbine: <b>{roundToTwoDecimal(order.total)} RSD</b></p>
                    {order.total < 3000 ? (
                        <p><b>Plaća isporuku</b></p>
                    ) : (
                        <p><b>Besplatna isporuka</b></p>
                    )}
                    <p>Datum kreiranja porudžbine {formatirajDatum(order.createdAt)}</p>

                </td>
                <td>
                    <p>
                        Ime: {order.address.name}
                        <br />
                        Telefon: {order.address.phone}
                        <br />
                        Email: {order.address.email}
                        <br />
                        Ulica i broj: {order.address.deviceAddress}
                        <br />
                        Poštanski broj: {order.address.deviceAddrZip}
                        <br />
                        Grad: {order.address.deviceAddrCity}
                        <br />
                        {order.address.compVatId ? (
                            <>
                                {order.address.compVatId.length === 13 ? (
                                    <>
                                        JMBG: {order.address.compVatId}
                                        <br />
                                        <b>Fizičko lice</b>
                                    </>
                                ) : (
                                    <>
                                        PIB: {order.address.compVatId}
                                        <br />
                                        <b>Pravno lice</b>
                                    </>
                                )}
                            </>
                        ) : null}
                    </p>
                </td>


            </tr>
        ));
    }
    return (
        <div className="content">
            <div className="container-fluid m-3 p-3">
                <div className="row">
                    <div className="col-md-2">
                        <AdminMenu />
                    </div>

                    <div className="col-md-10">
                        <h1 className="text-center">Porudžbine plaćanje pouzećem</h1>
                        <div className='row'>
                            <div className="mb-3 col-md-4">
                                <input
                                    value={orderNo}
                                    type="text"
                                    placeholder="Broj pošiljke"
                                    className="form-control"
                                    onChange={(e) => setOrderNo(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 col-md-3">
                                <input
                                    type='button'
                                    value='Pretraži'
                                    className="btn-fill pull-center btn btn-primary"
                                    onClick={searchOrder}
                                />
                            </div>
                            <div className=' col-md-3'>
                                <input
                                    type='button'
                                    value={`Ukupno porudžbina: ${orders?.length}`}
                                    className="btn-fill pull-center btn btn-info"
                                />
                            </div>
                        </div>
                        <div className="border shadow">
                            <table className="table table-bordered table-striped">
                                <thead className='thead-info '>
                                    <tr>
                                        <th className='text-dark fs-6' scope='col'><b>Broj porudžbine</b></th>
                                        <th className='text-dark fs-6' scope="col"><b>Status porudžbine</b></th>
                                        <th className='text-dark fs-6' scope="col"><b>Porudžbina</b></th>
                                        <th className='text-dark fs-6' scope="col"><b>Adresa isporuke</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tBody}
                                </tbody>
                            </table>
                            <div className="container">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default CashOrders
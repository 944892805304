import React from 'react'
import { NavLink } from 'react-router-dom'

const AdminMenu = () => {
  return (
    <>
<div className='text-center'>

   <div className="list-group">
    {/*<h3>Admin kontrolna tabla</h3>*/}
        {/*<NavLink to='/galebshop/create-product' className="list-group-item list-group-item-action">Kreiraj proizvod</NavLink>
        <NavLink to='/galebshop/products' className="list-group-item list-group-item-action">Svi proizvodi</NavLink>*/}<br/><br/>
        <NavLink to="/galebshop/orders" className="list-group-item list-group-item-action">Sve porudžbine</NavLink><br/><br/>
        <h6>Filteri po načinu plaćanja</h6>
        {/*<NavLink to="/galebshop/orders/orders-card" className="list-group-item list-group-item-action">Kartično</NavLink>*/}
        <NavLink to="/galebshop/orders/orders-cash" className="list-group-item list-group-item-action">Plaćanje pouzećem</NavLink>
        {/*<NavLink to="/galebshop/orders/orders-ips" className="list-group-item list-group-item-action">IPS plaćanje</NavLink>*/}
        <NavLink to="/galebshop/orders/orders-predracun" className="list-group-item list-group-item-action">Predračun</NavLink>
    </div>
</div>
</>
  )
}

export default AdminMenu

/* eslint-disable */
import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getClients, getClientsByVendorOnCall, getClientsByVendor, changeLicence, downloadExcel, getClientsByVendorCounter, getClientsByVendorFiltered, getClietnsSimCardStats, getUnseenLogs } from '../../actions/clientActions';
import { getCurrentProfile } from '../../actions/profileAction';
import { getDistStatsSimCard } from '../../actions/statisticsActions';
import Card from '../Card/Card';
import { StatsCard } from '../StatsCard/StatsCard';
import { Accordion } from 'react-bootstrap';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { style } from '../../variables/Variables.jsx';

class ClientTableDist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      notificationShown: false,
      compVatId: '',
      deviceSerialNo: '',
      page: 1,
      perPage: 25,
    };
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    const vendorName = this.props.auth.user.NavCode;
    this.props.getClientsByVendorCounter(vendorName);
    const searchCriteria = null;
    this.props.getClientsByVendor(vendorName, searchCriteria, '1', this.state.perPage);
    this.props.getDistStatsSimCard(vendorName);
    this.props.getUnseenLogs();
    const onCall = this.props.auth.user.onCall;

    //console.log(this.props.auth.user)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.notificationShown == false) {
      this.setState({ notificationShown: true })
      if (this.props.auth.user.position === 'Komercijalista') {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
        var _notificationSystem = this.refs.notificationSystem;
        if (nextProps.client.allUnseenLogs.length > 0) {
          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-note2" />,
            message: (
              <div>
                <b>
                  Novih odgovora u logovima{' '}
                  {
                    nextProps.client.allUnseenLogs.length
                  }
                </b>
              </div>
            ),
            level: 'warning',
            position: 'tr',
            autoDismiss: 15
          });
        } else {
          _notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-note2" />,
            message: (
              <div>
                <b>
                  Trenutno nema novih odgovora
                </b>
              </div>
            ),
            level: 'warning',
            position: 'tr',
            autoDismiss: 15
          });
        }
      }
    }
  }


  OnChangePerPage = (e) => {
    const perPage = e.target.value;
    this.setState({ perPage }, () => {
      const vendorName = this.props.auth.user.NavCode
      const searchCriteria = null;
      this.props.getClientsByVendor(vendorName, searchCriteria, this.state.page, this.state.perPage)
    });
  }

  showActiveDevices() {
    this.props.getClientsByVendorFiltered(this.props.auth.user.NavCode, 'Aktivna')
  }

  showDeactivatedDevices() {
    this.props.getClientsByVendorFiltered(this.props.auth.user.NavCode, 'Neaktivna')
  }

  onWithSim() {
    this.props.getClietnsSimCardStats(this.props.auth.user.NavCode, 'Da')
  }

  onWithoutSim() {
    this.props.getClietnsSimCardStats(this.props.auth.user.NavCode, 'Ne')
  }

  onDeactivateClick(cbLicenceId) {
    Swal.fire({
      title: 'Dektiviraj licencu?',
      text: "Da li sigurno želite da deaktivirate licencu ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Deaktiviraj!'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.changeLicence(cbLicenceId, 'Neaktivna'))
      }
    })
  }

  onChangeActivate(cbLicenceId) {
    Swal.fire({
      title: 'Aktiviraj licencu?',
      text: "Da li sigurno želite da aktivirate licencu ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Aktiviraj!'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.changeLicence(cbLicenceId, 'Aktivna'))
      }
    })
  }

  onClickexportExcelVendorClients() {
    this.props.getCurrentProfile();
    const vendorName = this.props.auth.user.NavCode;
    this.props.downloadExcel(vendorName);
  }

  onChange = d => {
    this.setState({
      [d.target.name]: d.target.value,
    }, () => { })
  }

  NextPage = () => {
    if (this.state.page >= 1) {
      this.setState({
        page: this.state.page + 1
      }
        , () => {
          const vendorName = this.props.auth.user.NavCode;
          const searchCriteria = null;
          this.props.getClientsByVendor(vendorName, searchCriteria, this.state.page, this.state.perPage)
        });
    }
  }

  PreviousPage = () => {
    if (this.state.page > 1) {
      this.setState({
        page: this.state.page - 1
      }
        , () => {
          const vendorName = this.props.auth.user.NavCode;
          const searchCriteria = null;
          this.props.getClientsByVendor(vendorName, searchCriteria, this.state.page, this.state.perPage)
        });
    }
  }

  updateSearch = e => {
    {

      //console.log([e.target.name], [e.target.value])
      //console.log(this.props.auth.user.onCall)
      if (this.props.auth.user.onCall == 'Yes') {
        if (this.state.compVatId.length > 3) {
          this.props.getClientsByVendorOnCall(this.state.compVatId, 'compVatId')
        } else if (this.state.deviceSerialNo.length > 3) {
          this.props.getClientsByVendorOnCall(this.state.deviceSerialNo, 'deviceSerialNo')
        }
      }
      else if (this.state.compVatId.length >= 3 || this.state.deviceSerialNo.length >= 3) {

        this.props.getCurrentProfile();
        const vendorName = this.props.auth.user.NavCode;
        const searchCriteria = this.state.compVatId
        this.props.getClientsByVendor(vendorName, searchCriteria, null);

      }
    }
  }



  updateSearchClick = () => {
    if (this.state.search.length >= 3) {
      this.props.getCurrentProfile();
      const vendorName = this.props.auth.user.NavCode;
      const searchCriteria = this.state.compVatId
      this.props.getClientsByVendor(vendorName, searchCriteria, null);
    }
  }

  render() {
    const evidencijaLogova = this.props.auth.user.position === 'Komercijalista' ? null : { display: 'none' };

    const statistics = this.props.statistic.statDistCounterSim;
    var withSim = 0;
    var withoutSim = 0;
    if (statistics.length < 1) {
    }
    else {
      withSim = statistics[0].SaSim;
      withoutSim = statistics[0].BezSim;
    }

    const { loading } = this.props.client;
    var aktivni = 0;
    var neaktivni = 0;
    const clientsCounter = this.props.client.clientsByVendorCounter;
    const clients = this.props.client.clientsByVendor;

    var SearchPibN = {}

    if (this.props.auth.user.onCall == 'Yes') {
      SearchPibN =
        <Row>
          <Col md={5}>
            <input
              className="form-control form-control-lg col-md-11"
              type="text"
              placeholder="Pretraga po PIB-u"
              name="compVatId"
              style={{ marginBottom: '5px' }}
              onChange={this.onChange.bind(this)}

            />
          </Col>
          <Col md={5}>
            <input
              className="form-control form-control-lg col-md-11"
              type="text"
              placeholder="Pretraga po serisjkom broju uredjaja"
              name="deviceSerialNo"
              style={{ marginBottom: '5px' }}
              onChange={this.onChange.bind(this)}

            />

          </Col>
          <Col md={2}>
            <div className="row">
              <div className="col-md-11">
                <input
                  type="button"
                  value="Pretrazi"
                  onClick={this.updateSearch.bind(this)}
                  className="btn-fill col-md-12 pull-center btn btn-info"
                />
              </div>
            </div>
          </Col>
        </Row>
    }

    else {
      SearchPibN = <Row>
        <Col md={10}>
          <input
            className="form-control form-control-lg col-md-11"
            type="text"
            placeholder="Pretraga po PIB-u"
            name="compVatId"
            style={{ marginBottom: '5px' }}
            onChange={this.onChange.bind(this)}


          />

        </Col>
        <Col md={2}>
          <div className="row">
            <div className="col-md-11">
              <input
                type="button"
                value="Pretrazi"
                onClick={this.updateSearch.bind(this)}
                className="btn-fill col-md-12 pull-center btn btn-info"
              />
            </div>
          </div>
        </Col>
      </Row>
    }

    let clientItems;
    if (clients === null || loading) {
      clientItems = (
        <tr>
          <td colSpan="4">
            <Spinner />
          </td>
        </tr>
      );

    } else {
      if (clients.length > 0) {
        let countActive = 0;
        let countInactive = 0;
        var count = clientsCounter.length;
        for (let i = 0; i < count; i++) {
          if (clientsCounter[i].statusLicence === 'Aktivna') {
            countActive += 1;
          }
          else if (clientsCounter[i].statusLicence === 'Neaktivna') {
            countInactive += 1;
          }
        }
        aktivni = countActive;
        neaktivni = countInactive;
        clientItems = clients.map(client => (
          <tr key={client._id}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item>
                <Accordion.Header>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <td style={{ width: '17%' }}>Ime: </td>
                      <td style={{ width: '8%' }}>MB: </td>
                      <td style={{ width: '7%' }}>PIB: </td>
                      <td style={{ width: '12%' }}>Serijski broj: </td>
                      <td style={{ width: '15%' }}>Status licence: </td>
                      <td style={{ width: '10%' }}>Datum aktivacije:</td>
                    </tr>
                    <tr>
                      <td style={{ width: '17%' }}>{client.compName}</td>
                      <td style={{ width: '8%' }}>{client.compId}</td>
                      <td style={{ width: '7%' }}>{client.compVatId}</td>
                      <td style={{ width: '12%' }}>{client.deviceSerialNo}</td>
                      <td style={{ width: '15%' }}>{(() => {
                        switch (client.statusLicence) {
                          case 'Aktivna':
                            return (
                              <span className="text-success">
                                {client.statusLicence}
                              </span>
                            );
                          case 'Neaktivna':
                            return (
                              <span className="text-danger">
                                {client.statusLicence}
                              </span>
                            );
                          default:
                            return null;
                        }
                      })()}
                      </td>
                      <td style={{ width: '10%' }}>{client.activationDate}</td>
                    </tr>
                  </table>
                </Accordion.Header>
                <Accordion.Body>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <td style={{ width: '26%', border: 'bold' }}>Mail kontakt osobe:</td>
                      <td style={{ width: '14%' }}>Kontakt osoba:</td>
                      <td style={{ width: '8%' }}>Sim kartica:</td>
                      <td style={{ width: '8%' }}>Karticno pl:</td>
                      <td style={{ width: '15%' }}>Telefon kontakt osobe: </td>
                      <td style={{ width: '10%' }}></td>
                      <td style={{ width: '10%' }}></td>
                      <td style={{ width: '10%' }}></td>
                    </tr>
                    <tr>
                      <td style={{ width: '26%', verticalAlign: 'top' }}>{client.userEmail}</td>
                      <td style={{ width: '14%', verticalAlign: 'top' }}>{client.userFirstName} {client.userLastName}</td>
                      <td style={{ width: '8%', verticalAlign: 'top' }}>{(() => {
                        switch (client.simCard) {
                          case 'Da':
                            return (
                              <span>
                                {client.simCard}
                              </span>
                            );
                          default:
                            return (
                              <span>
                                Ne
                              </span>
                            );
                        }
                      })()}</td>
                      <td style={{ width: '8%', verticalAlign: 'top' }}>{(() => {
                        switch (client.payment) {
                          case 'Da':
                            return (
                              <span>
                                {client.payment}
                              </span>
                            );
                          default:
                            return (
                              <span>
                                Ne
                              </span>
                            );
                        }
                      })()}</td>
                      <td style={{ width: '15%', verticalAlign: 'top' }}>{client.userPhone}</td>
                      <td style={{ width: '10%', verticalAlign: 'top' }}>{client.statusLicence === 'Aktivna' ? (
                        <button
                          type="button"
                          className="btn-fill btn btn-sm btn-danger"
                          onClick={this.onDeactivateClick.bind(this, client.cbLicenceId)}
                        >
                          Deaktiviraj
                        </button>
                      ) :
                        <button
                          type="button"
                          className="btn-fill btn btn-sm btn-success"
                          onClick={this.onChangeActivate.bind(this, client.cbLicenceId)}
                        >
                          Aktiviraj
                        </button>}
                      </td>
                      <td style={{ width: '5%', verticalAlign: 'top' }}>
                        <Link
                          to={`/clientsDist/add-client/${client._id}`}
                          className="btn-fill btn btn-sm btn-info"
                        >Izmena
                        </Link>
                      </td>
                      <td style={{ width: '10%', verticalAlign: 'top' }}>
                        <Link
                          type="button"
                          className="btn-fill btn btn-sm btn-success"
                          to={`/clientsDist/remove-client/${client._id}`}
                        >Zahtev za brisanje
                        </Link>
                      </td>
                      <td style={evidencijaLogova}>
                        <div className="btn-group mb-4">
                          <Link
                            to={`/clientsDist/ClientCallHistory/${client._id}`}
                            className="btn-fill btn btn-sm btn-secondary"
                          >Logovi
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </tr>
        ));
      }
      else {
        clientItems = (
          <tr>
            <td>Licenca sa tim podacima nije pronađena...</td>
          </tr>
        );
      }
    }

    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Row className='justify-content-center'>
          <Col lg={2} sm={3}>
            <button style={{ border: 'none', width: '100%', background: '#f7f7f8' }}
              onClick={this.showActiveDevices.bind(this)}>
              <StatsCard
                bigIcon={<i className="pe-7s-check text-success" />}
                statsText="Aktivni"
                statsValue={
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={aktivni}
                    duration={3}
                    useGrouping={true}
                  />}
                statsIconText="Broj aktivnih uređaja"
              />
            </button>
          </Col>
          <Col lg={2} sm={3}>
            <button style={{ border: 'none', width: '100%', background: '#f7f7f8' }}
              onClick={() => this.props.getClientsByVendorFiltered(this.props.auth.user.NavCode, 'All')}
            >
              <StatsCard
                bigIcon={<i className="pe-7s-calculator text-info" />}
                statsText="Svi"
                statsValue={
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={aktivni + neaktivni}
                    duration={3}
                    useGrouping={true}
                  />}
                statsIconText="Ukupan broj svih uređaja"
              />
            </button>
          </Col>
          <Col lg={2} sm={3}>
            <button style={{ border: 'none', width: '100%', background: '#f7f7f8' }}
              onClick={this.showDeactivatedDevices.bind(this)}>
              <StatsCard
                bigIcon={<i className="pe-7s-close-circle text-danger" />}
                statsText="Neaktivni"
                statsValue={
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={neaktivni}
                    duration={3}
                    useGrouping={true}
                  />}
                statsIconText="Broj neaktivnih uređaja"
              />
            </button>
          </Col>
          <Col lg={2} sm={3}>
            <button style={{ border: 'none', width: '100%', background: '#f7f7f8' }}
              onClick={this.onWithSim.bind(this)}>
              <StatsCard
                bigIcon={<i className="pe-7s-call text-success" />}
                statsText="Sa Sim"
                statsValue={
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={withSim}
                    duration={3}
                    useGrouping={true}
                  />}
                statsIconText="Uređaji sa sim karticom"
              />
            </button>
          </Col>
          <Col lg={2} sm={3}>
            <button style={{ border: 'none', width: '100%', background: '#f7f7f8' }}
              onClick={this.onWithoutSim.bind(this)}>
              <StatsCard
                bigIcon={<i className="pe-7s-close-circle" />}
                statsText="Bez Sim"
                statsValue={
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={withoutSim}
                    duration={3}
                    useGrouping={true}
                  />}
                statsIconText="Uređaji bez sim kartice"
              />
            </button>
          </Col>
        </Row>
        <Container fluid>

          {SearchPibN}

          <Row>
            <Col md={12}>
              <Row>
                <Col>
                  <div className="pull-right" style={{ marginRight: '5px' }}>
                    <select
                      name="perPage"
                      onChange={this.OnChangePerPage}
                      value={this.state.perPage}
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                    </select>
                    {this.state.page > 1
                      ? <button onClick={this.PreviousPage}>{'<-'}</button>
                      : <button disabled>{'<-'}</button>

                    }
                    <button disabled style={{ border: 'solid', borderColor: '#000000', borderWidth: 'thin', color: '#000000' }}>{this.state.page}</button>
                    {clients.length < this.state.perPage
                      ? <button disabled>{'->'}</button>
                      : <button onClick={this.NextPage} >{'->'}</button>
                    }
                  </div>
                </Col>
              </Row>
              <Card
                title="Licence"
                category="Informacije o licencama"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table hover style={{ background: '#f2f2f2' }}>
                    <thead style={{ background: '#ffffff' }}>
                      <tr>
                        <th>
                          <button
                            type="button"
                            className="btn-fill btn btn-sm btn-danger"
                            onClick={this.onClickexportExcelVendorClients.bind(this)}
                          >
                            Izvezi .xls
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{clientItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <div className="pull-right" style={{ marginRight: '5px' }}>

            {this.state.page > 1
              ? <button onClick={this.PreviousPage}>{'<-'}</button>
              : <button disabled>{'<-'}</button>
            }
            <button disabled style={{ border: 'solid', borderColor: '#000000', borderWidth: 'thin', color: '#000000' }}>{this.state.page}</button>
            {clients.length < this.state.perPage
              ? <button disabled>{'->'}</button>
              : <button onClick={this.NextPage} >{'->'}</button>
            }
          </div>
        </Container>
      </div>
    );
  }
}

ClientTableDist.propTypes = {
  downloadExcel: PropTypes.func.isRequired,
  getClietnsSimCardStats: PropTypes.func.isRequired,
  getClientsByVendorCounter: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getClientsByVendorOnCall: PropTypes.func.isRequired,
  getClientsByVendorFiltered: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getDistStatsSimCard: PropTypes.func.isRequired,
  getUnseenLogs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  client: state.client,
  clientsByVendor: state.clientsByVendor,
  getClientsByVendorCounter: state.getClientsByVendorCounter,
  profile: state.profile,
  auth: state.auth,
  statistic: state.statistic,
});

export default connect(
  mapStateToProps,
  {
    getClients,
    getClientsByVendor,
    getClientsByVendorOnCall,
    getCurrentProfile,
    changeLicence,
    downloadExcel,
    getClientsByVendorCounter,
    getClientsByVendorFiltered,
    getDistStatsSimCard,
    getClietnsSimCardStats,
    getUnseenLogs
  }
)(ClientTableDist);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Card } from '../Card/Card';
import isEmpty from '../../validation/is-empty';
import { getInvoiceById, changeInvoiceData } from '../../actions/invoiceActions';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

//const Validator = require('validator');
class AddInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: '2024',
      invoicesNumber: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      passwordHidden: '',
      userNameHidden: '',
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
      contactEmail: '',
      apiKey: '',
      companyBankAccount: '',
      isVatExcluded: null,
      vatExclusionReason: '',
      errors: {}
    };
    this.onChangeV1 = this.onChangeV1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.getInvoicesNumber = this.getInvoicesNumber.bind(this);
  }

  getInvoicesNumber(e) {
    e.preventDefault();
    if (this.state.apiKey == '' || this.state.apiKey.length < 5) {
      Toast.fire({
        icon: 'error',
        title: 'Problem sa api ključem!'
      })
    } else {
      Toast.fire({
        icon: 'info',
        title: 'Preuzimanje broja faktura sa sefa može malo potrajati!'
      })
      axios.post('/api/eInvoicesApi/getNumberOfInvoices/', { apiKey: this.state.apiKey, year: this.state.year })
        .then(response => {
          this.setState({ invoicesNumber: response.data })
        })
        .catch(err => {
          Toast.fire({
            icon: 'error',
            title: 'Greška'
          })
        })
    }


  }
  componentDidMount() {
    if (this.props.match.params._id) {
      this.props.getInvoiceById(this.props.match.params._id);
    }
  }

  onSubmit(e) {
    e.preventDefault();
    //
    document.getElementById("btn").disabled = true;
    setTimeout(function () { document.getElementById("btn").disabled = false; }, 1000);
    //
    const data = {
      _id: this.state._id,
      userFirstName: this.state.firstName,
      userLastName: this.state.lastName,
      loginMail: this.state.email,
      userEmail: this.state.contactEmail,
      apiKey: this.state.apiKey,
      CompanyBankAccount: this.state.companyBankAccount,
      isVatExcluded: this.state.isVatExcluded,
      vatExclusionReason: this.state.vatExclusionReason,
      passwordHidden: this.state.passwordHidden,
      userNameHidden: this.state.userNameHidden,
    };
    this.props.changeInvoiceData(data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.invoice.invoice) {
      const invoice = nextProps.invoice.invoice;

      invoice._id = !isEmpty(invoice._id) ? invoice._id : '';
      invoice.userFirstName = !isEmpty(invoice.userFirstName) ? invoice.userFirstName : '';
      invoice.userLastName = !isEmpty(invoice.userLastName) ? invoice.userLastName : '';
      invoice.loginMail = !isEmpty(invoice.loginMail) ? invoice.loginMail : '';
      invoice.userEmail = !isEmpty(invoice.userEmail) ? invoice.userEmail : '';
      invoice.apiKey = !isEmpty(invoice.apiKey) ? invoice.apiKey : '';
      invoice.CompanyBankAccount = !isEmpty(invoice.CompanyBankAccount) ? invoice.CompanyBankAccount : '';
      invoice.isVatExcluded = !isEmpty(invoice.isVatExcluded) ? invoice.isVatExcluded : '';
      invoice.vatExclusionReason = !isEmpty(invoice.vatExclusionReason) ? invoice.vatExclusionReason : '';
      invoice.userInvoicePass = !isEmpty(invoice.userInvoicePass) ? invoice.userInvoicePass : '';
      invoice.passwordHidden = !isEmpty(invoice.userInvoicePass) ? invoice.userInvoicePass : '';
      invoice.userNameHidden = !isEmpty(invoice.loginMail) ? invoice.loginMail : '';
      // Set component fields state
      this.setState({
        _id: invoice._id,
        firstName: invoice.userFirstName,
        lastName: invoice.userLastName,
        email: invoice.loginMail,
        contactEmail: invoice.userEmail,
        apiKey: invoice.apiKey,
        companyBankAccount: invoice.CompanyBankAccount,
        isVatExcluded: invoice.isVatExcluded,
        vatExclusionReason: invoice.vatExclusionReason,
        userInvoicePass: invoice.userInvoicePass,
        passwordHidden: invoice.passwordHidden,
        userNameHidden: invoice.userNameHidden,
      });
    }
  }

  onChangeV1(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({
      search: event.target.value
    }, () => { if (this.state.search.length === 9) { this.props.getClientByCompVatId(this.state.search); } }
    );
  }

  handleChange() {
    this.setState({ isVatExcluded: !this.state.isVatExcluded }, () => console.log(this.state.isVatExcluded));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  }

  render() {
    const { errors } = this.state;
    const showReasons = this.state.isVatExcluded
      ? <div>
        <div className="col-md-6">
          <label className="control-label">Nije u sistemu PDV-A</label>
          <select
            className={classnames('form-control', {
              'is-invalid': errors.vatExclusionReason
            })}
            name="vatExclusionReason"
            value={this.state.vatExclusionReason}
            onChange={this.onChange}
          >
            <option value=''>* Izaberite</option>
            <option value='33'>33 (mali obveznik)</option>
            <option value='35'>35 (turisticka agencija)</option>

          </select>
          <div className="text-danger">
            {errors.vatExclusionReason}
          </div>
        </div>
      </div> : null
    const checked = showReasons ? true : false

    const showData = this.props.auth.user.position == "Admin" ?
      <Col md={6}>
        <Card
          title="Podaci o licenci"
          content={
            <form onSubmit={this.onSubmit} autoComplete="off">
              <div className="row">
                <div className="col-md-4">
                  <TextFieldGroup
                    label="Ime korisnika *"
                    placeholder="Ime korisnika"
                    name="firstName"
                    value={this.state.firstName}
                    onChange={this.onChange}
                    error={errors.firstName}
                  />
                </div>
                <div className="col-md-8">
                  <TextFieldGroup
                    label="Prezime korisnika *"
                    placeholder="Prezime korisnika"
                    name="lastName"
                    value={this.state.lastName}
                    onChange={this.onChange}
                    error={errors.lastName}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextFieldGroup
                    label="Login mail *"
                    placeholder="Login mail"
                    name="email"
                    type="email"
                    value={this.state.email}
                    onChange={this.onChange}
                    error={errors.email}
                  />
                </div>
              </div>
              <div className="row">
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextFieldGroup
                    label="Kontakt mail korisnika *"
                    placeholder="Kontakt mail korisnika"
                    name="contactEmail"
                    type="email"
                    value={this.state.contactEmail}
                    onChange={this.onChange}
                    error={errors.contactEmail}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextFieldGroup
                    label="Api kljuc *"
                    placeholder="Api kljuc"
                    name="apiKey"
                    value={this.state.apiKey}
                    onChange={this.onChange}
                    error={errors.apiKey}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextFieldGroup
                    label="Broj tekuceg racuna *"
                    placeholder="Broj tekuceg racuna"
                    name="companyBankAccount"
                    value={this.state.companyBankAccount}
                    onChange={this.onChange}
                    error={errors.companyBankAccount}
                  />
                </div>
              </div>
              <div className='row'>
                <div>
                  <label>
                    <input type="checkbox"
                      value={this.state.isVatExcluded}
                      checked={checked}
                      onChange={this.handleChange}
                      name="isVatExcluded"
                      label="U sistemu PDV-a"
                      style={{ width: '25px', height: '25px', marginRight: '10px', marginTop: '10px' }}
                    />
                    Nije u sistemu PDV-a
                  </label>
                </div>
                {showReasons}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <input
                    id="btn"
                    type="submit"
                    value="Izmeni"
                    className="btn-fill col-md-12 pull-center btn btn-info"
                    style={{ margin: '3px' }}
                  />
                </div>
                <div className="col-md-6">
                  <Link
                    to="/EInvoices"
                    className="btn-fill col-md-12 pull-center btn btn-default"
                    style={{ margin: '3px' }}
                  >
                    Nazad na Klijenti
                  </Link>
                </div>
              </div>
            </form>
          }
        />
      </Col> : null

    return (
      <div className="content">
        <Container fluid>
          <Row>
            {showData}
            <Col>
              <Card
                title='Broj faktura po mesecima'
                content={
                  <Col>
                    <Row>
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Jan</th>
                            <th>Feb</th>
                            <th>Mar</th>
                            <th>Apr</th>
                            <th>Maj</th>
                            <th>Jun</th>
                            <th>Jul</th>
                            <th>Avg</th>
                            <th>Sep</th>
                            <th>Okt</th>
                            <th>Nov</th>
                            <th>Dec</th>
                          </tr>
                        </thead>
                        <tbody>
                          <td>{this.state.invoicesNumber[0]}</td>
                          <td>{this.state.invoicesNumber[1]}</td>
                          <td>{this.state.invoicesNumber[2]}</td>
                          <td>{this.state.invoicesNumber[3]}</td>
                          <td>{this.state.invoicesNumber[4]}</td>
                          <td>{this.state.invoicesNumber[5]}</td>
                          <td>{this.state.invoicesNumber[6]}</td>
                          <td>{this.state.invoicesNumber[7]}</td>
                          <td>{this.state.invoicesNumber[8]}</td>
                          <td>{this.state.invoicesNumber[9]}</td>
                          <td>{this.state.invoicesNumber[10]}</td>
                          <td>{this.state.invoicesNumber[11]}</td>
                        </tbody>
                      </Table>
                    </Row>
                    <Row>
                      <div className="col-md-3">
                        <input
                          onClick={this.getInvoicesNumber}
                          type="button"
                          value="Proveri"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                    </Row>
                  </Col>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddInvoice.propTypes = {
  invoice: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getInvoiceById: PropTypes.func.isRequired,
  changeInvoiceData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  invoice: state.invoice,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getInvoiceById, changeInvoiceData }
)(withRouter(AddInvoice));


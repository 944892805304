import React, { Component } from 'react';
import AdminMenu from './AdminMenu';
import axios from 'axios';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';

class AllOrdersVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      statusValue: "",
      orderNo: ""
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders = async () => {
    try {
      const response = await axios.post(`/backendeshop/api/v1/auth/getAllOrderByVendor`, { vendorName: this.props.auth.user.NavCode });
      const allorders = response.data;
      this.setState({ orders: allorders });
      console.log(allorders)
    } catch (error) {
      console.error("Error fetching orders: ", error);
    }
  }

  roundToTwoDecimal = (number) => {
    return Math.round(number * 100) / 100;
  }

  formatirajDatum = (datum) => {
    const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    const lokalizovaniDatum = new Date(datum).toLocaleDateString('en-GB', options);
    return lokalizovaniDatum.split('.').join('.');
  }

  handleChange = async (orderId, status) => {
    try {
      // Provera da li korisnik želi da promeni status sa 1 na 2
      if (status === 2) {
        const { value: postOrderNo } = await Swal.fire({
          title: 'Unesite broj pošiljke pošte',
          input: 'text',
          inputLabel: 'Broj pošiljke',
          showCancelButton: true,
          confirmButtonText: 'Potvrdi',
          cancelButtonText: 'Odustani',
          inputValidator: (value) => {
            if (!value) {
              return 'Morate uneti broj pošiljke!'
            }
          }
        });

        if (postOrderNo) {
          const updateStatusResponse = await axios.put(`/backendeshop/api/v1/auth/updateOrderStatus/${orderId}`, {
            status,
            postOrderNo
          });

          if (updateStatusResponse.status === 200) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Status porudžbine je uspešno promenjen',
              showConfirmButton: false,
              timer: 1500
            });
            this.getOrders();
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Došlo je do greške prilikom ažuriranja statusa porudžbine.',
            });
          }
        }
      } else {
        const { data } = await axios.put(`/backendeshop/api/v1/auth/updateOrderStatus/${orderId}`, {
          status
        });
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Status porudžbine je uspešno promenjen',
          showConfirmButton: false,
          timer: 1500
        });
        this.getOrders();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Došlo je do greške prilikom ažuriranja statusa porudžbine.',
      });
    }
  };

  render() {
    const { orders, statusValue, orderNo } = this.state;
    const options = [
      { label: 'Obrada', value: 1 },
      { label: 'Isporučeno', value: 2 },
      { label: 'Dostavljeno', value: 3 },
      { label: 'Odbijeno', value: 4 }
    ];
    let tBody = (
      <tr>
        <td>Trenutno nema porudžbina</td>
      </tr>
    );
  
    if (orders.length > 0) {
      tBody = orders.map((order) => (
        
        <tr key={order._id}>
          <td>
            <p>Broj pošiljke:</p>
            <b>{order.orderNo}</b><br /><br />
            <p>Poručeno sa uređaja:<br />
              {order.deviceSerialNo}</p>
            <p>Broj pošiljke u pošti<br></br>
              <b>{order.postOrderNo}</b>
            </p>
          </td>
          <td>
            {order.status === '1' && (
              <p>Trenutni status: <p className="text-warning">Obrada</p></p>
            )}
            {order.status === '2' && (
              <p>Trenutni status: <p className="text-primary">Isporučeno</p></p>
            )}
            {order.status === '3' && (
              <p>Trenutni status: <p className="text-success">Dostavljeno</p></p>
            )}
            {order.status === '4' && (
              <p>Trenutni status: <p className="text-danger">Odbijeno</p></p>
            )}
            <Select
              value={options.find((opt) => opt.value === order.status)}
              options={options}
              placeholder='Izaberi status'
              onChange={(e) => this.setState({ statusValue: e.value })}
            />
            <br />
            <button
              type="button"
              className="btn btn-fill btn-primary"
              onClick={() => this.handleChange(order._id, statusValue)}
            >
              Promeni status
            </button><br /><br />
            <p>Poslednja promena: <br /><b>{this.formatirajDatum(order.updatedAt)}</b></p>
          </td>
          <td>
            <table className="table table-bordered table-striped border-dark">
              <thead >
                <tr>
                  <th className='text-dark'><b>Naziv proizvoda</b></th>
                  <th className='text-dark'><b>Cena po komadu</b></th>
                  <th className='text-dark'><b>Veličina paketa</b></th>
                  <th className='text-dark'><b>Količina</b></th>
                  <th className='text-dark'><b>Ukupna cena</b></th>
                </tr>
              </thead>
              <tbody>
                {order.products.map((product) => (
                  <tr key={product._id}>
                    <td>{product.name}</td>
                    <td>{this.roundToTwoDecimal(product.price)} RSD</td>
                    <td>{product.quantity} kom</td>
                    <td>{product.pack_quantity} kom</td>
                    <td>{this.roundToTwoDecimal(product.totalPrice)} RSD</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p>Način plaćanja: {order.paymentMethod}</p>
            <p>Ukupna cena porudžbine: <b>{this.roundToTwoDecimal(order.total)} RSD</b></p>
            {order.total < 3000 ? (
              <p><b>Plaća isporuku</b></p>
            ) : (
              <p><b>Besplatna isporuka</b></p>
            )}
            <p>Datum kreiranja porudžbine {this.formatirajDatum(order.createdDate)}</p>
          </td>
          <td>
            <p>
              Ime: {order.address.name}
              <br />
              Telefon: {order.address.phone}
              <br />
              Email: {order.address.email}
              <br />
              Ulica i broj: {order.address.deviceAddress}
              <br />
              Poštanski broj: {order.address.deviceAddrZip}
              <br />
              Grad: {order.address.deviceAddrCity}
              <br />
              {order.address.compVatId ? (
                <>
                  {order.address.compVatId.length === 13 ? (
                    <>
                      JMBG: {order.address.compVatId}
                      <br />
                      <b>Fizičko lice</b>
                    </>
                  ) : (
                    <>
                      PIB: {order.address.compVatId}
                      <br />
                      <b>Pravno lice</b>
                    </>
                  )}
                </>
              ) : null}
            </p>
          </td>
        </tr>
      ));
    }
  
   

    return (
      <div className="content">
        <div className="container-fluid m-3 p-3">
         
              <h1 className="text-center">Sve porudžbine</h1>
              <div className='row'>
             
                <div className=' col-md-3'>
                  <input
                    type='button'
                    value={`Ukupno porudžbina: ${orders?.length}`}
                    className="btn-fill pull-center btn btn-info"
                  />
                </div>
              </div>
              <div className="border shadow">
                <table className="table table-bordered table-striped">
                  <thead className='thead-info '>
                    <tr>
                      <th className='text-dark fs-6' scope='col'><b>Broj porudžbine</b></th>
                      <th className='text-dark fs-6' scope="col"><b>Status porudžbine</b></th>
                      <th className='text-dark fs-6' scope="col"><b>Porudžbina</b></th>
                      <th className='text-dark fs-6' scope="col"><b>Adresa isporuke</b></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tBody}
                  </tbody>
                </table>
                <div className="container">
            
                </div>
              </div>
            </div>
          </div>
       
    );
  }
}
const mapStateToProps = state => ({
  client: state.client,


  profile: state.profile,
  auth: state.auth,
  statistic: state.statistic,
});
export default connect(
  mapStateToProps,
  {
 
  
  }
) (AllOrdersVendor);


import React from 'react'
import { useState, useEffect } from 'react'
import { Row, Col, Table, Container } from 'react-bootstrap';
import axios from 'axios';
import TextFieldGroup from '../common/TextFieldGroup';
import Card from '../../components/Card/Card';
import Swal from 'sweetalert2';
import Select from 'react-select';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const InactiveLicence = () => {

  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [compName, setCompName] = useState('');
  const [vendorName, setVendorName] = useState('')
  const [compVatId, setCompVatId] = useState('');
  const [KandDist, setKandDist] = useState('')
  const [checkedValues, setCheckedValues] = useState({
    distributeri: false,
    komercijalisti: false
  });
  const [kom, setKom] = useState()
  const [dist, setDist] = useState()
  const [selectedVendorName, setSelectedVendorName] = useState(null);
  const [vendor, setVendor] = useState()
  const [passThisVendor, setPassThisVendor] = useState('');

  useEffect(() => {
    searchDistAndK();
  }, []);
  function formatirajDatum(datum) {
    const options = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    const lokalizovaniDatum = new Date(datum).toLocaleDateString('en-GB', options);
    return lokalizovaniDatum.split('.').join('.');
  }

  const selectedVendor = async () => {

    const response = await axios.post('/api/admin/allKandDist');

    var optionVendor = ''

    if (checkedValues.komercijalisti == true && checkedValues.distributeri == false) {
      optionVendor = response.data;
      const komVendor = optionVendor.filter(user => user.reportRole === 'K');
      optionVendor = komVendor.map((user) => ({
        label: user.name,
        value: user.NavCode,
      }))
      //console.log(kom)
    } else if (checkedValues.distributeri == true && checkedValues.komercijalisti == false) {
      optionVendor = response.data;
      const distVendor = optionVendor.filter(user => user.reportRole === 'D')
      optionVendor = distVendor.map((user) => ({
        label: user.name,
        value: user.NavCode,
      }))
      // console.log(distVendor)
    } else {
      optionVendor = response.data;
      optionVendor = optionVendor.map((user) => ({
        label: user.name,
        value: user.NavCode,
      }));
    }

    optionVendor.unshift({ label: "Svi prodavci...", value: "" })
    setVendor(optionVendor);
    //console.log(vendor)
    //console.log(optionVendor)

  };
  useEffect(() => {
    selectedVendor()
  }, [checkedValues])


  const searchClients = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/admin/allInactiveLicence', {
        whatToLook: compName || compVatId || vendorName,
        whereToLook: compName ? 'compName' : compVatId ? 'compVatId' : 'vendorName',
        selectedVendorName: passThisVendor,
      });
      var filteredClients = ''


      if (checkedValues.distributeri == true && checkedValues.komercijalisti == true) {
        filteredClients = response.data
        const komNavCode = kom.map(user => user.NavCode);
        const distNavCode = dist.map(user => user.NavCode);
        filteredClients = filteredClients.filter(client => komNavCode.includes(client.vendorName) || distNavCode.includes(client.vendorName))
      }


      else if (checkedValues.komercijalisti == true && checkedValues.distributeri == false) {
        const komNavCodes = kom.map(user => user.NavCode);
        filteredClients = response.data;
        filteredClients = filteredClients.filter(client => komNavCodes.includes(client.vendorName));
        /* setSelectVendor ( kom.map(user => ({
           label: user.name,
           value: user.NavCode
         })))*/
      }
      else if (checkedValues.distributeri == true && checkedValues.komercijalisti == false) {
        filteredClients = response.data;
        const distNavCodes = dist.map(user => user.NavCode);
        filteredClients = filteredClients.filter(client => distNavCodes.includes(client.vendorName));

      }
      else {
        filteredClients = null
      }


      setClientData(filteredClients);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client data:', error);
      setLoading(false);
    }
  };

  const onChangeActivate = async (cbLicenceId, statusLicence) => {
    Swal.fire({
      title: 'Aktiviraj licencu?',
      text: "Da li sigurno želite da aktivirate licencu ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Aktiviraj!'
    }).
      then((result) => {
        if (result.isConfirmed) {
          axios.post(`/api/clients/changeLicence/${cbLicenceId}/${'Aktivna'}`)
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Status licence je uspešno promenjen',
            showConfirmButton: false,
            timer: 3000
          })
          setTimeout(function () {
            window.location.reload()
          }, 3000);
        }
      })
  };
  //uzimanje svih komercijalista i distributera iz baze i rasvrstava ih u dist ili kom
  const searchDistAndK = async () => {
    try {
      setLoading(true);

      const response = await axios.post('/api/admin/allKandDist');
      const kandDistData = response.data;

      setKandDist(kandDistData);

      const komUsers = kandDistData.filter(user => user.reportRole === 'K');
      setKom(komUsers);

      const distUsers = kandDistData.filter(user => user.reportRole === 'D');
      setDist(distUsers);

      setLoading(false);

      // Ovde ćeš dobiti ažurirane podatke
      //console.log(kom); // Ovde ćeš dobiti ažurirane podatke za korisnike sa reportRole 'K'
      //console.log(dist); // Ovde ćeš dobiti ažurirane podatke za korisnike sa reportRole 'D'
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const handleCheck = (event) => {
    const { value, checked } = event.target;
    setCheckedValues((prevValues) => ({
      ...prevValues,
      [value]: checked,

    }));

  };

  return (
    <div className='content'>
      <Container fluid>
        <Row className='justify-content-center'>
          <Col md={3}>
            <TextFieldGroup
              type="text"
              value={compName}
              label="Ime kompanije"
              name="compName"
              onChange={(e) => setCompName(e.target.value)}
              placeholder='Ime kompanije'
            />
          </Col>
          <Col md={3}>
            <TextFieldGroup
              type="text"
              value={compVatId}
              label="PIB"
              name="compVatId"
              onChange={(e) => setCompVatId(e.target.value)}
              placeholder='PIB'
            />
          </Col>

          <Col md={2}>
            <div className="row">
              <div className="col-md-12" style={{ marginTop: '25px' }}>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    value="distributeri"
                    checked={checkedValues.distributeri}
                    onChange={handleCheck}

                  />
                  <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                    Distributeri
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    value="komercijalisti"
                    checked={checkedValues.komercijalisti}
                    onChange={handleCheck}
                  />
                  <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                    Komercijalisti
                  </label>
                </div>
              </div>
            </div>
          </Col>
          <Col md={3}
            style={{ marginTop: '25px' }}>
            <Select
              placeholder="Selektuj prodavca..."
              options={vendor}
              value={selectedVendorName}
              onChange={e => { setSelectedVendorName(e); setPassThisVendor(e.value) }}
            />
          </Col>
        </Row>
        <Row>

          <div className="row">

            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <input
                type='button'
                value='Pretraži'
                onClick={searchClients.bind(this)}
                className="btn-fill col-md-6 pull-center btn btn-info  "
                style={{ marginTop: '25px' }}
              />
            </div>

          </div>
        </Row>
        {loading ? (
          <p>Pretrazivanje...</p>
        ) : clientData ? (
          <Row style={{ marginTop: '40px' }}>
            <Col md={12}>
              <p>Ukupan broj neaktivni licenci je: {clientData.length}</p>
              <Card
                title="Licence"
                category="Informacije o licencama"
                ctTableFullWidth
                ctTableResponsive

                content={
                  <><ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button  btn btn-danger btn-fill "
                    table="table-to-xls"
                    filename="neaktivnelicence"
                    sheet="tablexls"
                    buttonText="Preuzmi XLS" />
                    <Table striped hover id='table-to-xls'>
                      <thead>
                        <tr>
                          <th>Ime kompanije</th>
                          <th>PIB (VatId)</th>
                          <th>Serijski broj uredjaja</th>
                          <th>Datum deaktivacije</th>
                          <th></th>
                          <th>ID licence</th>
                          <th>Sifra prodavca</th>
                        </tr>
                      </thead>
                      <tbody>
                        {clientData.map((client) => (
                          <tr key={client._id}>
                            <td style={{ wordBreak: 'break-word' }}>
                              {client.compName}
                            </td>
                            <td>{client.compVatId}</td>
                            <td>{client.deviceSerialNo}</td>
                            <td>{formatirajDatum(client.dateOfDeactivated)}</td>
                            <td> <button
                              type="button"
                              className="btn-fill btn btn-sm btn-danger"
                              onClick={onChangeActivate.bind(this, client.cbLicenceId, client.statusLicence)}
                            >
                              Neaktivan
                            </button></td>
                            <td>{client.cbLicenceId}</td>
                            <td>{client.vendorName}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table></>
                }
              />
            </Col>
          </Row>


        ) : (
          <p>Nema dostupnih podataka o klijentima</p>
        )}
      </Container>
    </div>
  );
}

export default InactiveLicence
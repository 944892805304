import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getProfileById, passwordResetID, updateProfile } from '../../actions/profileAction';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import { Card } from '../../components/Card/Card';
import { confirmAlert } from 'react-confirm-alert';
import classnames from 'classnames';

class ProfileEdit extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      onCall: '',
      _id: '',
      phone: '',
      position: '',
      password: '',
      password2: '',
      email: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPasswordReset = this.onPasswordReset.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    if (this.props.match.params.id) {
      console.log(this.props.match.params.id);
      this.props.getProfileById(this.props.match.params.id);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile.profile) {
      const profile = nextProps.profile.profile;

      profile._id = !isEmpty(profile._id) ? profile._id : '';
      profile.phone = !isEmpty(profile.phone) ? profile.phone : '';
      profile.name = !isEmpty(profile.name) ? profile.name : '';
      profile.email = !isEmpty(profile.email) ? profile.email : '';
      profile.position = !isEmpty(profile.position) ? profile.position : '';
      profile.password = !isEmpty(profile.password) ? profile.password : '';
      profile.password2 = !isEmpty(profile.password2) ? profile.password2 : '';

      // Set component fields state
      this.setState({
        phone: profile.phone,
        email: profile.email,
        position: profile.position,
        name: profile.name,
        _id: profile._id,
        onCall: profile.onCall
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const profileData = {
      _id: this.state._id,
      phone: this.state.phone,
      position: this.state.position,
      email: this.state.email,
      onCall: this.state.onCall,
    };

    this.props.updateProfile(profileData, this.props.history);
  }

  onPasswordReset(e) {
    e.preventDefault();
    const profileData2 = {
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2
    };
    confirmAlert({
      title: 'Resetuj lozinku',
      message: 'Da li sigurno zelite da resetujete?',
      buttons: [
        {
          label: 'Da',
          onClick: () =>
            this.props.passwordResetID(profileData2, this.props.history)
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
  }



  render() {
    const { errors } = this.state;

    //const { services } = this.props.services;
    //const optionsService = services.map(services => ({
    //  label: services.name,
    //  value: services._id
    //}));
    //optionsService.unshift({ label: '* Selektujte servis', value: '' });

    let profileContent;

    profileContent = (
      <Container fluid>
        <Row>
          <Col md={6}>
            <Card
              title={this.state.name}
              category="Podaci o korisniku"
              content={
                <form onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-md-12">
                      <TextFieldGroup
                        label="Ime korisnika"
                        placeholder="Ime korisnika"
                        name="name"
                        value={this.state.name}
                        error={errors.name}
                        disabled="disabled"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextFieldGroup
                        label="Email adresa"
                        placeholder="Email Address"
                        name="email"
                        type="email"
                        value={this.state.email}
                        error={errors.email}
                        disabled="disabled"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <TextFieldGroup
                        label="Telefon korisnika"
                        placeholder="Telefon korisnika"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.onChange}
                        error={errors.phone}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label classname="control-label">Pozicija</label>
                      <select
                        label="Pozicija"
                        className={classnames('form-control form-control-lg')}
                        name="position"
                        value={this.state.position}
                        onChange={this.onChange}
                      >
                        <option value="">* Select Position</option>
                        <option value="PM">Serviser/Korisnik</option>
                        <option value="Admin">Admin</option>
                        <option value="Dist">Distributer</option>
                        <option value="Komercijalista">Komercijalista</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label classname="control-label">Dežurni</label>
                    <select
                      label="Dezurni"
                      className={classnames('form-control form-control-lg')}
                      name="onCall"
                      value={this.state.onCall}
                      onChange={this.onChange}
                    >
                      <option value="">* Dezurni</option>
                      <option value="Yes">Da</option>
                      <option value="No">Ne</option>
                    </select>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Link
                        to="/profiles"
                        className="btn-fill col-md-12 pull-center btn btn-default"
                        style={{ margin: '3px' }}
                      >
                        Nazad
                      </Link>
                    </div>
                    <div className="col-md-6">
                      <input
                        type="submit"
                        value="Sačuvaj"
                        className="btn-fill pull-right btn btn-info">
                      </input>
                    </div>
                  </div>
                  <div className="clearfix"> </div>
                </form>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Card
              title="Password Reset"
              category="* Da biste resetovali lozinku potrebno je uneti staru lozinku"
              content={
                <form onSubmit={this.onPasswordReset}>
                  <div className="row">
                    <div className="col-md-4">
                      <TextFieldGroup
                        label="Nova lozinka"
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password2}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextFieldGroup
                        label="Ponovi lozinku"
                        name="password2"
                        type="password"
                        value={this.state.password2}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <input
                    type="submit"
                    value="Reset"
                    className="btn-fill pull-right btn btn-danger"
                  />
                  <div className="clearfix"> </div>
                </form>
              }
            />
          </Col>
        </Row>
      </Container>
    );

    return <div className="content">{profileContent}</div>;
  }
}

ProfileEdit.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  passwordResetID: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getProfileById, passwordResetID, updateProfile }
)(ProfileEdit);

import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const UpdateProduct = () => {
  const params = useParams();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [id, setId] = useState("");
  const [sizeS, setSizeS] = useState(""); // Abbreviation for Small
  const [sizeM, setSizeM] = useState(""); // Abbreviation for Medium
  const [sizeL, setSizeL] = useState(""); // Abbreviation for Large

  // Fetch product data
  const getSingleProduct = async () => {
    try {
      const { data } = await axios.get(
        `/backendeshop/api/v1/product/get-product/${params.slug}`
      );
      setName(data.product.name);
      setId(data.product._id);
      setDescription(data.product.description);
      setPrice(data.product.price);
      setQuantity(data.product.quantity);

      // Set package sizes
      setSizeS(data.product.packageSizes.S);
      setSizeM(data.product.packageSizes.M);
      setSizeL(data.product.packageSizes.L);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleProduct();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      let answer = await Swal.fire({
        title: "Da li ste sigurni?",
        text: "Ova radnja ne može biti poništena!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Da, izmeni!",
        cancelButtonText: "Odustani",
      });
      if (answer.isConfirmed) {
        const productData = {
          name,
          description,
          price,
          quantity,
          packageSizes: {
            S: sizeS,
            M: sizeM,
            L: sizeL,
          },
        };

        await axios.put(
          `/backendeshop/api/v1/product/update-product/${id}`,
          productData
        );

        Swal.fire({
          icon: "success",
          title: "Proizvod ažuriran",
          showConfirmButton: false,
          timer: 1500,
        });

        window.location.href = "/galebshop/products";
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Greška!", "Došlo je do greške prilikom izmene proizvoda.", "error");
    }
  };

  // Delete product
  const handleDelete = async () => {
    try {
      let answer = await Swal.fire({
        title: "Da li ste sigurni?",
        text: "Ova radnja ne može biti poništena!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Da, obriši!",
        cancelButtonText: "Odustani",
      });
      if (answer.isConfirmed) {
        const { data } = await axios.delete(
          `/backendeshop/api/v1/product/delete-product/${id}`
        );

        Swal.fire("Obrisano!", "Proizvod je uspešno obrisan.", "success");
        window.location.href = "/galebshop/products";
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Greška!", "Došlo je do greške prilikom brisanja proizvoda.", "error");
    }
  };

  return (
    <div className="content">
      <div className="container-fluid m-3 p-3">
        <div className="row">
          <div className="col-md-8">
            <h1>Izmena proizvoda: {name}</h1>
            <div className="m-1 w-75">
              <div className="mb-3">
                <input
                  type="text"
                  value={name}
                  placeholder="Ime proizvoda"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <textarea
                  type="text"
                  value={description}
                  placeholder="Napiši opis"
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={price}
                  placeholder="Cena"
                  className="form-control"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  value={quantity}
                  placeholder="Količina"
                  className="form-control"
                  onChange={(e) => setQuantity(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <p>Veličine paketa:</p>
                <div className="form-group">
                  <label>S:</label>
                  <input
                    type="number"
                    value={sizeS}
                    className="form-control"
                    onChange={(e) => setSizeS(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>M:</label>
                  <input
                    type="number"
                    value={sizeM}
                    className="form-control"
                    onChange={(e) => setSizeM(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>L:</label>
                  <input
                    type="number"
                    value={sizeL}
                    className="form-control"
                    onChange={(e) => setSizeL(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3">
                <button className="btn-fill col-md-5 pull-center btn btn-primary" onClick={handleUpdate}>
                  Izmeni proizvod
                </button>
              </div>
              <div className="mb-3">
                <button className="btn-fill col-md-5 pull-center btn btn-danger" onClick={handleDelete}>
                  Obriši proizvod
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;

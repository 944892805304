import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextFieldGroup from '../common/TextFieldGroup'; 
import Select from 'react-select';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

function UpdategalebStart(props) {
  const [compName, setCompName] = useState('');
  const [compVatId, setCompVatId] = useState('');
  const [vendorName, setVendorName] = useState('');
  const [error, setError] = useState(null);
  const [profiles, setProfiles] = useState([]); 

  const clientId = props.match.params._id; // Uzimanje ID-ja korisnika iz URL parametra
  console.log(clientId)

  const handleUpdateClient = async () => {
    setError(null);


    if (compVatId.length === 9) {
      try {
        
            console.log(props)
        const selectedProfile = profiles.find(profile => profile.value === vendorName);
        if (!selectedProfile) {
          setError('Izabrani komercijalista ne postoji među profilima.');
          return;
        }

        const response = await axios.post(`/api/galebstart/updateClient/${clientId}`, {
          compName,
          compVatId,
          vendorName: selectedProfile.value // Koristimo value iz izabranog profila
        });
        Swal.fire({
          icon: 'success',
          title: 'Korisnik ažuriran',
          showConfirmButton: false,
          timer: 1500 })
        //alert('Korisnik ažuriran');
        console.log('Klijent ažuriran:', response.data);
      } catch (error) {
        setError('Greška prilikom ažuriranja klijenta');
        console.error('Error updating client:', error);
      }
    } else {
      setError('PIB mora imati 9 znakova');
    }
  };

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const userResponse = await axios.post(`/api/admin/allKandDist`);
        setProfiles(userResponse.data.map(profile => ({
          label: profile.name,
          value: profile.NavCode
        })));
      } catch (error) {
        setError('Greška pri preuzimanju profila');
        console.error('Error fetching profiles:', error);
      }
    }

    fetchProfiles();
  }, []);

  return (
    <div className='content'>
      <div className='row'>
        <div className='col-md-4'>
          <TextFieldGroup
            type="text"
            value={compName}
            onChange={(e) => setCompName(e.target.value)}
            placeholder="Ime kompanije"
          />
        </div>
        <div className='col-md-4'>
          <TextFieldGroup
            type="text"
            value={compVatId}
            onChange={(e) => setCompVatId(e.target.value)}
            placeholder="PIB"
          />
        </div>
        <div className='col-md-4'>
          <Select
            options={profiles}
            value={profiles.find(profile => profile.value === vendorName)} 
            onChange={selectedOption => setVendorName(selectedOption.value)} 
            style={{ marginTop: '25px' }}
          />
        </div>
      </div>
      <div className='div className="d-grid gap-2 d-md-flex justify-content-md-center'>
        <Link 
        to ='/galebstart'
          type='button' 
          onClick={handleUpdateClient} 
          value="Ažuriraj korisnika" 
          className="btn-fill col-md-6 pull-center btn btn-info"
          style={{ marginTop: '25px' }}

        >Ažuriraj korisnika</Link>
      </div>
      {error && <p>{error}</p>}
    </div>
  );
}

export default UpdategalebStart;

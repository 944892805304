import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

class Footer extends Component {
  render() {
    const authLinks =
    this.props.auth.user.position !== 'Klijent' ? (
      <div className="container"> 
      <footer className="py-1 my-1">
       {/* <ul className='nav justify-content-center border-bottom pb-3 mb-3'> 
          <li className='nav-item'>
            <a className='nav-link px-2 text-muted' href="https://www.linkedin.com/company/galeb/mycompany/"><i className="fa fa-linkedin"></i></a>
          </li>
          <li className='nav-item'>
            <a className='nav-link px-2 text-muted' href="https://sr-rs.facebook.com/galebgroup/"><i className="fa fa-facebook"></i></a>
          </li>
          <li className='nav-item'>
            <a className='nav-link px-2 text-muted' href="https://www.instagram.com/galeb_group/"><i className="fa fa-instagram"></i></a>
          </li>
      </ul>*/}
      <div className="text-center text-muted">
        <div> <i className="pe-7s-tools"> TechTool </i> </div>
          &copy; {new Date().getFullYear()}{' '}
            Galeb Electronics 
      </div>
    </footer>
    </div>
    
    ) : (
      <footer className="footer">
        <Container fluid>
          <nav className="pull-left">
            <ul>
              <li>
                <a href="https://www.linkedin.com/company/galeb/mycompany/"><i className="fa fa-linkedin"></i></a>
              </li>
              <li>
                <a href="/https://sr-rs.facebook.com/galebgroup/"><i className="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/galeb_group/"><i className="fa fa-facebook"></i></a>
              </li>
            </ul>
          </nav>
          
        <div> <i className="pe-7s-tools"> TechTool </i> </div>
          <div className="copyright pull-right">
            &copy; {new Date().getFullYear()}{' '}
              Galeb Electronics
          </div>
        </Container>
      </footer>
      );
    return authLinks;
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Footer);
import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getClients, deleteClient, getClientByCompId, getUnseenLogs } from '../../actions/clientActions';
import Card from '../../components/Card/Card';
import { StatsCard } from '../StatsCard/StatsCard';
import TextFieldGroup from '../common/TextFieldGroup';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import NotificationSystem from 'react-notification-system';
import { style } from '../../variables/Variables.jsx';
import axios from 'axios';
import Swal from 'sweetalert2';

class ClientTablePM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compName: '',
      compVatId: '',
      _notificationSystem: null,
      notificationShown: false,
      compId: '',
      deviceSerialNo: '',
      vendorName: ''
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.getUnseenLogs();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.notificationShown === false) {
      this.setState({ notificationShown: true })
      this.setState({ _notificationSystem: this.refs.notificationSystem });
      var _notificationSystem = this.refs.notificationSystem;
      if (nextProps.client.allUnseenLogs.length > 0) {
        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-note2" />,
          message: (
            <div>
              <b>
                Novih odgovora u logovima{' '}
                {
                  nextProps.client.allUnseenLogs.length
                }
              </b>
            </div>
          ),
          level: 'warning',
          position: 'tr',
          autoDismiss: 15
        });
      } else {
        _notificationSystem.addNotification({
          title: <span data-notify="icon" className="pe-7s-note2" />,
          message: (
            <div>
              <b>
                Trenutno nema novih odgovora
              </b>
            </div>
          ),
          level: 'warning',
          position: 'tr',
          autoDismiss: 15
        });
      }
    }
  }

  async changeAC(compVatId) {
    const { value: comment } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Šifra delatnosti',
      inputPlaceholder: 'Unesite šifru delatnosti...',
      inputAttributes: {
        'aria-label': 'Unesite šifru delatnosti'
      },
      showCancelButton: true
    })

    if (comment) {
      const data = {
        acticityCode: comment,
        compVatId: compVatId
      }

      axios.post(`/api/utility/addActivityCode/`, data)
        .then(res => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Šifra delatnosti je uspešno promenjena',
            showConfirmButton: false,
            timer: 3000
          })
        })
        .catch(err => {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Greška',
            showConfirmButton: false,
            timer: 3000
          })
          console.log(err)
        })
    }

  }

  changeSN(deviceSerialNo, _id) {
    const data = {
      SN: deviceSerialNo,
      id: _id
    }
    axios.post(`/api/utility/ChangeSNForServicer/`, data)
      .then(res => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Serijski broj licence je uspešno promenjen',
          showConfirmButton: false,
          timer: 3000
        })
      })
      .catch(err => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Greška',
          showConfirmButton: false,
          timer: 3000
        })
        console.log(err)
      })
  }

  onKeyUpCheck = e => {
    if (e.key === 'Enter') {
      if (this.state.compId.length > 2) { this.props.getClientByCompId(this.state.compId.trim(), "compId") }
      else if (this.state.compVatId.length > 2) { this.props.getClientByCompId(this.state.compVatId.trim(), "compVatId") }
      else if (this.state.compName.length > 2) { this.props.getClientByCompId(this.state.compName.trim(), "compName") }
      else if (this.state.deviceSerialNo.length > 2) { this.props.getClientByCompId(this.state.deviceSerialNo.trim(), "deviceSerialNo") }
      else if (this.state.vendorName.length > 2) { this.props.getClientByCompId(this.state.vendorName.trim(), "vendorName") }
    }
  }
  updateSearchClick = () => {
    if (this.state.compId.length >= 3) { this.props.getClientByCompId(this.state.compId.trim(), "compId") }
    else if (this.state.compVatId.length >= 3) { this.props.getClientByCompId(this.state.compVatId.trim(), "compVatId") }
    else if (this.state.compName.length >= 3) { this.props.getClientByCompId(this.state.compName.trim(), "compName") }
    else if (this.state.deviceSerialNo.length >= 3) { this.props.getClientByCompId(this.state.deviceSerialNo.trim(), "deviceSerialNo") }
    else if (this.state.vendorName.length >= 3) { this.props.getClientByCompId(this.state.vendorName.trim(), "vendorName") }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { loading } = this.props.client;
    const clients = this.props.client.clients;
    var aktivni = 0;
    var neaktivni = 0;

    let clientItems;
    if (clients === null || loading) {
      clientItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );

    } else {
      if (clients.length > 0) {
        let countActive = 0;
        let countInactive = 0;
        var count = clients.length;
        for (let i = 0; i < count; i++) {
          if (clients[i].statusLicence === 'Aktivna') {
            countActive += 1;
          }
          else if (clients[i].statusLicence === 'Neaktivna') {
            countInactive += 1;
          }
        }
        aktivni = countActive;
        neaktivni = countInactive;
        clientItems = clients.map(client => (
          <tr key={client._id}>
            <td>{client.activationDate.split('T00:00:00.000Z')}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
            <td>{client.activityCode}</td>
            <td>{client.compVatId}</td>
            <td>{client.compId}</td>
            <td>{client.compPhone}</td>
            <td>{client.userFirstName} {client.userLastName}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.userEmail}</td>
            <td>{client.userPhone}</td>
            <td>{client.vendorName}</td>
            <td>{client.deviceSerialNo}</td>
            <td>Sim: {(() => {
              switch (client.simCard) {
                case 'Da':
                  return (
                    <span>
                      {client.simCard}
                    </span>
                  );
                default:
                  return (
                    <span>
                      Ne
                    </span>
                  );
              }
            })()}
              <br />
              KP: {(() => {
                switch (client.payment) {
                  case 'Aktivno':
                    return (
                      <span>
                        Aktivno
                      </span>
                    );
                  case 'Suspendovano':
                    return (
                      <span>
                        Suspendovano
                      </span>
                    );
                  default:
                    return (
                      <span>
                        Neaktivno
                      </span>
                    );
                }
              })()}</td>
            <td>{client.deviceAddrCity}, {client.deviceAddress}</td>
            <td>{(() => {
              switch (client.statusLicence) {
                case 'Aktivna':
                  return (
                    <span className="text-success">
                      {client.statusLicence}
                    </span>
                  );
                case 'Neaktivna':
                  return (
                    <span className="text-danger">
                      {client.statusLicence}
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td>
              <Row>
                <div className="btn-group mb-2">
                  <Link
                    type="button"
                    className="btn-fill btn btn-sm btn-success"
                    to={`/clientsPM/remove-client/${client._id}`}
                  >Zahtev za brisanje
                  </Link>
                </div>
                <div className="btn-group mb-2">
                  <Link
                    to={`/clientsPM/ClientCallHistory/${client._id}`}
                    className="btn-fill btn btn-sm btn-secondary"
                  >Logovi
                  </Link>
                </div>
                <div className="btn-group mb-2">
                  <button
                    className="btn-fill btn btn-sm btn-primary"
                    onClick={this.changeSN.bind(this, client.deviceSerialNo, client._id)}
                  >Izmeni SN
                  </button>
                </div>
                <div className="btn-group mb-2">
                  <button
                    className="btn-fill btn btn-sm btn-info"
                    onClick={this.changeAC.bind(this, client.compVatId)}
                  >Šifra delatnosti
                  </button>
                </div>
              </Row>
            </td>
          </tr>
        ));
      }
      else {
        clientItems = (
          <tr>
            <td>Licenca sa tim podacima nije pronađena...</td>
          </tr>
        );
      }
    }

    return (
      <div className="content">
        <NotificationSystem ref="notificationSystem" style={style} />
        <Row className='justify-content-center'>
          <Col lg={3} sm={3}>
            <StatsCard
              bigIcon={<i className="pe-7s-check text-success" />}
              statsText="Aktivni"
              statsValue={
                <CountUp
                  className="account-balance"
                  start={0}
                  end={aktivni}
                  duration={1}
                  useGrouping={true}
                />}
              statsIconText="Broj aktivnih uređaja"
            />
          </Col>
          <Col lg={3} sm={3}>
            <StatsCard
              bigIcon={<i className="pe-7s-calculator text-info" />}
              statsText="Svi"
              statsValue={
                <CountUp
                  className="account-balance"
                  start={0}
                  end={aktivni + neaktivni}
                  duration={1}
                  useGrouping={true}
                />}
              statsIconText="Ukupan broj svih uređaja"
            />
          </Col>
          <Col lg={3} sm={3}>
            <StatsCard
              bigIcon={<i className="pe-7s-close-circle text-danger" />}
              statsText="Neaktivni"
              statsValue={
                <CountUp
                  className="account-balance"
                  start={0}
                  end={neaktivni}
                  duration={1}
                  useGrouping={true}
                />}
              statsIconText="Broj neaktivnih uređaja"
            />
          </Col>
        </Row>
        <Container fluid>
          <form onKeyUp={this.onKeyUpCheck.bind(this)}>
            <Row className='justify-content-center'>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.compName}
                  placeholder="Ime kompanije"
                  name="compName"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.compVatId}
                  placeholder="Pib"
                  name="compVatId"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.compId}
                  placeholder="MB"
                  name="compId"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.deviceSerialNo}
                  placeholder="Serijski broj"
                  name="deviceSerialNo"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.vendorName}
                  placeholder="Sifra distributera"
                  name="vendorName"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <Col md={2}>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="button"
                      value="Pretrazi"
                      onClick={this.updateSearchClick.bind(this)}
                      className="btn-fill col-md-12 pull-center btn btn-info"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </form>
          <br></br>
          <Row>
            <Col md={12}>
              <Card
                title="Licence"
                category="Informacije o licencama"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Datum fiskalizacije</th>
                        <th>Ime kompanije</th>
                        <th>Šifra delatnosti</th>
                        <th>PIB (VatId)</th>
                        <th>MB (CompId)</th>
                        <th>Kontak telefon kompanije</th>
                        <th>Kontakt osoba</th>
                        <th>Email kontakt osobe</th>
                        <th>Telefon kontakt osobe</th>
                        <th>Vendor</th>
                        <th>SN</th>
                        <th>Dodatno</th>
                        <th>Adresa uređaja</th>
                        <th>Status licence</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{clientItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ClientTablePM.propTypes = {
  deleteClient: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getClientByCompId: PropTypes.func.isRequired,
  getUnseenLogs: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client
});

export default connect(
  mapStateToProps,
  { getClients, deleteClient, getClientByCompId, getUnseenLogs }
)(ClientTablePM);

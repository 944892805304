import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getDeletedClients, getDeletedClientsByFilter, getDeletedClientsByVendorSearch, getDeletedClientsByDate } from '../../actions/clientActions';
import { getCurrentProfile } from '../../actions/profileAction';
import { Accordion } from 'react-bootstrap';
import ReactToExcel from 'react-html-table-to-excel';
import DeletedClientByWho from './DeletedClientByWho';

class DeletedClientsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dateFrom: '',
			dateUntil: '',
			navCodeTime: '',
			navCode: '',
			search: ''
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange = d => {
		this.setState({
			[d.target.name]: d.target.value
		}, () => { console.log([d.target.name] + "." + d.target.value) })
	}

	searchByVatIdAndSn() {
		if (this.state.search.length >= 3) {
			this.props.getDeletedClientsByFilter(this.state.search);
		}
	}

	searchByDate() {
		const searchCriteria = {
			dateFrom: this.state.dateFrom,
			dateUntil: this.state.dateUntil,
			navCodeTime: this.state.navCodeTime,
		}
		console.log(searchCriteria);
		if (this.state.dateFrom !== '' && this.state.dateUntil !== '' && this.state.navCodeTime !== '') {
			this.props.getDeletedClientsByDate(searchCriteria)
		}
	}

	searchByNavCode() {
		if (this.state.navCode.length > 2)
			this.props.getDeletedClientsByVendorSearch(this.state.navCode);
		console.log(this.state.navCode)
	}

	render() {
		const { loading } = this.props.deleted;
		const removedClients = this.props.deleted.deletedclients;
		const removedClientsByDate = this.props.deleted.deletedclientsbydate;
		const remocedClientsByVendor = this.props.deleted.deletedclientsbyvendor;
		let deletedClientsAdminByDate;
		let deletedClientsAdminByVendor;
		let deletedClientsAdmin;
		if (remocedClientsByVendor === null || loading) {
			deletedClientsAdminByVendor = (
				<tr>
					<td>
						<Spinner />
					</td>
				</tr>
			);
		} else {
			if (remocedClientsByVendor.length > 0) {
				deletedClientsAdminByVendor = remocedClientsByVendor.map(client => (
					<tr key={client._id}>
						<td>{client.dateOfActivation.split('T00:00:00.000Z')}</td>
						<td>{client.dateOfRemoval.split('T00:00:00.000Z')}</td>
						<td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
						<td>{client.compVatId}</td>
						<td>{client.vendorName}</td>
						<td>{client.deviceSerialNo}</td>
						<td>{client.reason}</td>
					</tr>
				));
			}
			else {
				deletedClientsAdminByVendor = (
					<tr>
						<td>Licenca sa tim podacima nije pronađena... </td>
					</tr>
				);
			}
		}
		if (removedClientsByDate === null || loading) {
			deletedClientsAdminByDate = (
				<tr>
					<td>
						<Spinner />
					</td>
				</tr>
			);
		} else {
			if (removedClientsByDate.length > 0) {
				deletedClientsAdminByDate = removedClientsByDate.map(client => (
					<tr key={client._id}>
						<td>{client.dateOfActivation.split('T00:00:00.000Z')}</td>
						<td>{client.dateOfRemoval.split('T00:00:00.000Z')}</td>
						<td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
						<td>{client.compVatId}</td>
						<td>{client.vendorName}</td>
						<td>{client.deviceSerialNo}</td>
						<td>{client.reason}</td>
					</tr>
				));
			}
			else {
				deletedClientsAdminByDate = (
					<tr>
						<td>Licenca sa tim podacima nije pronađena... </td>
					</tr>
				);
			}
		}
		if (removedClients === null || loading) {
			deletedClientsAdmin = (
				<tr>
					<td>
						<Spinner />
					</td>
				</tr>
			);
		}
		else {
			if (removedClients.length > 0) {
				deletedClientsAdmin = removedClients.map(client => (
					<tr key={client._id}>
						<td>{client.dateOfActivation.split('T00:00:00.000Z')}</td>
						<td>{client.dateOfRemoval.split('T00:00:00.000Z')}</td>
						<td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
						<td>{client.compVatId}</td>
						<td>{client.vendorName}</td>
						<td>{client.deviceSerialNo}</td>
						<td>{client.reason}</td>
					</tr>
				));
			}
			else {
				deletedClientsAdmin = (
					<tr>
						<td>Licenca sa tim podacima nije pronađena... </td>
					</tr>
				);
			}
		}

		return (
			<div className="content">
				<Container fluid>
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<Container>
									<Row style={{ justifyContent: 'space-between' }}>
										<Col md={4}>
											Izbrisani klijenti pretraga po PIB-u i SN
										</Col>
										<Col md={2} />
									</Row>
								</Container>
							</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col md={9}>
										<input
											className="form-control form-control-lg col-md-11"
											type="text"
											placeholder="Pretraga licenci..."
											name="search"
											style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
											onChange={this.onChange.bind(this)}
										/>
									</Col>
									<Col md={3}>
										<input
											type="submit"
											value="Pretraga"
											className="btn-fill col-md-12 pull-center btn btn-info"
											onClick={this.searchByVatIdAndSn.bind(this)}
										/>
									</Col>
								</Row>
								<Table striped hover id="table-to-xls1" >
									<thead>
										<tr>
											<th>Datum aktivacije</th>
											<th>Datum brisanja</th>
											<th>Ime kompanije</th>
											<th>Pib</th>
											<th>Nav sifra prodavca</th>
											<th>Serijski broj uređaja</th>
											<th>Razlog brisanja</th>
										</tr>
									</thead>
									<tbody>{deletedClientsAdmin}</tbody>
								</Table>
								<ReactToExcel
									font="TimesNewRoman"
									className="btn btn-fill btn-info btn-sm"
									table="table-to-xls1"
									filename={'Obrisani klijenti'}
									sheet="sheet 1"
									buttonText="Izvoz u .xls"
								/>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<Accordion>
						<Accordion.Item eventKey="2">
							<Accordion.Header>
								<Container>
									<Row style={{ justifyContent: 'space-between' }}>
										<Col md={4}>
											Izbrisani klijenti pretraga po vremenu
										</Col>
										<Col md={2} />
									</Row>
								</Container>
							</Accordion.Header>
							<Accordion.Body>
								<Row>
									<Col md={3}>
										<input
											className="form-control form-control-lg col-md-11"
											type="text"
											placeholder="NavSifra"
											name="navCodeTime"
											style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
											onChange={this.onChange.bind(this)}
										/>
									</Col>
									<Col md={3}>
										<input
											className="form-control form-control-lg col-md-11"
											type="date"
											placeholder="Pretraga licenci..."
											name="dateFrom"
											style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
											onChange={this.onChange.bind(this)}
										/>
									</Col>
									<Col md={3}>
										<input
											className="form-control form-control-lg col-md-11"
											type="date"
											placeholder="Pretraga licenci..."
											name="dateUntil"
											style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
											onChange={this.onChange.bind(this)}
										/>
									</Col>
									<Col md={3}>
										<input
											type="submit"
											value="Pretraga"
											className="btn-fill col-md-12 pull-center btn btn-info"
											onClick={this.searchByDate.bind(this)}
										/>
									</Col>
								</Row>
								<Table striped hover id="table-to-xls2" >
									<thead>
										<tr>
											<th>Datum aktivacije</th>
											<th>Datum brisanja</th>
											<th>Ime kompanije</th>
											<th>Pib</th>
											<th>Nav sifra prodavca</th>
											<th>Serijski broj uređaja</th>
											<th>Razlog brisanja</th>
										</tr>
									</thead>
									<tbody>{deletedClientsAdminByDate}</tbody>
								</Table>
								<ReactToExcel
									font="TimesNewRoman"
									className="btn btn-fill btn-info btn-sm"
									table="table-to-xls2"
									filename={'Obrisani klijenti u odredjenom vremenskom periodu'}
									sheet="Brisanje u odredjenom periodu "
									buttonText="Izvoz u .xls"
								/>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					<Accordion>
						<Accordion.Item eventKey="3">
							<Accordion.Header>
								<Container>
									<Row style={{ justifyContent: 'space-between' }}>
										<Col md={4}>
											Izbrisani klijenti pretraga po Nav sifri
										</Col>
										<Col md={2} />
									</Row>
								</Container>
							</Accordion.Header>
							<Accordion.Body>
								<Row >
									<Col md={3}>
										<input
											className="form-control form-control-lg col-md-11"
											type="text"
											placeholder="Pretraga licenci..."
											name="navCode"
											style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
											onChange={this.onChange.bind(this)}
										/>
									</Col>
									<Col md={3}>
										<input
											type="submit"
											value="Pretraga"
											className="btn-fill col-md-12 pull-center btn btn-info"
											onClick={this.searchByNavCode.bind(this)}
										/>
									</Col>
								</Row>
								<Table striped hover id="table-to-xls3" >
									<thead>
										<tr>
											<th>Datum aktivacije</th>
											<th>Datum brisanja</th>
											<th>Ime kompanije</th>
											<th>Pib</th>
											<th>Nav sifra prodavca</th>
											<th>Serijski broj uređaja</th>
											<th>Razlog brisanja</th>
										</tr>
									</thead>
									<tbody>{deletedClientsAdminByVendor}</tbody>
								</Table>
								<ReactToExcel
									font="TimesNewRoman"
									className="btn btn-fill btn-info btn-sm"
									table="table-to-xls3"
									filename={'Obrisani klijenti za vendora'}
									sheet="Brisanje za vendora"
									buttonText="Izvoz u .xls"
								/>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
					{<DeletedClientByWho/>}
				</Container>
				
			</div>
		);
	}
}

DeletedClientsTable.propTypes = {
	deleted: PropTypes.object.isRequired,
	getDeletedClientsByFilter: PropTypes.func.isRequired,
	client: PropTypes.object.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	getDeletedClients: PropTypes.func.isRequired,
	getDeletedClientsByVendorSearch: PropTypes.func.isRequired,
	getDeletedClientsByDate: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	deleted: state.deleted,
	client: state.client,
	auth: state.auth
});

export default connect(
	mapStateToProps,
	{ getDeletedClients, getDeletedClientsByFilter, getCurrentProfile, getDeletedClientsByVendorSearch, getDeletedClientsByDate }
)(DeletedClientsTable);

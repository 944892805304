import axios from 'axios';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';

function App() {
  const [pibArray, setPibArray] = useState([]);

  const handleFileChange = async event => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });

        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(sheet);

        const pibData = json.map(item => item['PIB']);
        setPibArray(pibData);
        const dataBody = {
          vatArray: pibData.map(String)
        }
        axios.post(`http://192.168.1.97:3000/api/utility/SuspendThemUnpayers/`, dataBody)
          .then(res => {
            console.log(res)
          })
          .catch(err => {
            console.log(err)
          })
      };
      reader.readAsArrayBuffer(file);
    }

  };
  console.log(pibArray)
  return (
    <div className='col-md-2 '>
      <label htmlFor="formFileLg" className="form-label">Ucitaj excel fajl</label>
      <input className="form-control form-control-sm " id="formFileSm align-self-end" type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
    </div>


  );
}

export default App;

import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getClients, getClientByCompId, debtorPaid, sendWarningMail, clientCompensation, clientProtected, getClientsByNavCode } from '../../actions/clientActions';
import Card from '../Card/Card';
import axios from 'axios';
import Swal from 'sweetalert2';
import { getCurrentProfile } from '../../actions/profileAction';
import Spinner from '../common/Spinner';

class TableWarning extends Component {
  handleFileUpload = event => {
    console.log(event.target.files[0].name);
  };
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      page: 1,
      perPage: 25,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    if (this.props.auth.user.position === 'Admin') {
      this.props.getClients('1', '25');
    }
    else {
      this.props.getClientsByNavCode(this.props.auth.user.NavCode);
    }
  }

  OnChangePerPage = (e) => {
    const perPage = e.target.value;
    this.setState({ perPage }, () => {
      if (this.props.auth.user.position === 'Admin') {
        this.props.getClients(this.state.page, this.state.perPage);
      }
      else {
        this.props.getClientsByNavCode(this.props.auth.user.NavCode, this.state.page, this.state.perPage)
      }

    });
  }

  NextPage = () => {
    if (this.state.page >= 1) {
      this.setState({ page: this.state.page + 1 },
        () => {
          if (this.props.auth.user.position === 'Admin') {
            this.props.getClients(this.state.page, this.state.perPage);
          }
          else {
            this.props.getClientsByNavCode(this.props.auth.user.NavCode, this.state.page);
          }
        }
      );
    }
  }

  PreviousPage = () => {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 },
        () => {
          if (this.props.auth.user.position === 'Admin') {
            this.props.getClients(this.state.page, this.state.perPage);
          }
          else {
            this.props.getClientsByNavCode(this.props.auth.user.NavCode, this.state.page);
          }
        }
      );
    }
  }

  onChange(e) {
    this.setState({ file: e.target.files[0] });
  }
  onSubmit(e) {
    e.preventDefault()
    const formData = new FormData()
    formData.append(
      "file",
      this.state.file,
      this.state.file.name
    );
    //Preskocen je clientActions salje se direkt ka funkciji
    console.log(this.state.selectedFile);
    axios.post(`/api/utility/insertSuspend/:file`, formData).then(res => {
      alert('Tabela je uspesno uvezena!');
      window.location.reload()
    });
  }

  onSendWarningClick(userEmail, iznos, faktura) {
    Swal.fire({
      title: 'Slanje opomene?',
      text: 'Da li ste sigurni da želite  da pošaljete opomenu izabranom korisniku?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Da'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.sendWarningMail(userEmail, iznos, faktura))
      }
    })
  }

  onSetCompensation(_id) {
    Swal.fire({
      title: 'Kompenzacija?',
      text: 'Da li ste sigurni da imamo kompenzaciju sa korisnikom?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Da'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.clientCompensation(_id, this.props.auth.user.name))
      }
    })
  }
  onSetClientProtected(_id) {
    Swal.fire({
      title: 'Zasticen?',
      text: 'Da li ste sigurni da zelite da zastite korisnika od iskljucenja?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Da'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.clientProtected(_id, this.props.auth.user.name))
      }
    })
  }
  onDeleteClick(_id) {
    Swal.fire({
      title: 'Korisnik je platio?',
      text: 'Da li ste sigurni da je korisnik izmirio dugovanje?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Ne',
      confirmButtonText: 'Da'
    }).then((result) => {
      if (result.isConfirmed) {
        (this.props.debtorPaid(_id, this.props.auth.user.name))
      }
    })
  }


  render() {
    const { loading } = this.props.client;
    const debtors = this.props.client.debtors;
    let debtorItems;
    if (debtors === null || loading) {
      debtorItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );

    } else {
      if (debtors.length > 0) {
        debtorItems = debtors.map(client => (
          <tr key={client._id}>
            <td>{client.NavCode}</td>
            <td>{client.imeKompanije}</td>
            <td>{client.faktura}</td>
            <td>{client.iznos}</td>
            <td>{client.compVatId}</td>
            <td>{client.userEmail}</td>
            <td>{client.Komentar}</td>
            <td>
              <button
                type="button"
                className="btn-fill btn btn-sm btn-success"
                onClick={this.onDeleteClick.bind(this, client._id)}>
                Uplatio
              </button>
            </td>
            <td>
              {(() => {
                switch (client.mailCounter) {
                  case '1':
                    return (
                      <button
                        disabled
                        type="button"
                        className="btn-fill btn btn-sm btn-outline-warning"
                        style={{ width: '100%' }}
                        onClick={this.onSendWarningClick.bind(this, client.userEmail, client.iznos, client.faktura)}
                      >
                        Opomena poslata
                      </button>
                    );
                  default:
                    return (
                      <button
                        type="button"
                        className="btn-fill btn btn-sm btn-warning"
                        onClick={this.onSendWarningClick.bind(this, client.userEmail, client.iznos, client.faktura)}
                        style={{ width: '100%' }}
                      >
                        Pošalji opomenu
                      </button>
                    );
                }
              })()}
            </td>
            <td>
              <button
                type="button"
                className="btn-fill btn btn-sm btn-info"
                onClick={this.onSetCompensation.bind(this, client._id)}
              >
                Kompenzacija
              </button>
            </td>
            <td>
              <button
                type="button"
                className="btn-fill btn btn-sm btn-info"
                onClick={this.onSetClientProtected.bind(this, client._id)}
              >
                Zasticen
              </button>
            </td>
          </tr>
        ));
      }
      else {
        debtorItems = (
          <tr>
            <td>Nema stavki za prikaz</td>
          </tr>
        );
      }
    }

    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={4}>
              <Card
                title="Uvoz"
                category="Uvoz liste duznika"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <div className="container">
                    <div className="row">
                      <form onSubmit={this.onSubmit} className='justify-content-center'>
                        <input disabled={this.props.auth.user.position !== 'Admin'} type="file" onChange={this.onChange} name="file" accept=".xls,.xlsx" />
                        <button disabled={this.props.auth.user.position !== 'Admin'} className="btn btn-primary btn-fill" type="submit">Unesi</button>
                      </form>
                    </div>
                  </div>
                } />
            </Col>
            <Col md={12}>
              <Card
                title="Licence"
                category="Informacije o licencama"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Nav sifra prodavca</th>
                        <th>Ime kompanije</th>
                        <th>Broj fakture</th>
                        <th>Iznos duga</th>
                        <th>PIB (VatId)</th>
                        <th>Mail korinsika</th>
                        <th>Komentar</th>
                        <th />
                        <th />
                        <th>Akcije</th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>{debtorItems}</tbody>
                  </Table>
                }
              />
              <div className="pull-right" style={{ marginRight: '5px' }}>
                <select
                  name="perPage"
                  onChange={this.OnChangePerPage}
                  value={this.state.perPage}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                </select>
                {this.state.page > 1
                  ? <button onClick={this.PreviousPage}>{'<-'}</button>
                  : <button disabled>{'<-'}</button>

                }
                <button disabled style={{ border: 'solid', borderColor: '#000000', borderWidth: 'thin', color: '#000000' }}>{this.state.page}</button>
                {debtors.length < this.state.perPage
                  ? <button disabled>{'->'}</button>
                  : <button onClick={this.NextPage} >{'->'}</button>
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

TableWarning.propTypes = {
  getClientsByNavCode: PropTypes.func.isRequired,
  debtorPaid: PropTypes.func.isRequired,
  clientCompensation: PropTypes.func.isRequired,
  clientProtected: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  sendWarningMail: PropTypes.func.isRequired,
  getClientByCompId: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  client: state.client,
  profile: state.profile,
  debtor: state.debtor,
  auth: state.auth,
  debtors: state.debtors
});

export default connect(
  mapStateToProps,
  { getClients, getClientByCompId, debtorPaid, sendWarningMail, clientCompensation, clientProtected, getCurrentProfile, getClientsByNavCode }
)(TableWarning);

import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getCurrentProfile } from '../../actions/profileAction';
import { callIsSolved, getPersonalLogs, getLogsByDates, getUnseenLogs } from '../../actions/clientActions';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Card from '../Card/Card';
import { Accordion } from 'react-bootstrap';
import ReactToExcel from 'react-html-table-to-excel';
import Swal from 'sweetalert2';
import axios from 'axios';
import LogsByAgent from './LogsByAgent';
class HistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      dateFrom: '',
      dateUntil: '',
      whoseLogs: '',
      byStatus: '',
    }
    this.onChange = this.onChange.bind(this);
  }

  OnClickSolved(id) {
    this.props.callIsSolved(id)
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.whoseLogs === '') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Nije odabrana vrsta logova',
        showConfirmButton: false,
        timer: 3000
      })
    } else if (this.state.dateFrom === '' || this.state.dateUntil === '') {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Datumi nisu izabrani',
        showConfirmButton: false,
        timer: 3000
      })
    } else {
      const dates = {
        dateFrom: this.state.dateFrom,
        dateUntil: this.state.dateUntil,
        whoseLogs: this.state.whoseLogs,
        byStatus: this.state.byStatus,
      }
      this.props.getLogsByDates(dates)
    }

  }
  async ChangeLog(id, log) {
    const { value: comment } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Komentar',
      inputPlaceholder: log,
      inputValue: log,
      inputAttributes: {
        'aria-label': 'Unesite komentar'
      },
      showCancelButton: true,
      customClass: {
        cancelButton: 'Otkaži',
        confirmButton: 'Unesi'
      }
    })
    const payload = {
      comment: comment,
      id: id
    }
    await axios.post(`/api/utility/changeComment/`, payload)
      .then(res => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Log promenjen',
          showConfirmButton: false,
          timer: 3000
        })
      })
      .catch(err => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Greška',
          showConfirmButton: false,
          timer: 3000
        })
      })
  }

  async addComment(id) {
    const { value: comment } = await Swal.fire({
      input: 'textarea',
      inputLabel: 'Komentar',
      inputPlaceholder: 'Unesite komentar',
      inputAttributes: {
        'aria-label': 'Unesite komentar'
      },
      showCancelButton: true,
      customClass: {
        cancelButton: 'Otkaži',
        confirmButton: 'Unesi'
      }
    })
    if (comment) {
      const payload = {
        comment: comment,
        id: id
      }
      await axios.post(`/api/utility/addHistoryLogComment/`, payload)
        .then(res => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Komentar unet',
            showConfirmButton: false,
            timer: 3000
          })
        })
        .catch(err => {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Greška',
            showConfirmButton: false,
            timer: 3000
          })
        })
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Komentar nije unet',
        showConfirmButton: false,
        timer: 3000
      })
    }
  }

  setLogsAsSeen() {
    if (this.props.client.allUnseenLogs.length > 0) {
      axios.post(`/api/utility/setCommentsAsSeen/`)
        .then(response => {
          console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    const agent = this.props.auth.user.NavCode;
    this.props.getPersonalLogs(agent);
    this.props.getUnseenLogs();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { loading } = this.props.client;
    const { logsByDate } = this.props.client;
    const { allUnseenLogs } = this.props.client;
    const personalLogsByAgent = this.props.client.personalLogsByAgent.filter(personalLogsByAgent => {
      return (
        personalLogsByAgent.compVatId.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      )
    })

    let callHistoryItems;
    let callHistoryItemsByDate;
    let callHistoryNewLogs;
    if (allUnseenLogs === null || loading) {
      callHistoryNewLogs = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      )
    } else {
      if (allUnseenLogs.length > 0) {
        callHistoryNewLogs = allUnseenLogs.map(unseenCalls => (
          <tr key={unseenCalls._id}>
            <td>{unseenCalls.dateOfCall.replace('T', ' ').replace('Z', '').replace(':00.000', '')}</td>
            <td>{unseenCalls.compName}, {unseenCalls.compVatId}</td>
            <td style={{ wordBreak: 'break-word' }}>{unseenCalls.comment}</td>
            <td>{unseenCalls.chiefResponse}</td>
            <td>{unseenCalls.dateOfStatusChange}</td>
            <td>{(() => {
              switch (unseenCalls.problemType) {
                case '1':
                  return (
                    <span>
                      Uspavana licenca
                    </span>
                  );
                case '2':
                  return (
                    <span>
                      Avansni račun
                    </span>
                  );
                case '3':
                  return (
                    <span>
                      PDV grupe
                    </span>
                  );
                case '4':
                  return (
                    <span>
                      Izveštaji
                    </span>
                  );
                case '5':
                  return (
                    <span>
                      Kartično pl
                    </span>
                  );
                case '6':
                  return (
                    <span>
                      E-fakture
                    </span>
                  );
                case '7':
                  return (
                    <span>
                      Ostalo
                    </span>
                  );
                case '8':
                  return (
                    <span>
                      Obrada
                    </span>
                  );
                case '9':
                  return (
                    <span>
                      Storniranje
                    </span>
                  );
                case '10':
                  return (
                    <span>
                      Van mreže
                    </span>
                  );
                case '11':
                  return (
                    <span>
                      Neaktivna licenca
                    </span>
                  );
                case '12':
                  return (
                    <span>
                      Boot mode
                    </span>
                  );
                case '13':
                  return (
                    <span>
                      Klijent
                    </span>
                  );
                case '14':
                  return (
                    <span>
                      Neprispeli računi
                    </span>
                  );
                case '15':
                  return (
                    <span>
                      BE
                    </span>
                  );
                case '16':
                  return (
                    <span>
                      Brisanje memorijskog prostora
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td>{unseenCalls.deviceSerialNo}</td>
            <td>{unseenCalls.callAnsweredBy}</td>
          </tr>
        ))
      } else {

      }
    }
    if (logsByDate === null || loading) {
      callHistoryItemsByDate = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      )
    } else {
      if (logsByDate.length > 0) {
        callHistoryItemsByDate = logsByDate.map(historyBD => (
          <tr key={historyBD._id}>
            <td>{historyBD.dateOfCall.replace('T', ' ').replace('Z', '').replace(':00.000', '')}</td>
            <td>{historyBD.compName}, {historyBD.compVatId}</td>
            <td style={{ wordBreak: 'break-word' }}>{historyBD.comment}</td>
            <td>{historyBD.chiefResponse}</td>
            <td>{historyBD.dateOfStatusChange}</td>
            <td>{(() => {
              switch (historyBD.problemType) {
                case '1':
                  return (
                    <span>
                      Uspavana licenca
                    </span>
                  );
                case '2':
                  return (
                    <span>
                      Avansni račun
                    </span>
                  );
                case '3':
                  return (
                    <span>
                      PDV grupe
                    </span>
                  );
                case '4':
                  return (
                    <span>
                      Izveštaji
                    </span>
                  );
                case '5':
                  return (
                    <span>
                      Kartično pl
                    </span>
                  );
                case '6':
                  return (
                    <span>
                      E-fakture
                    </span>
                  );
                case '7':
                  return (
                    <span>
                      Ostalo
                    </span>
                  );
                case '8':
                  return (
                    <span>
                      Obrada
                    </span>
                  );
                case '9':
                  return (
                    <span>
                      Storniranje
                    </span>
                  );
                case '10':
                  return (
                    <span>
                      Van mreže
                    </span>
                  );
                case '11':
                  return (
                    <span>
                      Neaktivna licenca
                    </span>
                  );
                case '12':
                  return (
                    <span>
                      Boot mode
                    </span>
                  );
                case '13':
                  return (
                    <span>
                      Klijent
                    </span>
                  );
                case '14':
                  return (
                    <span>
                      Neprispeli računi
                    </span>
                  );
                case '15':
                  return (
                    <span>
                      BE
                    </span>
                  );
                case '16':
                  return (
                    <span>
                      Brisanje memorijskog prostora
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td>{historyBD.deviceSerialNo}</td>
            <td>{historyBD.callAnsweredBy}</td>
            <td>{historyBD.logStatus === 'Resen' ? '' :
              (
                <button
                  type="button"
                  className="btn-fill btn btn-sm btn-danger"
                  onClick={this.OnClickSolved.bind(this, historyBD._id)}
                >
                  Resen
                </button>
              )}
            </td>
            <td>
              {this.props.auth.user.NavCode === 'SanjaM' ? <button
                type='button'
                className='btn-fill btn btn-sm btn-danger'
                onClick={this.addComment.bind(this, historyBD._id)}
              >
                Dodaj komentar
              </button> : <button
                type='button'
                className='btn-fill btn btn-sm btn-danger'
                onClick={this.ChangeLog.bind(this, historyBD._id, historyBD.comment)}
              >
                Izmeni unos
              </button>}
            </td>
          </tr>
        ))
      } else {

      }
    }
    if (personalLogsByAgent === null || loading) {
      callHistoryItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );
    } else {
      if (personalLogsByAgent.length > 0) {
        callHistoryItems = personalLogsByAgent.map(history => (
          <tr key={history._id}>
            <td>{history.dateOfCall.replace('T', ' ').replace('Z', '').replace(':00.000', '')}</td>
            <td>{history.compName}, {history.compVatId}</td>
            <td style={{ wordBreak: 'break-word' }}>{history.comment}</td>
            <td>{history.chiefResponse}</td>
            <td>{history.dateOfStatusChange}</td>
            <td>{(() => {
              switch (history.problemType) {
                case '1':
                  return (
                    <span>
                      Uspavana licenca
                    </span>
                  );
                case '2':
                  return (
                    <span>
                      Avansni račun
                    </span>
                  );
                case '3':
                  return (
                    <span>
                      PDV grupe
                    </span>
                  );
                case '4':
                  return (
                    <span>
                      Izveštaji
                    </span>
                  );
                case '5':
                  return (
                    <span>
                      Kartično pl
                    </span>
                  );
                case '6':
                  return (
                    <span>
                      E-fakture
                    </span>
                  );
                case '7':
                  return (
                    <span>
                      Ostalo
                    </span>
                  );
                case '8':
                  return (
                    <span>
                      Obrada
                    </span>
                  );
                case '9':
                  return (
                    <span>
                      Storniranje
                    </span>
                  );
                case '10':
                  return (
                    <span>
                      Van mreže
                    </span>
                  );
                case '11':
                  return (
                    <span>
                      Neaktivna licenca
                    </span>
                  );
                case '12':
                  return (
                    <span>
                      Boot mode
                    </span>
                  );
                case '13':
                  return (
                    <span>
                      Klijent
                    </span>
                  );
                case '14':
                  return (
                    <span>
                      Neprispeli računi
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td>{history.deviceSerialNo}</td>
            <td>{history.logStatus === 'Resen' ? '' :
              (
                <button
                  type="button"
                  className="btn-fill btn btn-sm btn-danger"
                  onClick={this.OnClickSolved.bind(this, history._id)}
                >
                  Resen
                </button>
              )}
            </td>
            <td>
              {this.props.auth.user.NavCode === 'SanjaM' ? <button
                type='button'
                className='btn-fill btn btn-sm btn-danger'
                onClick={this.addComment.bind(this, history._id)}
              >
                Dodaj komentar
              </button> : <button
                type='button'
                className='btn-fill btn btn-sm btn-danger'
                onClick={this.ChangeLog.bind(this, history._id, history.comment)}
              >
                Izmeni unos
              </button>}
            </td>
          </tr>

        ));
      }
      else {
        callHistoryItems = (
          <tr>
            <td>Nema stavki za prikaz...</td>
          </tr>
        );
      }
    }

    return (
      <div className='content'>
        <Container fluid>
          <Accordion>
            <Accordion.Item eventKey='0'>
              <Accordion.Header onClick={this.setLogsAsSeen.bind(this)}>
                <Container>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col md={2}>
                      Logovi sa odgovorom
                    </Col>
                    <Col md={2}>
                      <button
                        className='btn-fill btn btn-sm btn-danger'
                      >
                        Novi logovi: {allUnseenLogs.length}
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Accordion.Header>
              <Accordion.Body>
                <Col md={12}>
                  <Card
                    title="Logovi sa odgovorom"
                    category=""
                    ctTableFullWidth
                    ctTableResponsive
                    content={
                      <Table striped hover>
                        <thead>
                          <tr>
                            <th>Vreme poziva</th>
                            <th>Pib</th>
                            <th>Komentar</th>
                            <th>Odgovor</th>
                            <th>Datum statusa</th>
                            <th>Razlog poziva</th>
                            <th>Serijski broj</th>
                          </tr>
                        </thead>
                        <tbody>{callHistoryNewLogs}</tbody>
                      </Table>
                    }
                  />
                </Col>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <Container>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col md={2}>
                      Moji logovi
                    </Col>
                    <Col md={2}>
                      <button
                        className='btn-fill btn btn-sm btn-danger'
                      >
                        Logovi u toku: {personalLogsByAgent.length}
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <div className="clearfix"> </div>
                  <Col md={6}>
                    <input
                      className="form-control form-control-lg col-md-11"
                      type="text"
                      placeholder="Pretraga suspendovanih uređaja"
                      name="search"
                      style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
                      onChange={this.onChange.bind(this)}
                    />
                  </Col>
                  <Col md={12}>
                    <Card
                      title="Istorija poziva"
                      category="informacije o istoriji poziva"
                      ctTableFullWidth
                      ctTableResponsive
                      content={
                        <Table striped hover>
                          <thead>
                            <tr>
                              <th>Vreme poziva</th>
                              <th>Pib</th>
                              <th>Komentar</th>
                              <th>Odgovor</th>
                              <th>Datum statusa</th>
                              <th>Razlog poziva</th>
                              <th>Serijski broj</th>
                            </tr>
                          </thead>
                          <tbody>{callHistoryItems}</tbody>
                        </Table>
                      }
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>
                <Container>
                  <Row style={{ justifyContent: 'space-between' }}>
                    <Col md={2}>
                      Pretraga po datumima
                    </Col>
                    <Col md={2} />
                  </Row>
                </Container>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col md={2}>
                    <input
                      className="form-control form-control-lg col-md-11"
                      type="date"
                      name="dateFrom"
                      style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
                      onChange={this.onChange.bind(this)}
                    />
                  </Col>
                  <Col md={2}>
                    <input
                      className="form-control form-control-lg col-md-11"
                      type="date"
                      name="dateUntil"
                      style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
                      onChange={this.onChange.bind(this)}
                    />
                  </Col>
                  <Col md={1}>
                    <Row>
                      <label>
                        <input
                          onChange={this.onChange.bind(this)}
                          type='radio'
                          name='whoseLogs'
                          value='Mine'
                        /> Moji logovi
                      </label>
                    </Row>
                    <Row>
                      <label>
                        <input
                          onChange={this.onChange.bind(this)}
                          type='radio'
                          name='whoseLogs'
                          value='All'
                        /> Svi logovi
                      </label>
                    </Row>
                  </Col>
                  <Col md={1}>
                    <Row>
                      <label>
                        <input
                          onChange={this.onChange.bind(this)}
                          type='radio'
                          name='byStatus'
                          value='Resen'
                        /> Reseni
                      </label>
                    </Row>
                    <Row>
                      <label>
                        <input
                          onChange={this.onChange.bind(this)}
                          type='radio'
                          name='byStatus'
                          value='U toku'
                        /> U toku
                      </label>
                    </Row>
                  </Col>
                  <Col md={2}>
                    <button
                      onClick={this.onSubmit.bind(this)}
                      className="btn-fill col-md-12 pull-center btn btn-info"
                    >
                      Pretraga
                    </button>
                  </Col>
                  <Col md={2}>
                    <ReactToExcel
                      font="TimesNewRoman"
                      className="btn-fill col-md-8 pull-center btn btn-info"
                      table="table-to-xls1"
                      filename={'Logovi'}
                      sheet="sheet 1"
                      buttonText="Izvoz u .xls"
                    />
                  </Col>
                  <Col md={12}>
                    <Card
                      title="Istorija poziva"
                      category="informacije o istoriji poziva"
                      ctTableFullWidth
                      ctTableResponsive
                      content={
                        <Table striped hover id="table-to-xls1">
                          <thead>
                            <tr>
                              <th>Vreme poziva</th>
                              <th>Pib</th>
                              <th>Komentar</th>
                              <th>Odgovor</th>
                              <th>Datum statusa</th>
                              <th>Razlog poziva</th>
                              <th>Serijski broj</th>
                              <th>Agent</th>
                              <th />
                              <th />
                            </tr>
                          </thead>
                          <tbody>{callHistoryItemsByDate}</tbody>
                        </Table>
                      }
                    />
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <LogsByAgent/>
        </Container>
      </div>
    );
  }
}

HistoryTable.propTypes = {
  callIsSolved: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  getLogsByDates: PropTypes.func.isRequired,
  getPersonalLogs: PropTypes.func.isRequired,
  getUnseenLogs: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  client: state.client,
  auth: state.auth,
  profiles: state.profile,
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, callIsSolved, getPersonalLogs, getLogsByDates, getUnseenLogs }
)(HistoryTable);
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILES_LOADING = 'PROFILES_LOADING';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const ADD_CLIENT = 'ADD_CLIENT';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_DELETED_CLIENTS = 'GET_DELETED_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const CLIENTS_LOADING = 'CLIENTS_LOADING';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_DEVICES = 'GET_DEVICES';
export const GET_DELETED_DEVICES = 'GET_DELETED_DEVICES';
export const DEVICES_LOADING = 'DEVICES_LOADING';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const GET_DEVICE = 'GET_DEVICE';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_DELETED_SERVICES = 'GET_DELETED_SERVICES';
export const SERVICES_LOADING = 'SERVICES_LOADING';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_TICKETS = 'GET_TICKETS';
export const GET_TICKET = 'GET_TICKET';
export const TICKETS_LOADING = 'TICKETS_LOADING';
export const DELETE_TICKET = 'DELETE_TICKET';
export const GET_REQUESTS = 'GET_REQUESTS';
export const GET_REQUEST = 'GET_REQUEST';
export const REQUESTS_LOADING = 'REQUESTS_LOADING';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const GET_CLIENT_BY_COMPID = 'GET_CLIENT_BY_COMPID';
export const GET_CLIENTS_BY_VENDOR = 'GET_CLIENTS_BY_VENDOR';
export const CHANGE_LICENCE_STATUS = 'CHANGE_LICENCE_STATUS';
export const CHANGE_LICENCE = 'CHANGE_LICENCE';
export const GET_ZIPCODES = 'GET_ZIPCODES';
export const GET_ZIPCODE = 'GET_ZIPCODE';
export const ZIPCODES_LOADING = 'ZIPCODES_LOADING';
export const GET_PROMOCODES ='GET_PROMOCODES'; 
export const PROMOCODES_LOADING ='PROMOCODES_LOADING';
export const GET_PROMOCODE ='GET_PROMOCODE';
export const ADD_INVOICE = 'ADD_INVOICE';
export const GET_DEBTOR = 'GET_DEBTOR';
export const DELETE_DEBTOR = 'DELETE_DEBTOR';
export const GET_VENDOR_COUNTER = 'GET_VENDOR_COUNTER';
export const STATISTICS_LOADING = 'STATISTICS_LOADING';
export const GET_DIST_COUNTER = 'GET_DIST_COUNTER';
export const GET_SIM_DIST_COUNTER = 'GET_SIM_DIST_COUNTER';
export const GET_CLIENTS_BY_VENDOR_COUNTER = 'GET_CLIENTS_BY_VENDOR_COUNTER';
export const GET_LICENCEID_HISTORY = 'GET_LICENCEID_HISTORY';
export const GET_ALL_BLACKLIST_DEVICES = 'GET_ALL_BLACKLIST_DEVICES';
export const GET_BLACKLIST_DEVICE = 'GET_BLACKLIST_DEVICE';
export const GET_BLACKLIST_DEVICE_PER_VENDOR = 'GET_BLACKLIST_DEVICE_PER_VENDOR';
export const BLACKLIST_LOADING = 'BLACKLIST_LOADING';
export const GET_ALL_INVOICES = 'GET_ALL_INVOICES';
export const GET_ALL_INVOICES_PAGE = 'GET_ALL_INVOICES_PAGE';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const REMOVED_CLIENTS_LOADING = 'REMOVED_CLIENTS_LOADING';
export const GET_NUMBER_OF_E_INVOICES = 'GET_NUMBER_OF_E_INVOICES';
export const GET_DELETED_CLIENTS_BY_DATE = 'GET_DELETED_CLIENTS_BY_DATE';
export const GET_DELETED_CLIENTS_BY_VENDOR = 'GET_DELETED_CLIENTS_BY_VENDOR';
export const GET_ALL_INVOICES_VENDOR = 'GET_ALL_INVOICES_VENDOR';
export const GET_CLIENTS_CALL_HISTORY = 'GET_CLIENTS_CALL_HISTORY';
export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID'; 
export const GET_ALL_PERSONAL_LOGS = 'GET_ALL_PERSONAL_LOGS';
export const GET_ALL_LOGS_BY_DATE = 'GET_ALL_LOGS_BY_DATE';
export const GET_ALL_UNSEEN_LOGS = 'GET_ALL_UNSEEN_LOGS';
import React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import AdminMenu from './AdminMenu'

const Product = () => {
  const [products, setProducts] = useState([]);

  //uzima sve proizvode
  const getAllProducts = async () => {
    try {
      const { data } = await axios.get("/backendeshop/api/v1/product/get-product");
      setProducts(data.products);
      // console.log(data.products)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);
  return (
    <div className='content'>
      <div className="container-fluid m-3 p-3 dashboard">
        <div className="row dashboard">
          <div className="col-md-12 ">
            <h1 className="text-center">Lista svih proizvoda</h1>
            <div className="d-flex flex-wrap">
              {products?.map((p) => (
                <div className="card m-2 bg-light shadow rounded" style={{ width: "18rem" }}>
                  <div className="card-body">
                    <h3 className="card-title text-dark mb-0">{p.name}</h3>
                    <p className="card-text text-dark mb-3">{p.description}</p>
                    <div className="d-flex">
                      <Link to={`/galebshop/products/update-product/${p.slug}`} className="btn-fill  pull-center btn btn-primary">Izmeni</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product

import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Spinner from '../common/Spinner';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getClientById, createClient } from '../../actions/clientActions';
import isEmpty from '../../validation/is-empty';
import TextFieldGroup from '../common/TextFieldGroup';
import { Card } from '../../components/Card/Card';

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compName: '',
      compId: '',
      compVatId: '',
      compPhone: '',
      userFirstName: '',
      userLastName: '',
      userPhone: '',
      deviceVendor: '',
      deviceSerialNo: '',
      errors: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newClient = {
      compName: this.state.compName,
      compId: this.state.compId,
      compPhone: this.state.compPhone,
      compVatId: this.state.compVatId,
      userFirstName: this.state.userFirstName,
      userLastName: this.state.userLastName,
      userPhone: this.state.userPhone,
      deviceSerialNo: this.state.deviceSerialNo
    };

    this.props.createClient(newClient, this.props.history);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.client.client) {
      const client = nextProps.client.client;

      client.compPhone = !isEmpty(client.compPhone) ? client.compPhone : '';
      client.compId = !isEmpty(client.compId) ? client.compId : '';
      client.compName = !isEmpty(client.compName) ? client.compName : '';
      client.compVatId = !isEmpty(client.compVatId) ? client.compVatId : '';
      client.userFirstName = !isEmpty(client.userFirstName) ? client.userFirstName : '';
      client.userLastName = !isEmpty(client.userLastName) ? client.userLastName : '';
      client.deviceSerialNo = !isEmpty(client.deviceSerialNo) ? client.deviceSerialNo : '';
      client.userPhone = !isEmpty(client.userPhone) ? client.userPhone : '';

      // Set component fields state
      this.setState({
        compName: client.compName,
        compId: client.compId,
        compPhone: client.compPhone,
        compVatId: client.compVatId,
        userFirstName: client.userFirstName,
        userLastName: client.userLastName,
        userPhone: client.userPhone,
        deviceSerialNo: client.deviceSerialNo
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    if (this.props.match.params._id) {
      this.props.getClientById(this.props.match.params._id);
    }
  }

  render() {
    const { errors } = this.state;
    const { client, loading } = this.props.client;
    let clientContent;
    if (client === null || loading) {
      clientContent = <Spinner />;
    } else {
      clientContent = (
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title={this.state.name}
                category="Podaci klijenta"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Ime korisnika licence"
                          placeholder="Ime korisnika licence"
                          name="compName"
                          value={this.state.compName}
                          onChange={this.onChange}
                          error={errors.compName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Pib (compVatId)"
                          placeholder="Pib (compVatId)"
                          name="compVatId"
                          value={this.state.compVatId}
                          onChange={this.onChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Mb (compId)"
                          placeholder="Mb (compId)"
                          name="compId"
                          value={this.state.compId}
                          error={errors.compId}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Telefon kompanije"
                          placeholder="Telefon kompanije"
                          name="compPhone"
                          value={this.state.compPhone}
                          onChange={this.onChange}
                          error={errors.compPhone}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Ime kontakt osobe"
                          placeholder="Ime kontakt osobe"
                          name="userFirstName"
                          value={this.state.userFirstName}
                          onChange={this.onChange}
                          error={errors.userFirstName}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Prezime kontakt osobe"
                          placeholder="Prezime kontakt osobe "
                          name="userLastName"
                          value={this.state.userLastName}
                          onChange={this.onChange}
                          error={errors.userLastName}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon kontakt osobe"
                          placeholder="Telefon kontakt osobe"
                          name="userPhone"
                          value={this.state.userPhone}
                          onChange={this.onChange}
                          error={errors.userPhone}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Serijski broj uređaja"
                          placeholder="Serijski broj uređaja"
                          name="deviceSerialNo"
                          value={this.state.deviceSerialNo}
                          onChange={this.onChange}
                          error={errors.deviceSerialNo}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Link
                          to="/clients"
                          className="btn-fill col-md-12 pull-center btn btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Klijenti
                        </Link>
                      </div>
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                    </div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      );
    }
    return <div className="content">{clientContent}</div>;
  }
}

Client.propTypes = {
  getClientById: PropTypes.func.isRequired,
  createClient: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getClientById, createClient }
)(Client);

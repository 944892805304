import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getAllInvoices, getNumberOfEInvoices, getAllInvoicesPage } from '../../actions/invoiceActions';
import Card from '../../components/Card/Card';
import TextFieldGroup from '../common/TextFieldGroup';
import { getCurrentProfile } from '../../actions/profileAction';
import { StatsCard } from '../StatsCard/StatsCard';
import CountUp from 'react-countup';
import axios from 'axios';

function convertToDate(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000).toLocaleDateString('en-GB');
};

class ClientsEInvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compVatId: '',
      NavCode: '',
      page: 1,
      perPage: 15,
      clients: []
    };
    this.onKeyUpCheck = this.onKeyUpCheck.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  test() {
    var fileDownload = require('js-file-download');
    axios.get('/api/index/excelTest', { responseType: 'blob' })
      .then((res) => {
        var currentDate = new Date();
        var date = new Intl.DateTimeFormat('en-GB').format(currentDate);
        var filename = "Spisak E-fakture licenci " + date + ".xlsx";
        fileDownload(res.data, filename);
      })
      .catch((error) => {
        alert(error);
      })
  }

  onKeyUpCheck = e => {
    e.preventDefault();
    if (e.key === 'Enter') {
      if (this.state.compVatId.length > 2) { this.props.getAllInvoices(this.state.compVatId.trim(), 'compVatId') }
      else if (this.state.NavCode.length > 3) { this.props.getAllInvoices(this.state.NavCode.trim(), 'commercialistName') }
    }
  }
  updateSearchClick = () => {
    if (this.state.compVatId.length > 2) { this.props.getAllInvoices(this.state.compVatId.trim(), 'compVatId') }
    else if (this.state.NavCode.length > 3) { this.props.getAllInvoices(this.state.NavCode.trim(), 'commercialistName') }
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getNumberOfEInvoices();
    this.props.getAllInvoicesPage('1', this.state.perPage);
  }

  OnChangePerPage = (e) => {
    const perPage = e.target.value;
    this.setState({ perPage }, () => {
      this.props.getAllInvoicesPage(this.state.page, this.state.perPage)
    });
  }

  NextPage = () => {
    if (this.state.page >= 1) {
      this.setState({
        page: this.state.page + 1
      }
        , () => {
          this.props.getAllInvoicesPage(this.state.page, this.state.perPage)
        });
    }
  }

  PreviousPage = () => {
    if (this.state.page > 1) {
      this.setState({
        page: this.state.page - 1
      }
        , () => {
          this.props.getAllInvoicesPage(this.state.page, this.state.perPage)
        });
    }
  }
  render() {
    const { loading } = this.props.invoice;
    const clients = this.props.invoice.getAllInvoicesPage;
    const clientsSearch = this.props.invoice.getAllInvoices;
    const numOfEInv = this.props.invoice.getNumberOfEInvoices;
    var number = 0;
    if (numOfEInv < 1) { }
    else {
      number = numOfEInv;
    }
    let EInvoiceItems;
    if (clients === null || loading) {
      EInvoiceItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );
    }

    else {
      if (clients.length > 0) {
        EInvoiceItems = clients.map(client => (
          <tr key={client._id}>
            <td>{convertToDate(client._id)}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
            <td>{client.compVatId}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.loginMail}</td>
            <td>{client.userPhone}</td>
            <td>{client.userFirstName}   {client.userLastName}</td>
            <td>{(() => {
              switch (true) {
                case client.isVatExcluded != null:
                  return (
                    <span>
                      Ne
                    </span>
                  );
                case client.isVatExcluded == null:
                  return (
                    <span>
                      Da
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td>
              <div className="col-md-6">
                <Link
                  to={`/EInvoices/ChangeInvoiceData/${client._id}`}
                  className="btn-fill btn btn-sm btn-info"
                  style={{ margin: '3px' }}
                >
                  Info
                </Link>
              </div>
            </td>
          </tr>
        ));
        if (clientsSearch.length > 0) {
          EInvoiceItems = clientsSearch.map(client => (
            <tr key={client._id}>
              <td>{convertToDate(client._id)}</td>
              <td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
              <td>{client.compVatId}</td>
              <td style={{ wordBreak: 'break-word' }}>{client.loginMail}</td>
              <td>{client.userPhone}</td>
              <td>{client.userFirstName}   {client.userLastName}</td>
              <td>{(() => {
                switch (true) {
                  case client.isVatExcluded != null:
                    return (
                      <span>
                        Ne
                      </span>
                    );
                  case client.isVatExcluded == null:
                    return (
                      <span>
                        Da
                      </span>
                    );
                  default:
                    return null;
                }
              })()}</td>
              <td>
                <div className="col-md-6">
                  <Link
                    to={`/EInvoices/ChangeInvoiceData/${client._id}`}
                    className="btn-fill btn btn-sm btn-danger"
                    style={{ margin: '3px' }}
                  >
                    Izmena
                  </Link>
                </div>
              </td>
            </tr>
          ));
        }
      }
      else {
        EInvoiceItems = (
          <tr>
            <td>Licenca sa tim podacima nije pronađena...</td>
          </tr>
        );
      }
    }
    return (
      <div className="content">
        <Container fluid>
          <Row className='justify-content-center'>
            <Col lg={3} sm={3}>
              <StatsCard
                bigIcon={<i className="pe-7s-check text-success" />}
                statsText="E-fakture"
                statsValue={
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={number}
                    duration={3}
                    useGrouping={true}
                  />}
                statsIconText="Broj E-fakture licenci"
              />
            </Col>
          </Row>
          <form onSubmit={this.onKeyUpCheck.bind(this)}>
            <Row className='justify-content-center'>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.compVatId}
                  placeholder="Pib"
                  name="compVatId"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.NavCode}
                  placeholder="NavCode"
                  name="NavCode"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
              <Col md={2}>
                <div className="row">
                  <div className="col-md-12">
                    <input
                      type="submit"
                      value="Pretrazi"
                      onClick={this.updateSearchClick.bind(this)}
                      className="btn-fill col-md-12 pull-center btn btn-info"
                    />
                  </div>
                </div>
              </Col>
              <Col md={1}>
                <input
                  type="button"
                  value="Preuzmi .xls"
                  onClick={this.test.bind(this)}
                  className="btn-fill col-md-12 pull-center btn btn-info"
                />
              </Col>
            </Row>
          </form>
          <Row>
            <Col md={12}>
              {
                clientsSearch.length === 0 ?
                  <Row>
                    <Col>
                      <div className="pull-right" style={{ marginRight: '5px' }}>
                        <select
                          name="perPage"
                          onChange={this.OnChangePerPage}
                          value={this.state.perPage}
                        >
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        {this.state.page > 1
                          ? <button onClick={this.PreviousPage}>{'<-'}</button>
                          : <button disabled>{'<-'}</button>

                        }
                        <button disabled style={{ border: 'solid', borderColor: '#000000', borderWidth: 'thin', color: '#000000' }}>{this.state.page}</button>
                        {clients.length < this.state.perPage
                          ? <button disabled>{'->'}</button>
                          : <button onClick={this.NextPage} >{'->'}</button>
                        }
                      </div>
                    </Col>
                  </Row>
                  : <div> </div>
              }
              <Card
                title="Licence"
                category="Informacije o licencama za E-fakture"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover id="table-to-xls" >
                    <thead>
                      <tr>
                        <th>Datum aktivacije</th>
                        <th>Ime kompanije</th>
                        <th>PIB (VatId)</th>
                        <th>Login mail</th>
                        <th>Telefon kontakt osobe</th>
                        <th>Ime i prezime</th>
                        <th>U sistemu PDV-a</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{EInvoiceItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ClientsEInvoiceTable.propTypes = {
  getAllInvoices: PropTypes.func.isRequired,
  getNumberOfEInvoices: PropTypes.func.isRequired,
  getAllInvoicesPage: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  invoice: state.invoice,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, getAllInvoices, getNumberOfEInvoices, getAllInvoicesPage }
)(ClientsEInvoiceTable);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { Container, Row, Col } from 'react-bootstrap';
import Card from '../Card/Card';

class LoginTelekom extends Component {
  constructor() {
    super();
    this.state = {
      email: 'telekom.srbija.pisarnica@telekom.rs',
      password: '',
      errors: {},
      hidden: true
    };
    this.toggleShow = this.toggleShow.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'RegOnly') {
        this.props.history.push('/licenceRegistration')
      } 
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      if (this.props.auth.user.position === 'RegOnly') {
        this.props.history.push('/licenceRegistration')
      } 
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="content">
        <Container fluid>
          <Row style={{ paddingTop: '5%' }}>
            <Col md={5} className="pull-center">
              <Card
                content={
                  <div style={{ textAlign: 'center' }}>
                    <h1 className="display-4 text-center">Login</h1>
                    <p className="lead text-center">
                      Ulogujte sa na svoj Galeb Tech Tool nalog
                    </p>
                    <form noValidate onSubmit={this.onSubmit}>
                      <TextFieldGroup
                        placeholder="Password"
                        name="password"
                        type={this.state.hidden ? 'password' : 'text'}
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password}
                      />
                      <Row>
                        <Col>
                          <input
                            type="submit"
                            style={{ width: '50%', float: 'left' }}
                            value="Login"
                            className="btn btn-info btn-fill btn-block mt-4"
                          />
                          <button
                            onClick={this.toggleShow}
                            className={"pe-7s-look btn btn-outline-info"}
                            type="button"
                            style={{ border: 'none', float: 'right' }}
                          />
                        </Col>
                      </Row>
                    </form>

                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

LoginTelekom.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(LoginTelekom);

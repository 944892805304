import React from 'react'
import AdminMenu from './AdminMenu'

const AdminEShop = () => {
  return (
    <div className='content'>
    <div className="container-fluid m-3 p-3 dashboard">
      <div className="row">
        <div className="col-md-5">
          <AdminMenu />
        </div>
       
        </div>
      </div>
      </div>
  )
}

export default AdminEShop
import {
    GET_ZIPCODES,
    GET_ZIPCODE,
    ZIPCODES_LOADING
  } from '../actions/types';
  
  const initialState = {
    zipcodes: [],
    zipcode: {},
    loading: false
  };
  
  export default function FuncState(state = initialState, action) {
    switch (action.type) {
      case ZIPCODES_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_ZIPCODES:
        return {
          ...state,
          zipcodes: action.payload,
          loading: false
        };
      case GET_ZIPCODE:
        return {
          ...state,
          zipcode: action.payload,
          loading: false
        };
      default:
        return state;
    }
  }
  
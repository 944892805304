import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getAllInvoicesVendor } from '../../actions/invoiceActions';
import Card from '../../components/Card/Card';
import TextFieldGroup from '../common/TextFieldGroup';
import { getCurrentProfile } from '../../actions/profileAction';
import { StatsCard } from '../StatsCard/StatsCard';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

class ClientsEInvoiceTableDist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compVatId: '',
      search: '',
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    this.props.getAllInvoicesVendor(this.props.auth.user.NavCode)
  }

  render() {
    const { loading } = this.props.invoice;
    const clients = this.props.invoice.getAllInvoicesVendor.filter(getAllInvoicesVendor => {
      return (
        getAllInvoicesVendor.compVatId.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
      )
    });
    const numOfEInv = this.props.invoice.getAllInvoicesVendor.length;
    var number = 0;
    if (numOfEInv < 1) { }
    else {
      number = numOfEInv;
    }
    let EInvoiceItems;
    if (clients === null || loading) {
      EInvoiceItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );
    }

    else {
      if (clients.length > 0) {
        EInvoiceItems = clients.map(client => (
          <tr key={client._id}>
            <td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
            <td>{client.compVatId}</td>
            <td style={{ wordBreak: 'break-word' }}>{client.loginMail}</td>
            <td>{client.userPhone}</td>
            <td>{client.userFirstName}   {client.userLastName}</td>
            <td>{(() => {
              switch (true) {
                case client.isVatExcluded != null:
                  return (
                    <span>
                      Ne
                    </span>
                  );
                case client.isVatExcluded == null:
                  return (
                    <span>
                      Da
                    </span>
                  );
                default:
                  return null;
              }
            })()}
            </td>
            <td>
              <div className="col-md-6">
                <Link
                  to={`/EInvoicesDist/ChangeInvoiceData/${client._id}`}
                  className="btn-fill btn btn-sm btn-danger"
                  style={{ margin: '3px' }}
                >
                  Izmena
                </Link>
              </div>
            </td>
          </tr>
        ));
      }
      else {
        EInvoiceItems = (
          <tr>
            <td>Licenca sa tim podacima nije pronađena...</td>
          </tr>
        );
      }
    }

    return (
      <div className="content">
        <Container fluid>
          <Row className='justify-content-center'>
            <Col lg={3} sm={3}>
              <StatsCard
                bigIcon={<i className="pe-7s-check text-success" />}
                statsText="E-fakture"
                statsValue={
                  <CountUp
                    className="account-balance"
                    start={0}
                    end={number}
                    duration={3}
                    useGrouping={true}
                  />}
                statsIconText="Broj E-fakture licenci"
              />
            </Col>
          </Row>
          <form>
            <Row className='justify-content-center'>
              <div className="col-md-2">
                <TextFieldGroup
                  type="text"
                  value={this.state.search}
                  placeholder="Pib"
                  name="search"
                  style={{ marginBottom: '5px' }}
                  onChange={this.onChange}
                />
              </div>
            </Row>
          </form>
          <br></br>
          <Row>
            <Col md={12}>
              <Card
                title="Licence"
                category="Informacije o licencama za E-fakture"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover id="table-to-xls" >
                    <thead>
                      <tr>
                        <th>Ime kompanije</th>
                        <th>PIB (VatId)</th>
                        <th>Login mail</th>
                        <th>Telefon kontakt osobe</th>
                        <th>Ime i prezime</th>
                        <th>U sistemu PDV-a</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{EInvoiceItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ClientsEInvoiceTableDist.propTypes = {
  getAllInvoicesVendor: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  invoice: state.invoice,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, getAllInvoicesVendor }
)(ClientsEInvoiceTableDist);
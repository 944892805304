import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Card from '../../components/Card/Card';
import TextFieldGroup from '../common/TextFieldGroup';
import axios from 'axios';

const ClientsNewLicences = () => {
  const [allClients, setAllClients] = useState([]);
  const [licenceId, setLicenceId] = useState('');
  const [compVatId, setCompVatId] = useState('');
  const [compName, setCompName] = useState('');
  const [compId, setCompId] = useState('');

  useEffect(() => {
    firstFetch();
  }, []);

  async function search() {
    if (licenceId.length > 2) { fetchSpecificData({ 'whereToLook': 'licence_id', 'whatToLook': licenceId }) }
    else if (compVatId.length > 2) { fetchSpecificData({ 'whereToLook': 'compVatId', 'whatToLook': compVatId }) }
    else if (compName.length > 2) { fetchSpecificData({ 'whereToLook': 'compName', 'whatToLook': compName }) }
    else if (compId.length > 2) { fetchSpecificData({ 'whereToLook': 'compId', 'whatToLook': compId }) }
  }

  async function onKeyUp(e) {
    if (e.key == 'Enter') {
      if (licenceId.length > 2) { fetchSpecificData({ 'whereToLook': 'licence_id', 'whatToLook': licenceId }) }
      else if (compVatId.length > 2) { fetchSpecificData({ 'whereToLook': 'compVatId', 'whatToLook': compVatId }) }
      else if (compName.length > 2) { fetchSpecificData({ 'whereToLook': 'compName', 'whatToLook': compName }) }
      else if (compId.length > 2) { fetchSpecificData({ 'whereToLook': 'compId', 'whatToLook': compId }) }
    }
  }

  async function firstFetch() {
    axios.post(`/api/NewLicences/getLicences/`, { 'isFirstCall': true })
      .then(response => {
        console.log(response)
        setAllClients(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  async function fetchSpecificData(data) {
    console.log(data)
    axios.post(`/api/NewLicences/getLicences/`, data)
      .then(response => {
        console.log(response.data)
        setAllClients(response.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  let tBody = (
    <tr>
      <td colSpan="5">Nema podataka za prikaz</td>
    </tr>
  )

  if (allClients.length > 0) {
    tBody = allClients.map((c) => (
      <tr key={c._id}>
        <td>{c.compName}</td>
        <td>{c.compVatId}</td>
        <td>{c.compId}</td>
        <td>{c.compEmail}</td>
        <td>{c.compPhone}</td>
        <td style={{ wordBreak: 'break-word' }}>{c.deviceAddrCity}, {c.deviceAddress}</td>
        <td>{c.vendorName}</td>
        <td>{c.software}</td>
        <td>
          {(() => {
            switch (c.suspended) {
              case true:
                return (
                  <span>
                    Suspendovana
                  </span>
                );
              default:
                return (
                  <span>
                    Aktivna
                  </span>
                );
            }
          })()}
        </td>
        <td>{c.licence_id}</td>
        <td style={{ width: '8%' }}>
          <Row>
            <div className="btn-group mb-4">
              <Link
                to={`/ClientsNewLicences`}
                className="btn btn-fill btn-info btn-sm"
              >Izmena
              </Link>
            </div>
            <div className="btn-group mb-4">
              <Link
                to={`/ClientsNewLicences`}
                type="button"
                className="btn-fill btn btn-sm btn-danger"
              >Izbrisi
              </Link>
            </div>
            <div className="btn-group mb-4">
              <Link
                to={`/ClientsNewLicences`}
                className="btn btn-fill btn-info btn-sm"
              >Istorija
              </Link>
            </div>
          </Row>
        </td>
      </tr>
    ))
  }

  return (
    <div className="content">
      <Container fluid>
        <form onKeyUp={(e) => { onKeyUp(e) }}>
          <Row className='justify-content-center'>
            <div className="col-md-2">
              <TextFieldGroup
                type="text"
                placeholder="Ime kompanije"
                name="compName"
                style={{ marginBottom: '5px' }}
                onChange={(e) => { setCompName(e.target.value) }}
              />
            </div>
            <div className="col-md-2">
              <TextFieldGroup
                type="text"
                placeholder="Pib"
                name="compVatId"
                style={{ marginBottom: '5px' }}
                onChange={(e) => { setCompVatId(e.target.value) }}
              />
            </div>
            <div className="col-md-2">
              <TextFieldGroup
                type="text"
                placeholder="MB"
                name="compId"
                style={{ marginBottom: '5px' }}
                onChange={(e) => { setCompId(e.target.value) }}
              />
            </div>
            <div className="col-md-2">
              <TextFieldGroup
                type="text"
                placeholder="ID licence"
                name="licenceId"
                style={{ marginBottom: '5px' }}
                onChange={(e) => { setLicenceId(e.target.value) }}
              />
            </div>
            <Col md={2}>
              <div className="row">
                <input
                  type="button"
                  value="Pretrazi"
                  onClick={search}
                  className="btn-fill col-md-12 pull-center btn btn-info"
                  style={{ marginTop: '15px' }}
                />
              </div>
            </Col>
          </Row>
        </form>
        <br></br>
        <Row>
          <Col md={12}>
            <Card
              title="Licence"
              category="Informacije o licencama"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover id="table-to-xls" >
                  <thead>
                    <tr>
                      <th>Ime kompanije</th>
                      <th>PIB </th>
                      <th>MB</th>
                      <th>Mail kompanije</th>
                      <th>Broj telefona</th>
                      <th>Adresa </th>
                      <th>Prodavac</th>
                      <th>Softver</th>
                      <th>Status</th>
                      <th>Id licence</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {tBody}
                  </tbody>
                </Table>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ClientsNewLicences


import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Container } from 'react-bootstrap';
import Card from '../../components/Card/Card';
import axios from 'axios';
import {  Link } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import Swal from 'sweetalert2';

function ClientInfo() {
  const [clientData, setClientData] = useState();
  const [loading, setLoading] = useState(true);
  const [compName, setCompName] = useState('');
  const [compVatId, setCompVatId] = useState('');


  useEffect(() => {
    fetchClientData();
  }, []);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/galebstart/galebStartSearch/');

      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client data:', error);
      setLoading(false);
    }
  };

  const searchClients = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/galebstart/galebStartSearch/', {
        whatToLook: compName || compVatId,
        whereToLook: compName ? 'compName' : 'compVatId',
      });

      setClientData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching client data:', error);
      setLoading(false);
    }
  };
  /*
  const handleDeleteClient = async (id) => {
    try {
      const response = await axios.post(`/api/galebstart/deleteClient/${id}`);
      console.log(response.data.message);

      
      const updatedClientData = clientData.filter(client => client._id !== id);
      setClientData(updatedClientData);
    } catch (error) {
      console.error('Greška pri brisanju korisnika:', error);
    }
  };
*/
const handleDeleteClient = async (id) => {
  try {
    const result = await Swal.fire({
      title: 'Da li ste sigurni?',
      text: 'Ova radnja ne može biti poništena!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Da, obriši!',
      cancelButtonText: 'Odustani'
    });

    if (result.isConfirmed) {
      const response = await axios.post(`/api/galebstart/deleteClient/${id}`);
      console.log(response.data.message);

      const updatedClientData = clientData.filter(client => client._id !== id);
      setClientData(updatedClientData);
      
      Swal.fire(
        'Obrisano!',
        'Korisnik je uspešno obrisan.',
        'success'
      );
    }
  } catch (error) {
    console.error('Greška pri brisanju korisnika:', error);
    Swal.fire(
      'Greška!',
      'Došlo je do greške prilikom brisanja korisnika.',
      'error'
    );
  }
};

  return (
    <div className='content'>
      <Container fluid>
      <Row className='justify-content-center'>
          <Col md={5}>
            <TextFieldGroup
              type="text"
              value={compName}
              label="Ime kompanije"
              name="compName"
              onChange={(e) => setCompName(e.target.value)}
              placeholder='Ime kompanije'
            />
          </Col>
          <Col md={5}>
            <TextFieldGroup
              type="text"
              value={compVatId}
              label="PIB"
              name="compVatId"
              onChange={(e) => setCompVatId(e.target.value)}
              placeholder='PIB'
            />
          </Col>
          <Col md={2}>
            <div className="row">
              <div className="col-md-12">
                <input
                type='button'
                value='Pretraži'
                onClick={searchClients.bind(this)}
                className="btn-fill col-md-12 pull-center btn btn-info"
                style={{ marginTop: '25px' }}/>
                
              </div>
            </div>
          </Col>
          <Col md={12}>
    <div className="d-grid gap-2 d-md-flex justify-content-md-center"   style={{ marginTop: '25px' }}>
      <Link to="/galebstart/createG" className="btn btn-primary btn-fill ">
        Kreiraj korisnika
      </Link>
    
    </div>
  </Col>
        </Row>
        {loading ? (
          <p>Pretrazivanje...</p>
        ) : clientData ? (
          <Row style={{ marginTop: '40px' }}>
            <Col md={12}>
              <Card
                title="Licence"
                category="Informacije o licencama"
                ctTableFullWidth
                ctTableResponsive
                
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Ime kompanije</th>
                        <th>PIB (VatId)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clientData.map((client) => (
                        <tr key={client._id}>
                          <td style={{ wordBreak: 'break-word' }}>
                            {client.compName}
                          </td>
                          <td>{client.compVatId}</td>
                          <td> <Link to={`/galebstart/updateClient/${client._id}`} className="btn btn-success btn-fill" >
                            Izmeni
                            </Link></td>
                            <td> <input type='button' value="Obrisi" className="btn btn-danger btn-fill"onClick={() => handleDeleteClient(client._id)}>
                            
                            </input></td>
                            <td><Link to ={`/galebstart/clientCallHistory/${client._id}`} className="btn btn-info btn-fill">
                              Logovi</Link></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
         
        
        ) : (
          <p>Nema dostupnih podataka o klijentima</p>
        )}
      </Container>
    </div>
  );
}

export default ClientInfo;



/*
import React from 'react';
import { Row, Col, Table,Container } from 'react-bootstrap';
import Card from '../../components/Card/Card';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';





function ClientInfo() {
  const { whatToLook, whereToLook } = useParams();
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
      }
        const whatToLook = '987987665'
        const whereToLook = 'compVatId'
      try {
        const response = await axios.post('/api/admin/galebSearch/', {
          whatToLook,
          whereToLook,
        });

        setClientData(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching client data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [whatToLook, whereToLook]);

  return (


    <div>
      {loading ? (
        <p>Loading...</p>
      ) : clientData ? (
        <div className="content">


        <Row>
          <Col md={12}>
            <Card
              title="Licence"
              category="Informacije o licencama"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover >
                  <thead>
                    <tr>
  
                      <th>Ime kompanije</th>
                      <th>PIB (VatId)</th>
  
                    </tr>
                  </thead>
                  <tbody>
                  {clientData.map(client => (
                  <><td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
                    <td>{client.compVatId}</td></>
    ))
    }
                  </tbody>
                </Table>
              }
            />
          </Col>
        </Row>
      </div>
      ) : (
        <p>No client data available.</p>
      )}
    </div>
  );
}



    /*
    <div className="content">


      <Row>
        <Col md={12}>
          <Card
            title="Licence"
            category="Informacije o licencama"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover >
                <thead>
                  <tr>

                    <th>Ime kompanije</th>
                    <th>PIB (VatId)</th>

                  </tr>
                </thead>
                <tbody>
                {clientData.map(client => (
                <><td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
                  <td>{client.compVatId}</td></>
  ))
  }
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    </div>
    */
  




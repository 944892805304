import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getClientById, getSendRemovalMail } from '../../actions/clientActions';
import { Container, Row, Col } from 'react-bootstrap';
import SelectListGroup from '../common/SelectListGroup';
import isEmpty from '../../validation/is-empty';
import { Card } from '../Card/Card';
import { getCurrentProfile, getProfilesSellers } from '../../actions/profileAction';
const Validator = require('validator');

class RemoveClientDist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _id: '',
            compName: '',
            compVatId: '',
            deviceSerialNo: '',
            cbLicenceId: '',
            vendorName: '',
            reason: '',
            senderName: '',
            disabled: true,
            errors: {}
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getProfilesSellers();
        this.setState({ senderName: this.props.auth.user.name })
        this.props.getCurrentProfile();
        if (this.props.match.params._id) {
            this.props.getClientById(this.props.match.params._id);
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const removalRequest = {
            _id: this.state._id,
			compName: Validator.stripLow(this.state.compName.trim()),
			compVatId: Validator.stripLow(this.state.compVatId.trim()),
			deviceSerialNo: Validator.stripLow(this.state.deviceSerialNo.toUpperCase().trim()),
			cbLicenceId: Validator.stripLow(this.state.cbLicenceId.trim()),
            senderName: Validator.stripLow(this.state.senderName.trim()),
			reason: Validator.stripLow(this.state.reason.trim())
        };
        if (this.state.reason !== '') {
            this.props.getSendRemovalMail(removalRequest, this.props.history);
        } else {
            alert("Morate odabrati razlog za brisanje licence!")
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({ errors: nextProps.errors });
        }

        if (nextProps.client.client) {
            const client = nextProps.client.client;

            client._id = !isEmpty(client._id) ? client._id : '';
            client.compName = !isEmpty(client.compName) ? client.compName : '';
            client.compVatId = !isEmpty(client.compVatId) ? client.compVatId : '';
            client.deviceSerialNo = !isEmpty(client.deviceSerialNo) ? client.deviceSerialNo : '';
            client.cbLicenceId = !isEmpty(client.cbLicenceId) ? client.cbLicenceId : '';
            client.vendorName = !isEmpty(client.vendorName) ? client.vendorName : '';

            // Set component fields state
            this.setState({
                _id: client._id,
                compName: client.compName,
                compVatId: client.compVatId,
                deviceSerialNo: client.deviceSerialNo,
                cbLicenceId: client.cbLicenceId,
                vendorName: client.vendorName,
            });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { errors } = this.state;
        const { profiles } = this.props.profiles;

        const optionsProfile = profiles.map(profile => ({
            label: profile.name,
            value: profile.NavCode,
        }))

        optionsProfile.unshift({ label: '* Selektujte prodavca', value: '' });

        return (
            <div className="content">
                <Container fluid>
                    <Row>
                        <Col md={6}>
                            <Card
                                title="Zahtev za brisanje "
                                category="Zahtev za brisanje "
                                content={
                                    <form onSubmit={this.onSubmit} autoComplete="off">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    disabled={this.state.disabled ? true : 'null'}
                                                    type="text"
                                                    label="Id dokumenta"
                                                    placeholder="Id dokumenta"
                                                    name="_id"
                                                    value={this.state._id}
                                                    onChange={this.onChange}
                                                    error={errors._id}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TextFieldGroup
                                                    disabled={this.state.disabled ? true : 'null'}
                                                    type="text"
                                                    label="Ime korisnika licence"
                                                    placeholder="Ime korisnika licence"
                                                    name="compName"
                                                    value={this.state.compName}
                                                    onChange={this.onChange}
                                                    error={errors.compName}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    disabled={this.state.disabled ? true : 'null'}
                                                    label="Pib (compVatId)"
                                                    placeholder="Pib (compVatId)"
                                                    name="compVatId"
                                                    value={this.state.compVatId}
                                                    onChange={this.onChange}
                                                    error={errors.compVatId}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <TextFieldGroup
                                                    disabled={this.state.disabled ? true : 'null'}
                                                    label="Serijski broj urđaja"
                                                    placeholder="Serijski broj uređaja"
                                                    name="deviceSerialNo"
                                                    value={this.state.deviceSerialNo}
                                                    onChange={this.onChange}
                                                    error={errors.deviceSerialNo}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <SelectListGroup
                                                    disabled={this.state.disabled ? true : 'null'}
                                                    labels="Prodavac"
                                                    placeholder="vendorName"
                                                    name="vendorName"
                                                    value={this.state.vendorName}
                                                    onChange={this.onChange}
                                                    options={optionsProfile}
                                                />
                                            </div>
                                        </div>
                                        <label style={{ 'fontSize': '15px' }}>Razlog zahteva za brisanje: </label>
                                        <div className="col-sm-12">
                                            <div className="form-check">
                                                <label style={{ 'fontSize': '15px' }}>
                                                    <input
                                                        onChange={this.onChange}
                                                        type="radio"
                                                        name="reason"
                                                        value="Prestanak obavljanja delatnosti"
                                                        className="form-check-input"
                                                    />
                                                    Prestanak obavljanja delatnosti
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label style={{ 'fontSize': '15px' }}>
                                                    <input
                                                        onChange={this.onChange}
                                                        type="radio"
                                                        name="reason"
                                                        value="Mirovanje"
                                                        className="form-check-input"
                                                    />
                                                    Mirovanje
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label style={{ 'fontSize': '15px' }}>
                                                    <input
                                                        onChange={this.onChange}
                                                        type="radio"
                                                        name="reason"
                                                        value="Nezadovoljstvo kupca"
                                                        className="form-check-input"
                                                    />
                                                    Nezadovoljstvo kupca
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label style={{ 'fontSize': '15px' }}>
                                                    <input
                                                        onChange={this.onChange}
                                                        type="radio"
                                                        name="reason"
                                                        value="Licenca je visak (duplikat)"
                                                        className="form-check-input"
                                                    />
                                                    Licenca je visak (duplikat)
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label style={{ 'fontSize': '15px' }}>
                                                    <input
                                                        onChange={this.onChange}
                                                        type="radio"
                                                        name="reason"
                                                        value="Rentiranje uredjaja"
                                                        className="form-check-input"
                                                    />
                                                    Rentiranje uređaja
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <label style={{ 'fontSize': '15px' }}>
                                                    <input
                                                        onChange={this.onChange}
                                                        type="radio"
                                                        name="reason"
                                                        value="Neplaćanje licence"
                                                        className="form-check-input"
                                                    />
                                                    Neplaćanje licence
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input
                                                    type="submit"
                                                    value="Posalji zahtev"
                                                    className="btn-fill col-md-12 pull-center btn btn-info"
                                                    style={{ margin: '3px' }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <Link
                                                    to="/clients"
                                                    className="btn-fill col-md-12 pull-center btn btn-default"
                                                    style={{ margin: '3px' }}
                                                >
                                                    Nazad
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="clearfix"> </div>
                                    </form>
                                }
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

RemoveClientDist.propTypes = {
    getClientById: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
    getProfilesSellers: PropTypes.func.isRequired,
    getSendRemovalMail: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    client: state.client,
    profiles: state.profile,
    errors: state.errors,
    auth: state.auth,
});

export default connect(
    mapStateToProps,
    { getClientById, getCurrentProfile, getProfilesSellers, getSendRemovalMail }
)(withRouter(RemoveClientDist));

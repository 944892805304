import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TextFieldGroup from '../common/TextFieldGroup';
import Select from 'react-select';
import { Row, Col, Table, Container } from 'react-bootstrap';
import Card from '../../components/Card/Card';
import { getCurrentProfile } from '../../actions/profileAction';
import axios from 'axios';
import Swal from 'sweetalert2';


const GSClientCallHistory = (props) => {
  const lienceId = props.match.params._id
  const [clientGS, setClientGS] = useState()
  const [currentUser, setCurrentUser] = useState()
  const [compVatId, setCompVatId] = useState()
  const [comment, setComment] = useState()
  const [logStatus, setStatus] = useState()
  const [logStatusValue, setLogStatusValue] = useState()
  const [historyCall, setHistoryCall] = useState()

  useEffect(() => {
    getCurrentProfile()
    getCurrentUser()
    getHistoryLogs()
  }, [])
  //console.log(props)


  //trenutni GSKlijent
  const getCurrentProfile = async () => {
    const response = await axios.get(`/api/galebstart/${lienceId}`)
    const client = response.data
    setClientGS(client)
    setCompVatId(client.compVatId)

  }
  //console.log(logStatusValue)
  //trenutni korisnik
  const getCurrentUser = async () => {
    const response = await axios.get(`/api/users/current`)
    const user = response.data
    setCurrentUser(user)
    // console.log(user.NavCode)
  }
  // console.log(historyCall)
  //kreiranje loga

  const createHistoryLog = async (e) => {
    e.preventDefault()
    const dataHis = {
      compVatId,
      comment,
      logStatus: logStatusValue,
    }
    if (logStatusValue == 'Resen' || logStatusValue == 'U toku') {
      await axios.post(`/api/galebstart/createHistoryLog/${currentUser.NavCode}`, dataHis)
        .then(res => { window.location.reload() })

    } else {
      //alert ('Niste izabrali status')
      Swal.fire({
        icon: 'error',
        title: 'Odaberite status unosa'

      })
    }
  }
  //opcije za status
  const optionsStatus = [

    { label: 'Resen', value: 'Resen' },
    { label: 'U toku', value: 'U toku' }
  ]
  const getHistoryLogs = async () => {
    const response = await axios.post(`/api/galebstart/getAllLogsForVatId/${lienceId}`)
    const data = response.data
    setHistoryCall(data)
    // console.log(data)
  }
  const updateHistoryLog = async (id) => {
    const response = await axios.post(`/api/galebstart/solvedCall/${id}`)
    Swal.fire({
      icon: 'success',
      title: 'Status rešen',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  }



  return (
    <div className='content'>
      <Container fluid>
        <Row>
          <div className="clearfix"> </div>
          <Col md={3}>
            <Card
              title="Informacije o pozivu"
              category="Forma za unos informacija o pozivu"
              ctTableFullWidth
              ctTableResponsive
              content={
                <form >

                  <div className="row">
                    <div className="col-md-12">
                      <TextFieldGroup
                        label="Pib"
                        placeholder="Pib"
                        name="compVatId"
                        value={compVatId}
                        onChange={(e) => setCompVatId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <textarea
                        class="form-control"
                        label="Komentar"
                        placeholder="Komentar"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        labels="Status"
                        placeholder="Status"
                        name="logStatus"
                        options={optionsStatus}
                        value={logStatus}
                        onChange={e => { setStatus(e); setLogStatusValue(e.value) }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="submit"
                        value="Unesi"
                        className="btn-fill col-md-12 pull-center btn btn-info"
                        style={{ margin: '3px' }}
                        onClick={createHistoryLog}
                      />
                    </div>
                  </div>
                </form>
              }
            />
          </Col>
          <Col md={9}>
            <Card
              title="Istorija poziva"
              category="informacije o istoriji poziva"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Vreme poziva</th>
                      <th>Komentar</th>
                      <th>Datum statusa</th>
                      <th>Status</th>
                      <th>Agent</th>
                    </tr>
                  </thead>

                  <tbody>
                    {historyCall && historyCall.length > 0 ? (
                      historyCall.map((history) => (
                        <tr key={history._id}>
                          <td>{history.dateOfCall.replace('T', ' ').replace('Z', '').replace(':00.000', '')}</td>
                          <td style={{ wordBreak: 'break-word' }}>{history.comment}</td>
                          <td>{history.dateOfStatusChange}</td>
                          <td>{history.logStatus === 'Resen' ? 'Resen' :
                            (
                              <button
                                type="button"
                                className="btn-fill btn btn-sm btn-danger"
                                onClick={() => updateHistoryLog(history._id)}
                              >
                                Resi
                              </button>
                            )}</td>
                          <td>{history.callAnsweredBy}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5">Nema podataka</td>
                      </tr>
                    )}
                  </tbody>

                </Table>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GSClientCallHistory
import {
  ADD_CLIENT,
  GET_CLIENTS,
  GET_CLIENT,
  CLIENTS_LOADING,
  DELETE_CLIENT,
  GET_DELETED_CLIENTS,
  GET_CLIENT_BY_COMPID,
  GET_CLIENTS_BY_VENDOR,
  CHANGE_LICENCE,
  GET_DEBTOR,
  DELETE_DEBTOR,
  GET_CLIENTS_BY_VENDOR_COUNTER,
  GET_LICENCEID_HISTORY,
  GET_DELETED_CLIENTS_BY_DATE,
  GET_DELETED_CLIENTS_BY_VENDOR,
  GET_CLIENTS_CALL_HISTORY,
  GET_ALL_PERSONAL_LOGS,
  GET_ALL_LOGS_BY_DATE,
  GET_ALL_UNSEEN_LOGS,
} from '../actions/types';

const initialState = {
  allUnseenLogs: [],
  clientsCallHistory: [],
  clientsByVendorCounter: [],
  clientsByVendor: [],
  clients: [],
  deletedclients: [],
  deletedclientsbydate: [],
  deletedclientsbyvendor: [],
  client: {},
  historyOfLicence: [],
  debtor: {},
  debtors: [],
  personalLogsByAgent: [],
  logsByDate: [],
  loading: false
};

export default function FuncState(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_UNSEEN_LOGS:
      return {
        ...state,
        allUnseenLogs: action.payload,
        loading: false,
      }
    case GET_ALL_LOGS_BY_DATE:
      return {
        ...state,
        logsByDate: action.payload,
        loading: false,
      }
    case GET_ALL_PERSONAL_LOGS:
      return {
        ...state,
        personalLogsByAgent: action.payload,
        loading: false,
      }
    case GET_CLIENTS_CALL_HISTORY:
      return {
        ...state,
        clientsCallHistory: action.payload,
        loading: false,
      }
    case GET_DELETED_CLIENTS_BY_VENDOR:
      return {
        ...state,
        deletedclientsbyvendor: action.payload,
        loading: false,
      }
    case GET_DELETED_CLIENTS_BY_DATE:
      return {
        ...state,
        deletedclientsbydate: action.payload,
        loading: false,
      }
    case CLIENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_LICENCEID_HISTORY:
      return {
        ...state,
        historyOfLicence: action.payload,
        loading: false
      }
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        loading: false
      };
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
        loading: false
      };
    case GET_CLIENT_BY_COMPID:
      return {
        ...state,
        clients: action.payload,
        loading: false
      };
    case GET_CLIENTS_BY_VENDOR:
      return {
        ...state,
        clientsByVendor: action.payload,
        loading: false
      };
    case ADD_CLIENT:
      return {
        ...state,
        clients: [action.payload, ...state.clients]
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter(client => client._id !== action.payload)
      };
    case DELETE_DEBTOR:
      return {
        ...state,
        debtors: state.debtors.filter(debtor => debtor._id !== action.payload)
      };
    case CHANGE_LICENCE:
      return {
        ...state,
        clients: state.clients.filter(client => client._id !== action.payload)
      };
    case GET_DEBTOR:
      return {
        ...state,
        debtors: action.payload,
        loading: false
      };
    case GET_CLIENTS_BY_VENDOR_COUNTER:
      return {
        ...state,
        clientsByVendorCounter: action.payload,
        loading: false
      };
    case GET_DELETED_CLIENTS:
      return {
        ...state,
        deletedclients: action.payload,
        loading: false
      };
    default:
      return state;
  }
}

import axios from "axios";
import Swal from 'sweetalert2'
import {
	GET_BLACKLIST_DEVICE_PER_VENDOR,
	GET_ALL_BLACKLIST_DEVICES,
	BLACKLIST_LOADING,
	GET_BLACKLIST_DEVICE,
} from './types';

//Admin unsuspend device
export const unsuspendDeviceAdmin = _id => dispatch => {
	dispatch(setBlackListLoading())
	axios
		.post(`/api/blacklist/unsuspendDeviceAdmin/${_id}`)
		.then(res => {
			Swal.fire({
				position: 'top-end',
				icon: 'success',
				title: 'Uređaj je uklonjen sa spiska suspendovanih uređaja',
				showConfirmButton: false,
				timer: 3000
			})
			setTimeout(function () {
				window.location.reload()
			}, 3000);
		})
		.catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'eror',
        title: 'Greška, pokušajte ponovo!',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
		})
}
//get specific blacklisted device
export const getBlacklistedDevice = deviceSerialNo => dispatch =>{
	dispatch(setBlackListLoading())
	axios
		.post(`/api/blacklist/getBlacklistedDevice/${deviceSerialNo}`)
		.then(res =>
			dispatch({
				type: GET_BLACKLIST_DEVICE,
				payload: res.data
			})
		)
		.catch(err =>
			dispatch({
				type: GET_BLACKLIST_DEVICE,
				payload: null
			})
		);
}


//Vendor unsuspend device
export const unsuspendDeviceVendor = _id => dispatch => {
	dispatch(setBlackListLoading())
	axios
		.post(`/api/blacklist/unsuspendDeviceVendor/${_id}`)
		.then(res => {
			Swal.fire({
				position: 'top-end',
				icon: 'success',
				title: 'Uređaj je uklonjen sa spiska suspendovanih uređaja',
				showConfirmButton: false,
				timer: 3000
			})
			setTimeout(function () {
				window.location.reload()
			}, 3000);
		})
		.catch(err => {
      Swal.fire({
        position: 'top-end',
        icon: 'eror',
        title: 'Greška, pokušajte ponovo!',
        showConfirmButton: false,
        timer: 3000
      })
      setTimeout(function () {
        window.location.reload()
      }, 3000);
		})
}

//Get all blacklist Devices Admin version
export const getAllBlackListDevices = () => dispatch => {
	dispatch(setBlackListLoading())
	axios
		.post(`/api/blacklist/allBlackListDevices`)
		.then(res =>
			dispatch({
				type: GET_ALL_BLACKLIST_DEVICES,
				payload: res.data
			})
		)
		.catch(err =>
			dispatch({
				type: GET_ALL_BLACKLIST_DEVICES,
				payload: null
			})
		);
}

//Get all blacklist Devices vendor version
export const getBlackListDevicesPerVendor = NavCode => dispatch => {
	dispatch(setBlackListLoading())
	axios
		.post(`/api/blacklist/BlackListDevicesPerVendor/${NavCode}`)
		.then(res =>
			dispatch({
				type: GET_BLACKLIST_DEVICE_PER_VENDOR,
				payload: res.data
			})
		)
		.catch(err =>
			dispatch({
				type: GET_BLACKLIST_DEVICE_PER_VENDOR,
				payload: null
			})
		);
}

//set blacklist loading
export const setBlackListLoading = () => {
	return {
		type: BLACKLIST_LOADING
	}
}

import {
	GET_BLACKLIST_DEVICE_PER_VENDOR,
	GET_ALL_BLACKLIST_DEVICES,
	GET_BLACKLIST_DEVICE,
	BLACKLIST_LOADING,
} from '../actions/types';

const initialState = {

	BlackListDevices: [],
	BlackListDevicesPerVendor: [],
	blackListedDevice: [],
	blockedDevice: {},
	loading: false
};

export default function FuncState(state = initialState, action) {
	switch (action.type) {
		case GET_BLACKLIST_DEVICE:
			return {
				...state,
				blackListedDevice: action.payload,
				loading: false
			}
		case BLACKLIST_LOADING:
			return {
				...state,
				loading: true
			};
		case GET_ALL_BLACKLIST_DEVICES:
			return {
				...state,
				BlackListDevices: action.payload,
				loading: false
			};
		case GET_BLACKLIST_DEVICE_PER_VENDOR:
			return {
				...state,
				BlackListDevicesPerVendor: action.payload,
				loading: false
			};
		default:
			return state;
	}
}
import axios from 'axios';
import {
    GET_ZIPCODES,
    GET_ZIPCODE,
    ZIPCODES_LOADING
} from './types';

// Get all zip codes
export const getzipCodes = () => dispatch => {
    dispatch(setzipCodesLoading());
    axios
        .get('/api/zipcodes/all')
        .then(res => 
            dispatch({
                type: GET_ZIPCODES,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ZIPCODES,
                payload: null
            })
        );
}; 

// Get zip code by id
export const getzipCodesById = _id => dispatch => {
    dispatch(setzipCodesLoading());
    axios
        .get(`/api/zipcodes/${_id}`)
        .then(res =>
            dispatch({
                type: GET_ZIPCODE,
                payload: res.data
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ZIPCODE,
                payload: null
            })
        );
        console.log(_id);
};

// Zip codes loading
export const setzipCodesLoading = () => {
    return {
        type: ZIPCODES_LOADING
    };
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Card from '../../components/Card/Card';
import { getClientsStats, getDistStats } from '../../actions/statisticsActions';
import { exportExcelVendorClients, downloadExcel, getDeletedLicences, getNewLicences, getDBRep } from '../../actions/clientActions';
import TextFieldGroup from '../common/TextFieldGroup';
import Select from 'react-select';
import axios from 'axios';
//import SelectListGroup from '../common/SelectListGroup';
import { getCurrentProfile, getProfilesSellers } from '../../actions/profileAction'
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import InactiveLicence from './InactiveLicence';
import ExcelUpload from './ExcelUpload'
import ClientPhone from './ClientPhone';
class Statistics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDateU: '',
			endDateU: '',
			startDateD: '',
			endDateD: '',
			startDateN: '',
			endDateN: '',
			selectedVendor: null,
			vendorName: '',
		};
		this.onChange = this.onChange.bind(this)
	}

	getCurrentDBRep(e) {
		e.preventDefault();
		this.props.getDBRep()
	}

	onClickGetRemovedClients(e) {
		e.preventDefault();
		let data = {
			startDate: this.state.startDateD,
			endDate: this.state.endDateD
		}
		console.log(data)
		if (this.state.startDateD !== '' && this.state.endDateD !== '') {
			this.props.getDeletedLicences(data)
		}
	}

	onClickGetNewClients(e) {
		e.preventDefault();
		let data = {
			startDate: this.state.startDateN,
			endDate: this.state.endDateN
		}
		console.log(data)
		if (this.state.startDateN !== '' && this.state.endDateN !== '') {
			this.props.getNewLicences(data)
		}
	}

	getUnsuspendedLicences(e) {
		e.preventDefault();
		var fileDownload = require('js-file-download');
		const payload = {
			startDate: this.state.startDateU,
			endDate: this.state.endDateU
		}

		axios.post('/api/utility/getUnsuspended/', payload, { responseType: 'blob' })
			.then(res => {
				var currentDate = new Date();
				var date = new Intl.DateTimeFormat('en-GB').format(currentDate);
				var filename = "Spisak reaktiviranih " + date + ".xls";
				fileDownload(res.data, filename);
			})
			.catch(err => {
				console.log(err)
			})
	}
	onChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	onClickDownloadExcel(vendorName) {
		console.log(vendorName);
		this.props.downloadExcel(vendorName);
	}

	onClickexportExcelVendorClients(exportCode) {
		const userMail = this.props.auth.user.email;
		this.props.exportExcelVendorClients(exportCode, userMail);
	}

	componentDidMount() {
		this.props.getCurrentProfile();
		this.props.getClientsStats();
		this.props.getProfilesSellers();
	}

	handleChange = selectedVendor => {
		this.setState({ selectedVendor });
		this.setState({ vendorName: selectedVendor !== null ? selectedVendor.value : '' });
		if (selectedVendor.value !== '') {
			this.props.getDistStats(selectedVendor.value);
		}
	};

	render() {

		const { profiles } = this.props.profiles;
		let { selectedVendor } = this.state;
		const optionsProfile = profiles.map(profile => ({
			label: profile.NavCode + "   " + profile.name,
			value: profile.NavCode,
		}))

		optionsProfile.unshift({ label: '* Selektujte prodavca', value: '' });

		const statistics = this.props.statistic.statVenCounter;
		const statisticTable = this.props.statistic.statDistCounter;
		var Galeb = 0;
		var Telekom = 0;
		var Yettel = 0;
		var GalebDistributer = 0;




		let statisticTableItems;
		if (statisticTable === null) {

		} else {
			if (statisticTable.length > 0) {
				statisticTableItems = statisticTable.map(statistic => (
					<tr key={statistic._id}>
						<td style={{ width: '30%' }}>{statistic._id}</td>
						<td>{statistic.exportCode}</td>
						<td>
							<button
								type="button"
								className="btn-fill btn btn-sm btn-success"
								onClick={this.onClickDownloadExcel.bind(this, statistic.exportCode)}
							>
								Preuzmi Excel
							</button>
						</td>
						<td>{statistic.Sim}</td>
						<td>{(statistic.Aktivno + statistic.Neaktivno) - statistic.Sim}  </td>
						<td>{statistic.Aktivno}</td>
						<td>{statistic.Neaktivno}</td>
						<td>{statistic.Aktivno + statistic.Neaktivno}</td>
					</tr>
				));
			}
			else {
				statisticTableItems = (
					<tr>
						<td>Nema nista za prikazati</td>
					</tr>
				);
			}
		}

		return (
			<div className="content">
				<Row className='justify-content-center'>
					<Container fluid>
						<Row style={{ justifyContent: 'center' }}>
							<Col md={3}>
								<Card
									title="Trenutno stanje"
									category="Preuzimanje trenutnog stanja licenci"
									ctTableFullWidth
									ctTableResponsive
									content={
										<form onSubmit={this.getCurrentDBRep.bind(this)} style={{ height: '70px' }}>
											<div className="row">
												<div className="col-md-4">
													<input
														type="submit"
														value="Preuzmi trenutno stanje"
														className="btn-fill pull-center btn btn-info"
														style={{ margin: '3px', marginTop: '25px' }}
													/>
												</div>
											</div>
										</form>
									}
								/>
							</Col>
							<Col md={4}>
								<Card
									title="Obrisane licence"
									category="[)"
									ctTableFullWidth
									ctTableResponsive
									content={
										<form onSubmit={this.onClickGetRemovedClients.bind(this)} style={{ height: '70px' }}>
											<div className="row">
												<div className="col-md-4">
													<TextFieldGroup
														label="Datum od"
														type="date"
														placeholder="YYYY-MM-DD"
														name="startDateD"
														value={this.state.startDateD}
														onChange={this.onChange}
													/>
												</div>
												<div className="col-md-4">
													<TextFieldGroup
														label="Datum do "
														type="date"
														placeholder="YYYY-MM-DD"
														name="endDateD"
														value={this.state.endDateD}
														onChange={this.onChange}
													/>
												</div>
												<div className="col-md-4">
													<input
														type="submit"
														value="Preuzmi"
														className="btn-fill pull-center btn btn-info"
														style={{ margin: '3px', marginTop: '25px' }}
													/>
												</div>
											</div>
										</form>
									}
								/>
							</Col>
							<Col md={4}>
								<Card
									title="Nove licence"
									category="[)"
									ctTableFullWidth
									ctTableResponsive
									content={
										<form onSubmit={this.onClickGetNewClients.bind(this)} style={{ height: '70px' }}>
											<div className="row">
												<div className="col-md-4">
													<TextFieldGroup
														label="Datum od"
														type="date"
														placeholder="YYYY-MM-DD"
														name="startDateN"
														value={this.state.startDateN}
														onChange={this.onChange}
													/>
												</div>
												<div className="col-md-4">
													<TextFieldGroup
														label="Datum do "
														type="date"
														placeholder="YYYY-MM-DD"
														name="endDateN"
														value={this.state.endDateN}
														onChange={this.onChange}
													/>
												</div>
												<div className="col-md-4">
													<input
														type="submit"
														value="Preuzmi"
														className="btn-fill pull-center btn btn-info"
														style={{ margin: '3px', marginTop: '25px' }}
													/>
												</div>
											</div>
										</form>
									}
								/>
							</Col>
						</Row>
					</Container>
					<Col md={10}>
						<Row>
							<Card
								title="Reaktivirane licence izmedju dva datuma"
								category="Uzima od prvog datuma (taj dan) do drugog datuma (bez tog dana)"
								ctTableFullWidth
								ctTableResponsive
								content={
									<form onSubmit={this.getUnsuspendedLicences.bind(this)} style={{ height: '70px' }}>
										<div className="row">
											<div className="col-md-4">
												<TextFieldGroup
													label="Datum od"
													type="date"
													placeholder="YYYY-MM-DD"
													name="startDateU"
													value={this.state.startDateU}
													onChange={this.onChange}
												/>
											</div>
											<div className="col-md-4">
												<TextFieldGroup
													label="Datum do "
													type="date"
													placeholder="YYYY-MM-DD"
													name="endDateU"
													value={this.state.endDateU}
													onChange={this.onChange}
												/>
											</div>
											<div className="col-md-4">
												<input
													type="submit"
													value="Preuzmi"
													className="btn-fill pull-center btn btn-info"
													style={{ margin: '3px', marginTop: '25px' }}
												/>
											</div>
										</div>
									</form>
								}
							/>
						</Row>
					</Col>
					<div className="col-md-6" style={{ marginBottom: '20px' }}>
						<label className="control-label">Prodavac</label>
						<Select
							defaultValue=""
							isClearable
							labels="Distributer / komercijalista"
							placeholder=" * Selektujte prodavca"
							name="vendorName"
							value={selectedVendor}
							onChange={this.handleChange}
							options={optionsProfile}
						/>
					</div>
					<Col md={10}>
						<Row>
							<Card
								title="Licence"
								category="Informacije o licencama"
								ctTableFullWidth
								ctTableResponsive
								content={
									<Table striped hover>
										<thead>
											<tr>
												<th>Ime distributera</th>
												<th>Nav sifra</th>
												<th />
												<th>Sa sim</th>
												<th>Bez sim</th>
												<th>Aktivna</th>
												<th>Neaktivna</th>
												<th>Ukupno</th>
											</tr>
										</thead>
										<tbody>{statisticTableItems}</tbody>
									</Table>
								}
							/>
						</Row>
					</Col>
				</Row>
				{/*
				<InactiveLicence />
				<ClientPhone/>
				<ExcelUpload />
							*/}
			</div >
		);
	}
}

Statistics.propTypes = {
	getClientsStats: PropTypes.func.isRequired,
	exportExcelVendorClients: PropTypes.func.isRequired,
	statistic: PropTypes.object.isRequired,
	getProfilesSellers: PropTypes.func.isRequired,
	getCurrentProfile: PropTypes.func.isRequired,
	downloadExcel: PropTypes.func.isRequired,
	getDeletedLicences: PropTypes.func.isRequired,
	getNewLicences: PropTypes.func.isRequired,
	getDBRep: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	statistic: state.statistic,
	auth: state.auth,
	profiles: state.profile,
});

export default connect(
	mapStateToProps,
	{ getClientsStats, getDistStats, exportExcelVendorClients, getCurrentProfile, getProfilesSellers, downloadExcel, getDeletedLicences, getNewLicences, getDBRep }
)(Statistics);


import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getClientByCompVatId } from '../../actions/clientActions';
import { getPromoCodes } from '../../actions/promocodeActions';
import { createInvoice } from '../../actions/invoiceActions';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../Card/Card';
import { getzipCodes } from '../../actions/zipCodesActions';
import SelectListGroup from '../common/SelectListGroup';
import isEmpty from '../../validation/is-empty';
const Validator = require('validator');
class AddInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      loginMail: '',
      compName: '',
      compVatId: '',
      compId: '',
      compAddrStreet: '',
      compAddrNumber: '',
      compAddrZip: '',
      compAddrCity: '',
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userPhone: '',
      commercialistName: '',
      userInvoicePass: '',
      userInvoicePassConfirm: '',
      apiKey: '',
      CompanyBankAccount: '',
      isVatExcluded: false,
      vatExclusionReason: '',
      errors: {}
    };
    this.onChangeV1 = this.onChangeV1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    //
    document.getElementById("btn").disabled = true;
    setTimeout(function () { document.getElementById("btn").disabled = false; }, 1000);
    //
    const newInvoice = {
      compName: Validator.stripLow(this.state.compName.trim()),
      compVatId: Validator.stripLow(this.state.compVatId.trim()),
      compId: Validator.stripLow(this.state.compId.trim()),
      compAddrStreet: Validator.stripLow(this.state.compAddrStreet.trim()),
      compAddrNumber: Validator.stripLow(this.state.compAddrNumber.trim()),
      compAddrZip: this.state.compAddrZip,
      compAddrCity: this.state.compAddrCity,
      userFirstName: Validator.stripLow(this.state.userFirstName.trim()),
      userLastName: Validator.stripLow(this.state.userLastName.trim()),
      userEmail: Validator.normalizeEmail(this.state.userEmail.trim(),{gmail_remove_dots: false}),
      userPhone: Validator.stripLow(this.state.userPhone.trim()),
      loginMail: Validator.normalizeEmail(this.state.loginMail.trim(),{gmail_remove_dots: false}),
      commercialistName: Validator.stripLow(this.state.commercialistName.trim()),
      userInvoicePass: Validator.stripLow(this.state.userInvoicePass.trim()),
      userInvoicePassConfirm: Validator.stripLow(this.state.userInvoicePassConfirm.trim()),
      CompanyBankAccount: Validator.stripLow(this.state.CompanyBankAccount.trim()),
      isVatExcluded: this.state.isVatExcluded,
      vatExclusionReason: this.state.vatExclusionReason,
      apiKey: Validator.stripLow(this.state.apiKey.trim())
    };
    this.props.createInvoice(newInvoice, this.props.history);
  }

  componentDidMount() {
    this.props.getPromoCodes();
    this.props.getzipCodes();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.client.client) {
      const client = nextProps.client.client;

      client.compPhone = !isEmpty(client.compPhone) ? client.compPhone : '';
      client.compId = !isEmpty(client.compId) ? client.compId : '';
      client.compName = !isEmpty(client.compName) ? client.compName : '';
      client.compVatId = !isEmpty(client.compVatId) ? client.compVatId : '';
      client.userFirstName = !isEmpty(client.userFirstName) ? client.userFirstName : '';
      client.userLastName = !isEmpty(client.userLastName) ? client.userLastName : '';
      client.deviceSerialNo = !isEmpty(client.deviceSerialNo) ? client.deviceSerialNo : '';
      client.userPhone = !isEmpty(client.userPhone) ? client.userPhone : '';
      client.userEmail = !isEmpty(client.userEmail) ? client.userEmail : '';
      client.deviceAddress = !isEmpty(client.deviceAddress) ? client.deviceAddress : '';
      client.compAddrZip = !isEmpty(client.compAddrZip) ? client.compAddrZip : '';
      client.compAddrCity = !isEmpty(client.compAddrCity) ? client.compAddrCity : '';
      client.compAddrStreet = !isEmpty(client.compAddrStreet) ? client.compAddrStreet : '';
      client.compAddrNumber = !isEmpty(client.compAddrNumber) ? client.compAddrNumber : '';

      // Set component fields state
      this.setState({
        compName: client.compName,
        compId: client.compId,
        compPhone: client.compPhone,
        compVatId: client.compVatId,
        userFirstName: client.userFirstName,
        userLastName: client.userLastName,
        userPhone: client.userPhone,
        userEmail: client.userEmail,
        deviceSerialNo: client.deviceSerialNo,
        deviceAddress: client.deviceAddress,
        compAddrZip: client.compAddrZip,
        compAddrCity: client.compAddrCity,
        compAddrStreet: client.compAddrStreet,
        compAddrNumber: client.compAddrNumber
      });
    }
  }

  onChangeV1(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({
      search: event.target.value
    }, () => { if (this.state.search.length === 9) { this.props.getClientByCompVatId(this.state.search); } }
    );
  }

  handleChange() {
    this.setState({ isVatExcluded: !this.state.isVatExcluded }, () => console.log(this.state.isVatExcluded));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  }

  render() {
    const { errors } = this.state;
    const { zipcodes } = this.props.zipcode;
    const { promocodes } = this.props.promocode;

    const optionszipCodes = zipcodes.map(zipcodes => ({
      label: zipcodes.addrZip,
      value: zipcodes.addrZip
    }));

    optionszipCodes.unshift({ label: '* Izaberite poštanski br.', value: '' });

    const optionspromoCodes = promocodes.map(promocodes => ({
      label: promocodes.commercialistName,
      value: promocodes.promoCode
    }))

    optionspromoCodes.unshift({ label: '* Izaberite komercijalistu ', value: '' });

    const city = this.props.zipcode.zipcodes.filter(zipcodes => {
      return !isEmpty(this.state.compAddrZip)
        ? zipcodes.addrZip
          .toLowerCase()
          .indexOf(this.state.compAddrZip.toLowerCase()) !== -1
        : zipcodes;
    });

    if (!isEmpty(this.state.compAddrZip)) {
      // eslint-disable-next-line
      this.state.compAddrCity = city[0].addrCity;
    };


    const showReasons = this.state.isVatExcluded
      ? <div>
        <div className="col-md-6">
          <label className="control-label">Nije u sistemu PDV-A</label>
          <select
            className={classnames('form-control', {
              'is-invalid': errors.vatExclusionReason
            })}
            name="vatExclusionReason"
            value={this.state.vatExclusionReason}
            onChange={this.onChange}
          >
            <option value=''>* Izaberite</option>
            <option value='33'>33 (mali obveznik)</option>
            <option value='35'>35 (turisticka agencija)</option>

          </select>
          <div className="text-danger">
            {errors.vatExclusionReason}
          </div>
        </div>
      </div> : null

    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title="Pib za pretragu"
                content={
                  <TextFieldGroup
                    label="Pib za pretragu"
                    placeholder="Pib za pretragu"
                    name="search"
                    value={this.state.search}
                    onChange={this.onChangeV1}
                  />
                }
              />
              <Card
                title="Podaci o preduzeću"
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Naziv preduzeća *"
                          placeholder="Naziv Preduzeća"
                          name="compName"
                          value={this.state.compName}
                          onChange={this.onChange}
                          error={errors.compName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="PIB *"
                          placeholder="PIB"
                          name="compVatId"
                          value={this.state.compVatId}
                          onChange={this.onChange}
                          error={errors.compVatId}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Matični broj *"
                          placeholder="Matični broj"
                          name="compId"
                          value={this.state.compId}
                          onChange={this.onChange}
                          error={errors.compId}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-8">
                        <TextFieldGroup
                          label="Adresa *"
                          placeholder="Adresa"
                          name="compAddrStreet"
                          value={this.state.compAddrStreet}
                          onChange={this.onChange}
                          error={errors.compAddrStreet}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Broj *"
                          placeholder="Broj"
                          name="compAddrNumber"
                          value={this.state.compAddrNumber}
                          onChange={this.onChange}
                          error={errors.compAddrNumber}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <SelectListGroup
                          labels="Poštanski broj *"
                          placeholder="Poštanski broj"
                          name="compAddrZip"
                          value={this.state.compAddrZip}
                          options={optionszipCodes}
                          onChange={this.onChange}
                          error={errors.compAddrZip}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Grad"
                          placeholder="Grad"
                          name="compAddrCity"
                          value={this.state.compAddrCity}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                    </div>
                  </form>
                }
              />
              <Card
                title="Podaci o korisniku"
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Ime korisnika *"
                          placeholder="Ime korisnika"
                          name="userFirstName"
                          value={this.state.userFirstName}
                          onChange={this.onChange}
                          error={errors.userFirstName}
                        />
                      </div>
                      <div className="col-md-8">
                        <TextFieldGroup
                          label="Prezime korisnika *"
                          placeholder="Prezime korisnika"
                          name="userLastName"
                          value={this.state.userLastName}
                          onChange={this.onChange}
                          error={errors.userLastName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Login mail *"
                          placeholder="Login mail"
                          name="loginMail"
                          value={this.state.loginMail}
                          onChange={this.onChange}
                          error={errors.loginMail}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Kontakt telefon *"
                          placeholder="Kontakt telefon"
                          name="userPhone"
                          value={this.state.userPhone}
                          onChange={this.onChange}
                          error={errors.userPhone}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="E-mail adresa korisnika *"
                          placeholder="E-mail adresa korisnika"
                          name="userEmail"
                          type="userEmail"
                          value={this.state.userEmail}
                          onChange={this.onChange}
                          error={errors.userEmail}
                        />
                      </div>
                      <div className="col-md-6">
                        <SelectListGroup
                          labels="Promo kod komercijaliste"
                          placeholder="Promo kod komercijaliste"
                          name="commercialistName"
                          value={this.state.commercialistName}
                          options={optionspromoCodes}
                          onChange={this.onChange}
                          error={errors.commercialistName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Lozinka za E-fakture *"
                          placeholder="Lozinka za E-fakture"
                          name="userInvoicePass"
                          type="userInvoicePass"
                          value={this.state.userInvoicePass}
                          onChange={this.onChange}
                          error={errors.userInvoicePass}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Potvrda lozinke za E-fakture *"
                          placeholder="Potvrda lozinke za E-fakture"
                          name="userInvoicePassConfirm"
                          type="userInvoicePassConfirm"
                          value={this.state.userInvoicePassConfirm}
                          onChange={this.onChange}
                          error={errors.userInvoicePass}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Api kljuc *"
                          placeholder="Api kljuc"
                          name="apiKey"
                          type="apiKey"
                          value={this.state.apiKey}
                          onChange={this.onChange}
                          error={errors.apiKey}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Broj tekuceg racuna *"
                          placeholder="Broj tekuceg racuna"
                          name="CompanyBankAccount"
                          type="CompanyBankAccount"
                          value={this.state.CompanyBankAccount}
                          onChange={this.onChange}
                          error={errors.CompanyBankAccount}
                        />
                      </div>

                    </div>
                    <div className='row'>
                      <div>
                        <label>
                          <input type="checkbox"
                            value={this.state.isVatExcluded}
                            onChange={this.handleChange}
                            name="isVatExcluded"
                            label="U sistemu PDV-a"
                            style={{ width: '25px', height: '25px', marginRight: '10px', marginTop: '10px' }}
                          />
                          Nije u sistemu PDV-a
                        </label>
                      </div>
                      {showReasons}
                    </div>
                  </form>
                }
              />
              <Card
                title=""
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          id="btn"
                          type="submit"
                          value="Aktiviraj licencu"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                    </div>
                    <div className="stats" >* su označena obavezna polja</div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddInvoice.propTypes = {
  getClientByCompVatId: PropTypes.func.isRequired,
  createInvoice: PropTypes.func.isRequired,
  getzipCodes: PropTypes.func.isRequired,
  getPromoCodes: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  zipcode: PropTypes.object.isRequired,
  promocode: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client,
  invoice: state.invoice,
  zipcode: state.zipcode,
  promocode: state.promocode,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { createInvoice, getzipCodes, getPromoCodes, getClientByCompVatId }
)(withRouter(AddInvoice));


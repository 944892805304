import React, { Component } from 'react';
import { connect } from 'react-redux';
import dashboardRoutes from '../../routes/dashboard.jsx';
//import HeaderLinks from './HeaderLinks.jsx';
import { NavItem, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { logoutUser } from '../../actions/authActions';
import { clearCurrentProfile } from '../../actions/profileAction';
//import Snowfall from'react-snowfall';

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false,
      width: window.innerWidth
    };
  }
  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    var node = document.createElement('div');
    //node.id = 'bodyClick';
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks =
      this.props.auth.user.position !== 'Klijent' ? (
        <div>
          <Nav pullright='true'>
            <a eventkey={1}
              href="/clients"
              style={{ color: '#9a9a9a' }}>
              <i className="fa fa-dashboard" />
            </a>
          </Nav>
          <Nav pullright='true'>
            <a eventkey={2}
              style={{ color: '#9a9a9a' }}
              href="/edit-profile">
              {user.name}
            </a>
          </Nav>
          <Nav pullright='true'>
            <a
              eventkey={3}
              style={{ color: '#9a9a9a' }}
              href="/"
              onClick={this.onLogoutClick.bind(this)}
            >
              Log out
            </a>
          </Nav>
        </div>
      ) : (
        <div>
          <Nav pullright='true'>
            <a eventkey={1}
              href="/"
              style={{ color: '#9a9a9a' }}>
              <i className="fa fa-dashboard" />
            </a>
          </Nav>
          <Nav pullright='true'>
            <a eventkey={2}
              style={{ color: '#9a9a9a' }}
              href="/profile-client">
              {user.name}
            </a>
          </Nav>
          <Nav pullright='true'>
            <a
              style={{ color: '#9a9a9a' }}
              eventkey={3}
              href="/"
              onClick={this.onLogoutClick.bind(this)}
            >
              Log out
            </a>
          </Nav>
        </div>
      );

    const guestLinks = (
      <div>
        <Nav pullright='true'>
          <NavItem
            eventkey={1}
            href="/login"
            style={{ color: '#9a9a9a' }}>
            Login
          </NavItem>
        </Nav>
      </div>
    );

    const wideButton = (
      <input
        type='button'
        value='Sidebar'
        onClick={this.mobileSidebarToggle.bind(this)}
        className="nav-link px-2 link-secondary"
        style={{ border: 'none', background: '#ffffff', color: '#9a9a9a' }} />
    );
    const notWideButton = (
      <input
        type='button'
        value='-'
        onClick={this.mobileSidebarToggle.bind(this)}
        className="nav-link px-2 link-secondary"
        style={{ border: 'none', background: '#ffffff', color: '#9a9a9a' }} />
    );

    return (
      <header className="mb-3 border-bottom" style={{ background: '#ffffff', minHeight: '72px' }}>
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start" style={{ width: '100%' }}>
            <div className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
              {this.state.width <= 991 ? wideButton : notWideButton}
            </div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              {isAuthenticated ? authLinks : guestLinks}
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(Header);

import React from 'react'
import InactiveLicence from './InactiveLicence'
import ClientPhone from './ClientPhone'
import ExcelUpload from './ExcelUpload'

const tools = () => {
  return (
    <div className='content'>
   <InactiveLicence/>
   <ClientPhone/>
   <ExcelUpload />
    </div>

   
  )
}

export default tools
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Accordion, Container, Row, Col, Table, Button } from 'react-bootstrap';
import Card from '../../components/Card/Card';
import ReactToExcel from 'react-html-table-to-excel';

const DeletedClientByWho = () => {
  const [deletedClients, setDeletedClients] = useState([]);
  const [who, setWho] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateUntil, setDateUntil] = useState('');

  function formatirajDatum(datum) {
    const options = { day: 'numeric', month: 'numeric', year: 'numeric',hour:'numeric', minute:'numeric' };
    const lokalizovaniDatum = new Date(datum).toLocaleDateString('en-GB', options);
    return lokalizovaniDatum.split('.').join('.');
  }


  const DeletedByWho = async () => {
    try {
      const search = {};
  
      if (who) {
        search.who = who;
      }
  
      if (dateFrom) {
        search.dateFrom = dateFrom;
      }
  
      if (dateUntil) {
        search.dateUntil = dateUntil;
      }
  
      const response = await axios.post(`/api/admin/getDeletedClientsByWho`, search);
      const byWho = response.data;
      setDeletedClients(byWho);
    } catch (error) {
      console.error(error);
    }
  };
  

  let tBody = (
    <tr>
      <td colSpan="3">Licenca sa tim podacima nije pronađena...</td>
    </tr>
  );

  if (deletedClients.length > 0) {
    tBody = deletedClients.map((client) => (
      <tr key={client._id}>
        <td>{client.compName}</td>
        <td>{client.compVatId}</td>
        <td>{client.deviceSerialNo}</td>
        <td>{client.vendorName}</td>
        <td>{client.cbLicenceId}</td>
        <td>{client.reason}</td>
        <td>{client.byWho}</td>
        <td>{formatirajDatum(client.deleted)}</td>
      </tr>
    ));
  }

  return (
    <Accordion>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <Container>
            <Row style={{ justifyContent: 'space-between' }}>
              <Col md={4}>Ko je izbrisao po vremenu</Col>
              <Col md={2} />
            </Row>
          </Container>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md={3}>
              <input
                className="form-control form-control-lg col-md-11"
                type="text"
                placeholder="Ko je izbrisao"
                style={{
                  marginBottom: '5px',
                  borderWidth: 'thin',
                  borderColor: '#000000',
                }}
                onChange={(e) => setWho(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <input
                className="form-control form-control-lg col-md-11"
                type="date"
                placeholder="Datum od"
                name="dateFrom"
                style={{
                  marginBottom: '5px',
                  borderWidth: 'thin',
                  borderColor: '#000000',
                }}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <input
                className="form-control form-control-lg col-md-11"
                type="date"
                placeholder="Datum do"
                name="dateUntil"
                style={{
                  marginBottom: '5px',
                  borderWidth: 'thin',
                  borderColor: '#000000',
                }}
                onChange={(e) => setDateUntil(e.target.value)}
              />
            </Col>
            <Col md={3}>
              <button
                className="btn-fill col-md-12 pull-center btn btn-primary btn-fill"
                onClick={DeletedByWho}
              >
                Pretraga
              </button>
            </Col>
          </Row>
          <Row>
            <Col md={6}>{`Ukupno obrisanih ${deletedClients.length}`}</Col>
          </Row>
          <Table striped hover id="table-to-xls5">
            <thead>
              <tr>
                
                <th>Ime kompanije</th>
                <th>Pib</th>
                <th>Serijski broj uređaja</th>
                <th>Prodavac</th>
                <th>ID licence</th>
                <th>Razlog brisanja</th>
                <th>Obrisao</th>
                <th>Datum brisanja</th>
              </tr>
            </thead>
            <tbody>{tBody}</tbody>
          </Table>
          <ReactToExcel
            font="TimesNewRoman"
            className="btn btn-fill btn-primary btn-sm btn-fill"
            table="table-to-xls5"
            filename={'Obrisani klijenti u odredjenom vremenskom periodu'}
            sheet="Brisanje u odredjenom periodu "
            buttonText="Izvoz u .xls"
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default DeletedClientByWho;

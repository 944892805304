import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createClient, getClientById } from '../../actions/clientActions';
import { Container, Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import isEmpty from '../../validation/is-empty';
import { Card } from '../Card/Card';
import { getCurrentProfile } from '../../actions/profileAction'
const Validator = require('validator');

class AddiClientDist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      compName: '',
      compId: '',
      compVatId: '',
      compPhone: '',
      userFirstName: '',
      userLastName: '',
      userEmail: '',
      userPhone: '',
      deviceSerialNo: '',
      deviceSerialNoOld: '',
      cbLicenceId: '',
      deviceAddress: '',
      simCard: '',
      payment: '',
      vendorName: '',
      regDate: '',
      activityCode: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    const newClient = {
      _id: this.state._id,
      activityCode: this.state.activityCode.trim(),
      compName: Validator.stripLow(this.state.compName.trim()),
      compId: Validator.stripLow(this.state.compId.trim()),
      compPhone: Validator.stripLow(this.state.compPhone.trim()),
      compVatId: Validator.stripLow(this.state.compVatId.trim()),
      userFirstName: Validator.stripLow(this.state.userFirstName.trim()),
      userLastName: Validator.stripLow(this.state.userLastName.trim()),
      userEmail: Validator.normalizeEmail(this.state.userEmail.trim(), { gmail_remove_dots: false }),
      userPhone: Validator.stripLow(this.state.userPhone.trim()),
      deviceSerialNo: Validator.stripLow(this.state.deviceSerialNo.toUpperCase().trim()),
      deviceAddress: Validator.stripLow(this.state.deviceAddress.trim()),
      deviceSerialNoOld: Validator.stripLow(this.state.deviceSerialNoOld.toUpperCase().trim()),
      cbLicenceId: this.state.cbLicenceId,
      simCard: this.state.simCard,
      payment: this.state.payment
    };
    const userName = this.props.auth.user.name;
    const userRole = this.props.auth.user.position;
    this.props.createClient(newClient, this.props.history, userRole, userName);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.client.client) {
      const client = nextProps.client.client;

      client._id = !isEmpty(client._id) ? client._id : '';
      client.activityCode = !isEmpty(client.activityCode) ? client.activityCode : '';
      client.compPhone = !isEmpty(client.compPhone) ? client.compPhone : '';
      client.compId = !isEmpty(client.compId) ? client.compId : '';
      client.compName = !isEmpty(client.compName) ? client.compName : '';
      client.compVatId = !isEmpty(client.compVatId) ? client.compVatId : '';
      client.userFirstName = !isEmpty(client.userFirstName) ? client.userFirstName : '';
      client.userLastName = !isEmpty(client.userLastName) ? client.userLastName : '';
      client.deviceSerialNo = !isEmpty(client.deviceSerialNo) ? client.deviceSerialNo : '';
      client.userPhone = !isEmpty(client.userPhone) ? client.userPhone : '';
      client.userEmail = !isEmpty(client.userEmail) ? client.userEmail : '';
      client.deviceAddress = !isEmpty(client.deviceAddress) ? client.deviceAddress : '';
      client.deviceSerialNoOld = !isEmpty(client.deviceSerialNo) ? client.deviceSerialNo : '';
      client.cbLicenceId = !isEmpty(client.cbLicenceId) ? client.cbLicenceId : '';
      client.simCard = !isEmpty(client.simCard) ? client.simCard : '';
      client.payment = !isEmpty(client.payment) ? client.payment : '';
      client.vendorName = !isEmpty(client.vendorName) ? client.vendorName : '';

      // Set component fields state
      this.setState({
        _id: client._id,
        activityCode: client.activityCode,
        compName: client.compName,
        compId: client.compId,
        compPhone: client.compPhone,
        compVatId: client.compVatId,
        userFirstName: client.userFirstName,
        userLastName: client.userLastName,
        userPhone: client.userPhone,
        userEmail: client.userEmail,
        deviceSerialNo: client.deviceSerialNo,
        deviceAddress: client.deviceAddress,
        deviceSerialNoOld: client.deviceSerialNo,
        cbLicenceId: client.cbLicenceId,
        simCard: client.simCard,
        payment: client.payment,
        vendorName: client.vendorName,
      });

      var dateFromObjectId = function (objectId) {
        return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
      };

      const a = dateFromObjectId(client._id)
      if (a !== '') {
        console.log(a.toLocaleDateString())
      }
      this.setState({ regDate: a.toLocaleDateString("en-GB") })
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    if (this.props.match.params._id) {
      this.props.getClientById(this.props.match.params._id);
    }
  }

  render() {
    const { errors } = this.state;
    const KomercijalistaSeeRegDate = this.props.auth.user.position === "Komercijalista" ?
      <div className="row">
        <div className="col-md-12">
          <TextFieldGroup
            disabled
            label="Datum registracije"
            placeholder="Datum registracije"
            name="regDate"
            value={this.state.regDate}
            onChange={this.onChange}
          />
        </div>
      </div> : null
    const KomercijalistaSeeSeller = this.props.auth.user.position === "Komercijalista" ?
      <div className="row">
        <div className="col-md-12">
          <TextFieldGroup
            disabled
            label="Prodavac"
            placeholder="Prodavac"
            name="vendorName"
            value={this.state.vendorName}
            onChange={this.onChange}
          />
        </div>
      </div> : null
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card
                title="Izmena licence "
                category="Izmena licence "
                content={
                  <form onSubmit={this.onSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          type="text"
                          label="Ime korisnika licence"
                          placeholder="Ime korisnika licence"
                          name="compName"
                          value={this.state.compName}
                          onChange={this.onChange}
                          error={errors.compName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-5">
                        <TextFieldGroup
                          disabled
                          label="Pib (compVatId)"
                          placeholder="Pib (compVatId)"
                          name="compVatId"
                          value={this.state.compVatId}
                          onChange={this.onChange}
                          error={errors.compVatId}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          disabled
                          label="Mb (compId)"
                          placeholder="Mb (compId)"
                          name="compId"
                          value={this.state.compId}
                          onChange={this.onChange}
                          error={errors.compId}
                        />
                      </div>
                      <div className="col-md-3">
                        <TextFieldGroup
                          label="Šifra delatnosti *"
                          placeholder="Šifra delatnosti"
                          name="activityCode"
                          value={this.state.activityCode}
                          onChange={this.onChange}
                          error={errors.activityCode}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon kompanije"
                          placeholder="Telefon kompanije"
                          name="compPhone"
                          value={this.state.compPhone}
                          onChange={this.onChange}
                          error={errors.compPhone}
                        />
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Ime kontakt osobe"
                          placeholder="Ime kontakt osobe"
                          name="userFirstName"
                          value={this.state.userFirstName}
                          onChange={this.onChange}
                          error={errors.userFirstName}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Prezime kontakt osobe"
                          placeholder="Prezime kontakt osobe "
                          name="userLastName"
                          value={this.state.userLastName}
                          onChange={this.onChange}
                          error={errors.userLastName}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Telefon kontakt osobe"
                          placeholder="Telefon kontakt osobe"
                          name="userPhone"
                          value={this.state.userPhone}
                          onChange={this.onChange}
                          error={errors.userPhone}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Email kontakt osobe"
                          placeholder="Email kontakt osobe"
                          name="userEmail"
                          value={this.state.userEmail}
                          onChange={this.onChange}
                          error={errors.userEmail}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Serijski broj uređaja"
                          placeholder="Serijski broj uređaja"
                          name="deviceSerialNo"
                          value={this.state.deviceSerialNo}
                          onChange={this.onChange}
                          error={errors.deviceSerialNo}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Adresa uređaja"
                          placeholder="Adresa uređaja"
                          name="deviceAddress"
                          value={this.state.deviceAddress}
                          onChange={this.onChange}
                          error={errors.deviceAddress}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label className="control-label">Sim kartica</label>
                        <select className={classnames('form-control')}
                          name="simCard"
                          value={this.state.simCard}
                          onChange={this.onChange}
                        >
                          <option value="">* Poseduje sim karticu *</option>
                          <option value="Da">Da</option>
                          <option value="Ne">Ne</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <label className="control-label">Kartično placanje</label>
                        <select className={classnames('form-control')}
                          name="payment"
                          value={this.state.payment}
                          onChange={this.onChange}
                        >
                          <option value="">* Poseduje kartično placanje </option>
                          <option value="Aktivno">Aktivno (Ima kartično plaćanje)</option>
                          <option value="Neaktivno">Neaktivno (Nema kartično plaćanje)</option>
                          <option value="Suspendovano">Suspendovano (Ima kartično plaćanje, trenutno suspendovano)</option>
                        </select>
                      </div>
                    </div>
                    {KomercijalistaSeeSeller}
                    {KomercijalistaSeeRegDate}
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          type="submit"
                          value="Sacuvaj"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Link
                          to="/clients"
                          className="btn-fill col-md-12 pull-center btn btn-default"
                          style={{ margin: '3px' }}
                        >
                          Nazad na Klijenti
                        </Link>
                      </div>
                    </div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

AddiClientDist.propTypes = {
  getClientById: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  createClient: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  client: state.client,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getClientById, createClient, getCurrentProfile }
)(withRouter(AddiClientDist));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TextFieldGroup from '../common/TextFieldGroup';
import {
  getCurrentProfile,
  passwordReset
} from '../../actions/profileAction';
import { withRouter } from 'react-router-dom';
import isEmpty from '../../validation/is-empty';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from '../../components/Card/Card.jsx';
import { UserCard } from '../../components/UserCard/UserCard.jsx';
import { confirmAlert } from 'react-confirm-alert';
import axios from 'axios';

class CreateProfile extends Component {
  constructor(props) {
    super();
    this.state = {
      phone: '',
      oldpassword: '',
      password: '',
      password2: '',
      email: '',
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onPasswordReset = this.onPasswordReset.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile.profile) {
      const profile = nextProps.profile.profile;

      profile.phone = !isEmpty(profile.phone) ? profile.phone : '';
      profile.branch = !isEmpty(profile.branch) ? profile.branch : '';
      profile.service = !isEmpty(profile.service) ? profile.service : '';
      profile.email = !isEmpty(profile.email) ? profile.email : '';
      profile.oldpassword = !isEmpty(profile.oldpassword) ? profile.oldpassword : '';
      profile.password = !isEmpty(profile.password) ? profile.password : '';
      profile.password2 = !isEmpty(profile.password2) ? profile.password2 : '';

      // Set component fields state
      this.setState({
        phone: profile.phone,
        email: profile.email
      });
    }
  }

  FCK(e){
    e.preventDefault();
    const data = {
      tid: '10000952',
      transactionId: '1000095223214115250'
    }

    axios.post(`/api/ips/refundTransaction/`, data)
    .then(resp => {
      console.log('1')
    })
    .catch(err => {
      console.log('2')
    })
  }
  onSubmit(e) {
    e.preventDefault();
    const profileData = {
      phone: this.state.phone,
      email: this.state.email
    };

    this.props.createProfile(profileData, this.props.history);
  }

  onPasswordReset(e) {
    e.preventDefault();
    const profileData2 = {
      email: this.state.email,
      oldpassword: this.state.oldpassword,
      password: this.state.password,
      password2: this.state.password2
    };
    
    confirmAlert({
      title: 'Resetuj lozinku',
      message: 'Da li sigurno zelite da resetujete?',
      buttons: [
        {
          label: 'Da',
          onClick: () =>
            this.props.passwordReset(profileData2, this.props.history)
        },
        {
          label: 'Ne',
          onClick: () => ''
        }
      ]
    });
    //this.props.passwordReset(profileData2, this.props.history);
  }

  fileSelectedHandler = event => {
    console.log(event.target.files[0]);
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    // pristupamo errors preko state jer je u componentWillReceiveProps
    // setovan state
    const { errors } = this.state;
    const { user } = this.props.auth;
    //Select options for Service
    //const options = services.map(service => ({
    //  label: service.name,
    //  value: service._id
    //}));

    return (
      // <div className="create-profile">
      //   <div className="container">
      //     <div className="row">
      //       <div className="col-md-8 m-auto">
      //         <h1 className="display-4 text-center">Edit Profile</h1>
      //         <small className="d-block pb-3">*= required fields</small>
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Izmena podataka"
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-5">
                        <TextFieldGroup
                          label="Korisnicko ime"
                          name="name"
                          value={user.name}
                          onChange={this.onChange}
                          disabled="disabled"
                        />
                      </div>
                      
                      {/*<div className="col-md-3">
                        <SelectListGroup
                          labels="Servis"
                          placeholder="Service"
                          name="service"
                          value={this.state.service}
                          onChange={this.onChange}
                          options={options}
                          error={errors.service}
                        />
                      </div>*/}
                    </div>
                    <div className="row">
                      {/*<div className="col-md-12">
                        <TextFieldGroup
                          label="Adresa predstavnistva"
                          placeholder="Ogranak"
                          name="branch"
                          value={this.state.branch}
                          onChange={this.onChange}
                          error={errors.branch}
                        />
                      </div>*/}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextFieldGroup
                          label="Email adresa"
                          placeholder="Email"
                          name="email"
                          value={this.state.email}
                          error={errors.email}
                          disabled="disabled"
                        />
                      </div>
                      <Row>
                        <div className="col-md-4">
                          <TextFieldGroup
                            label="Kontakt telefon"
                            placeholder="* Telefon"
                            name="phone"
                            value={this.state.phone}
                            error={errors.phone}
                            disabled="disabled"
                        />
                        </div>
                       </Row>
                    </div>
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <UserCard
                bgImage="https://www.ambientum.com/wp-content/uploads/2018/08/via-lactea.jpg"
                avatar={user.avatar}
                name={user.name}
                userName={this.state.email}
                description={
                  <span>
                    {this.state.phone}
                    <br />
                    {this.state.branch}
                    <br />
                  </span>
                }
                socials={
                  <div className="row">
                    {/* <div className="col-md-6">
                      <input
                        disabled
                        className="btn btn-info"
                        type="file"
                        onChange={this.fileSelectedHandler}
                      />
                    </div>
                    <div className="col-md-6">
                      <button
                        type="submit"
                        disabled
                        className="btn-fill btn btn-info"
                      >
                        <span className="btn-label">
                          <i className="fa fa-upload" />
                        </span>
                        Upload
                      </button>
                    </div> */}
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                title="Password Reset"
                category="* Da biste resetovali lozinku potrebno je uneti staru lozinku"
                content={
                  <form onSubmit={this.onPasswordReset}>
                    <div className="row">
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Stara lozinka"
                          type="password"
                          name="oldpassword"
                          value={this.state.oldpassword}
                          onChange={this.onChange}
                          error={errors.password}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Nova lozinka"
                          name="password"
                          type="password"
                          value={this.state.password}
                          onChange={this.onChange}
                          error={errors.password2}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextFieldGroup
                          label="Ponovi lozinku"
                          name="password2"
                          type="password"
                          value={this.state.password2}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <input
                      type="submit"
                      value="Reset"
                      className="btn-fill pull-right btn btn-danger"
                    />
                    <div className="clearfix"> </div>
                  </form>
                }
              />
            </Col>
            <Col>
            <input 
            type="submit"
            value="Test"
            className="btn-fill pull-right btn btn-danger"
            onClick={this.FCK.bind(this)}/>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

CreateProfile.propTypes = {
  passwordReset: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

// Povlacimo podatak iz root reducera
// state.service je vrednost koju uzimamo iz root reducera
// da pristupimo koristimo const { services } = this.props.services
// gde se drugo services odnosi na vrednost iz mapStateToProps
const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile,  passwordReset }
)(withRouter(CreateProfile));

import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getCurrentProfile } from '../../actions/profileAction';
import { getClientById, createNewHistoryLog, getAllHistoryLogs, callIsSolved } from '../../actions/clientActions';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Card from '../Card/Card';
import SelectListGroup from '../common/SelectListGroup';
import TextFieldGroup from '../common/TextFieldGroup';
//import classnames from 'classnames';
import isEmpty from '../../validation/is-empty';
import Swal from 'sweetalert2'

class HistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSerialNo: '',
      compVatId: '',
      comment: '',
      logStatus: '',
      problemType: '',
      isDisabled: false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.client.client) {
      const client = nextProps.client.client;

      client.compVatId = !isEmpty(client.compVatId) ? client.compVatId : '';
      client.deviceSerialNo = !isEmpty(client.deviceSerialNo) ? client.deviceSerialNo : '';

      this.setState({
        compVatId: client.compVatId,
        deviceSerialNo: client.deviceSerialNo,
      });
    }
  }

  OnClickSolved(id) {
    this.props.callIsSolved(id)
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ isDisabled: true })
    setTimeout(() => this.setState({ isDisabled: false }), 3000);
    const data = {
      comment: this.state.comment,
      logStatus: this.state.logStatus,
      compVatId: this.state.compVatId,
      deviceSerialNo: this.state.deviceSerialNo,
      problemType: this.state.problemType,
    }
    const navCode = this.props.auth.user.NavCode;
    if (this.state.logStatus !== '' && this.state.problemType !== '') {
      this.props.createNewHistoryLog(data, navCode);
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      Toast.fire({
        icon: 'error',
        title: 'Odaberite status unosa/razlog'
      })
    }
  }

  componentDidMount() {
    this.props.getCurrentProfile();
    if (this.props.match.params._id) {
      this.props.getClientById(this.props.match.params._id);
      this.props.getAllHistoryLogs(this.props.match.params._id);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => console.log(e.target.value));
  }

  render() {
    const { loading } = this.props.history;
    let { clientsCallHistory } = this.props.client;
    const optionsStatus = [
      { label: 'Izaberite status', value: '' },
      { label: 'Resen', value: 'Resen' },
      { label: 'U toku', value: 'U toku' }
    ]
    const optionsProblemType = [
      { label: 'Odaberite razlog', value: '' },
      { label: 'Uspavana licenca', value: '1' },
      { label: 'Avansni račun', value: '2' },
      { label: 'PDV grupe', value: '3' },
      { label: 'Izveštaji', value: '4' },
      { label: 'Kartično plaćanje', value: '5' },
      { label: 'E-fakture', value: '6' },
      { label: 'Obrada', value: '8' },
      { label: 'Ostalo', value: '7' },
      { label: 'Storniranje', value: '9' },
      { label: 'Van mreže', value: '10' },
      { label: 'Neaktivna licenca', value: '11' },
      { label: 'Boot mode', value: '12' },
      { label: 'Klijent', value: '13' },
      { label: 'Neprispeli računi', value: '14' }
    ]

    let callHistoryItems;
    if (clientsCallHistory === null || loading) {
      callHistoryItems = (
        <tr>
          <td>
            <Spinner />
          </td>
        </tr>
      );
    } else {
      if (clientsCallHistory.length > 0) {
        callHistoryItems = clientsCallHistory.map(history => (
          <tr key={history._id}>
            <td>{history.dateOfCall.replace('T', ' ').replace('Z', '').replace(':00.000', '')}</td>
            <td style={{ wordBreak: 'break-word' }}>{history.comment}</td>
            <td>{history.dateOfStatusChange}</td>
            <td>{history.logStatus}</td>
            <td>{(() => {
              switch (history.problemType) {
                case '1':
                  return (
                    <span>
                      Uspavana licenca
                    </span>
                  );
                case '2':
                  return (
                    <span>
                      Avansni račun
                    </span>
                  );
                case '3':
                  return (
                    <span>
                      PDV grupe
                    </span>
                  );
                case '4':
                  return (
                    <span>
                      Izveštaji
                    </span>
                  );
                case '5':
                  return (
                    <span>
                      Kartično pl
                    </span>
                  );
                case '6':
                  return (
                    <span>
                      E-fakture
                    </span>
                  );
                case '7':
                  return (
                    <span>
                      Ostalo
                    </span>
                  );
                case '8':
                  return (
                    <span>
                      Obrada
                    </span>
                  );
                case '9':
                  return (
                    <span>
                      Storniranje
                    </span>
                  );
                case '10':
                  return (
                    <span>
                      Van mreže
                    </span>
                  );
                case '11':
                  return (
                    <span>
                      Neaktivna licenca
                    </span>
                  );
                case '12':
                  return (
                    <span>
                      Boot mode
                    </span>
                  );
                case '13':
                  return (
                    <span>
                      Klijent
                    </span>
                  );
                case '14':
                  return (
                    <span>
                      Neprispeli računi
                    </span>
                  );
                default:
                  return null;
              }
            })()}</td>
            <td>{history.deviceSerialNo}</td>
            <td>{history.callAnsweredBy}</td>
            <td>{history.logStatus === 'Resen' ? '' :
              (
                <button
                  type="button"
                  className="btn-fill btn btn-sm btn-danger"
                  onClick={this.OnClickSolved.bind(this, history._id)}
                >
                  Resen
                </button>
              )}
            </td>
          </tr>
        ));
      }
      else {
        callHistoryItems = (
          <tr>
            <td>Nema stavki za prikaz...</td>
          </tr>
        );
      }
    }

    return (
      <div className='content'>
        <Container fluid>
          <Row>
            <div className="clearfix"> </div>
            <Col md={3}>
              <Card
                title="Informacije o pozivu"
                category="Forma za unos informacija o pozivu"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <form onSubmit={this.onSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Serijski broj"
                          placeholder="Serijski broj"
                          name="deviceSerialNo"
                          value={this.state.deviceSerialNo}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextFieldGroup
                          label="Pib"
                          placeholder="Pib"
                          name="compVatId"
                          value={this.state.compVatId}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <textarea
                          class="form-control"
                          label="Komentar"
                          placeholder="Komentar"
                          name="comment"
                          value={this.state.comment}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className="col-md-12">
                        <SelectListGroup
                          labels="Razlog"
                          placeholder="Razlog"
                          name="problemType"
                          value={this.state.problemType}
                          options={optionsProblemType}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <SelectListGroup
                          labels="Status"
                          placeholder="Status"
                          name="logStatus"
                          value={this.state.logStatus}
                          options={optionsStatus}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <input
                          disabled={this.state.isDisabled}
                          type="submit"
                          value="Unesi"
                          className="btn-fill col-md-12 pull-center btn btn-info"
                          style={{ margin: '3px' }}
                        />
                      </div>
                    </div>
                  </form>
                }
              />
            </Col>
            <Col md={9}>
              <Card
                title="Istorija poziva"
                category="informacije o istoriji poziva"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th>Vreme poziva</th>
                        <th>Komentar</th>
                        <th>Datum statusa</th>
                        <th>Status</th>
                        <th>Razlog poziva</th>
                        <th>Serijski broj</th>
                        <th>Agent</th>
                      </tr>
                    </thead>
                    <tbody>{callHistoryItems}</tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

HistoryTable.propTypes = {
  getClientById: PropTypes.func.isRequired,
  callIsSolved: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  createNewHistoryLog: PropTypes.func.isRequired,
  getAllHistoryLogs: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  client: state.client,
  auth: state.auth,
  profiles: state.profile,
});

export default connect(
  mapStateToProps,
  { getClientById, getCurrentProfile, createNewHistoryLog, getAllHistoryLogs, callIsSolved }
)(HistoryTable);
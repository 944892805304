import axios from 'axios';
import { saveAs } from 'file-saver';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap'
import Card from '../../components/Card/Card'

const ClientPhone = () => {
  const [clientPhone, setClientPhone] = useState([]);
  const [checkedValue, setCheckedValue] = useState('');

  const getAllPhone = async () => {
    const response = await axios.post(`/api/admin/allClientPhone`, { checkedValue });
    setClientPhone(response.data);
  };

  useEffect(() => {
    getAllPhone();
  }, [checkedValue]);

  const handleCheck = (event) => {
    const { value, checked } = event.target;

    if (value === 'komercijalisti' && checked) {
      setCheckedValue('K');
    } else if (value === 'distributeri' && checked) {
      setCheckedValue('D');
    } else {
      setCheckedValue('');
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const response = await axios.post(`/api/admin/allClientPhone`, { checkedValue }, { responseType: 'blob' });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      saveAs(blob, 'clients.xlsx');
    } catch (error) {
      console.error('Greška prilikom preuzimanja Excel datoteke:', error);
    }
  };

  return (
    <>
    <Row style={{ marginTop: '40px' }}>
      <Col md={6} className="mx-auto">
        <Card
          title="Brojevi telefona"
          category="Brojevi telefona po komercijalistima i distributerima"
          ctTableFullWidth
          ctTableResponsive
          content={
            <>
              <div className="d-flex flex-column align-items-center"> 
                <div className="checkbox-container mb-3">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      value="distributeri"
                      checked={checkedValue === 'D'}
                      onChange={handleCheck}
                    />
                    <label className="form-check-label text-dark" htmlFor="flexSwitchCheckDefault">
                      Distributeri
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      value="komercijalisti"
                      checked={checkedValue === 'K'}
                      onChange={handleCheck}
                    />
                    <label className="form-check-label text-dark" htmlFor="flexSwitchCheckChecked">
                      Komercijalisti
                    </label>
                  </div>
                </div>
  
                <Button className='btn-primary btn-fill' onClick={handleDownloadExcel}>Preuzmi Excel</Button>
              </div>
            </>
          }
        />
      </Col>
    </Row>
  </>
  
  );
};

export default ClientPhone;

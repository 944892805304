import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';

import imagine from '../../assets/img/sidebar-5.jpg';
import logo from '../../assets/img/Logo-Galeb-1.png';
//import Snowfall from 'react-snowfall';

import distRoute from '../../routes/dist.jsx'
import dashboardRoutes from '../../routes/dashboard.jsx';
import adminRoutes from '../../routes/admin.jsx';
import clientuserRoutes from '../../routes/clientuser.jsx';
import serviceRoutes from '../../routes/serviser.jsx';
import pmRoutes from '../../routes/pm.jsx';
import komercRoutes from '../../routes/komer.jsx';
import RegOnlyRoutes from '../../routes/regOnlyRoutes.jsx';
import eshopRoutes from '../../routes/eshop';

import { logoutUser } from '../../actions/authActions';
import komercDistRoutes from '../../routes/KomerDist.jsx';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  async checkUser() {
    setInterval(async () => {
      if (this.props.auth.user.id != null) {
        await axios.post(`/api/users/logOutSuspendedUser/`, { 'id': this.props.auth.user.id })
          .then(resp => {
            if (resp.data.allowed == true) {
              this.props.logoutUser();
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    }, 60000)

  }

  async componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
    this.checkUser()
  }

  render() {
    const sidebarBackground = {
      backgroundImage: 'url(' + imagine + ')'
    };
    let route;
    if (this.props.auth.isAuthenticated) {
      console.log(this.props.auth.user)
      if (this.props.auth.user.position === 'Admin') {
        route = adminRoutes;
        console.log("admin");
      } else if (this.props.auth.user.position === 'Klijent') {
        route = clientuserRoutes;
        console.log("klijent");
      } else if (this.props.auth.user.position === 'Serviser') {
        route = serviceRoutes;
        console.log("serviser");
      } else if (this.props.auth.user.position === 'PM') {
        route = pmRoutes;
        console.log("pm");
      } else if (this.props.auth.user.position === 'Dist') {
        route = distRoute;
        console.log("dist");
      } else if (this.props.auth.user.position === 'Komercijalista') {
        if (this.props.auth.user.distManager == "Yes") {
          route = komercDistRoutes
        } else {
          route = komercRoutes;
        }
        console.log('Komercijalista')
      } else if (this.props.auth.user.position === 'RegOnly') {
        route = RegOnlyRoutes;
        console.log('RegOnly')
      } else if (this.props.auth.user.position === 'EShop') {
        route = eshopRoutes;
        console.log('EShop')
      }
      else {
        route = dashboardRoutes;
        console.log("undefined");
      }
    } else {
      route = [{ redirect: true, path: '/', to: '/login', name: 'Login' }];
    }

    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color="black"
        data-image={imagine}

      >

        <div className="sidebar-background" style={sidebarBackground} />
        <div className="logo">
          <a
            href="/login"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </a>
          <a
            href="/login"
            className="simple-text logo-normal"
            style={{ textDecoration: 'none' }}
          >
            Galeb Tech Tool
          </a>
        </div>
        <div className="sidebar-wrapper" >
          {/*<Snowfall
          snowflakeCount={30}
          />*/}
          <ul className="nav">
            {/*
              this.state.width <= 991 ? <HeaderLinks /> : null
              */}
            {route.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? 'active active-pro'
                        : this.activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <span>{prop.name}</span>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          </ul>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(Sidebar);

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import { Container, Row, Col, Table } from 'react-bootstrap/';
import Spinner from '../common/Spinner';
import Swal from 'sweetalert2'
import Card from '../Card/Card';
import { Link } from 'react-router-dom';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})

function ClientsDistDuty() {
  const [vendorArray, setVendorArray] = useState([]);
  const [linkState, setLinkState] = useState('');
  const [position, setPosition] = useState('');
  const [eInvoicesArray, setEInvoicesArray] = useState([]);
  const [vendorStats, setVendorStats] = useState([{ Aktivno: 0, Neaktivno: 0 }]);
  const [tableData, setTableData] = useState([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateUntil, setDateUntil] = useState('');
  const [vendor, setVendor] = useState('');

  useEffect(() => {
    console.log()
    axios.get('/api/users/current')
      .then(resp => {
        setPosition(resp.data.position)
        if (resp.data.position === 'Admin') {
          setLinkState('EInvoices/ChangeInvoiceData')
        } else {
          setLinkState('EInvoicesDist/ChangeInvoiceData')
        }
      })
      .catch(err => {
        console.log(err)
      })
    axios.get('/api/users/sellers')
      .then(resp => {
        const data = resp.data
        let finalData = []
        for (let i = 0; i < data.length; i++) {
          if (data[i].NavCode.length > 4 && isNaN(data[i].NavCode) === false) {
            finalData.push(data[i])
          }
        }
        setVendorArray(finalData)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const onchangeSelect = (item) => {
    setVendor(item);
  };

  const getAllData = () => {
    const data = {
      dateFrom: dateFrom,
      dateUntil: dateUntil,
      navCodeTime: vendor.NavCode
    }
    if (dateFrom == '' || dateUntil == '' || vendor.NavCode == '' || vendor.NavCode == undefined) {
      Toast.fire({
        icon: 'error',
        title: 'Nisu uneti datumi ili distributer'
      })
      return
    }
    axios.post('/api/admin/getDeteledClientsByDate', data)
      .then(resp => {
        setTableData(resp.data)
      })
      .catch(err => {
        console.og(err)
      })

    axios.get(`/api/statistics/distStatistics/${vendor.NavCode}`)
      .then(resp => {
        console.log(resp.data)
        setVendorStats(resp.data)
      })
      .catch(err => {
        console.log(err)
      })

    axios.get(`/api/invoices/getAllInvoicesVendor/${vendor.NavCode}`)
      .then(resp => {
        setEInvoicesArray(resp.data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  let deletedClientsAdminByVendor;
  if (tableData === null) {
    deletedClientsAdminByVendor = (
      <tr>
        <td>
          <Spinner />
        </td>
      </tr>
    );
  } else {
    if (tableData.length > 0) {
      deletedClientsAdminByVendor = tableData.map(client => (
        <tr key={client._id}>
          <td>{client.dateOfActivation.split('T00:00:00.000Z')}</td>
          <td>{client.dateOfRemoval.split('T00:00:00.000Z')}</td>
          <td style={{ wordBreak: 'break-word' }}>{client.compName}</td>
          <td>{client.compVatId}</td>
          <td>{client.vendorName}</td>
          <td>{client.deviceSerialNo}</td>
          <td>{client.reason}</td>
        </tr>
      ));
    }
    else {
      deletedClientsAdminByVendor = (
        <tr>
          <td>Nema podataka za prikaz </td>
        </tr>
      );
    }
  }

  let eInvoiceData;
  if (eInvoicesArray === null) {
    eInvoiceData = (
      <tr>
        <td>
          <Spinner />
        </td>
      </tr>
    )
  } else {
    if (eInvoicesArray.length > 0) {
      eInvoiceData = eInvoicesArray.map(eInv => (
        <tr key={eInv._id}>
          <td style={{ wordBreak: 'break-word' }}>{eInv.compName}</td>
          <td>{eInv.compVatId}</td>
          <td style={{ wordBreak: 'break-word' }}>{eInv.loginMail}</td>
          <td>{eInv.userPhone}</td>
          <td>{eInv.userFirstName}   {eInv.userLastName}</td>
          <td>{(() => {
            switch (true) {
              case eInv.isVatExcluded != null:
                return (
                  <span>
                    Ne
                  </span>
                );
              case eInv.isVatExcluded == null:
                return (
                  <span>
                    Da
                  </span>
                );
              default:
                return null;
            }
          })()}
          </td>
          <td>
            <div className="col-md-6">
              <Link
                to={`/${linkState}/${eInv._id}`}
                target="_blank"
                className="btn-fill btn btn-sm btn-danger"
                style={{ margin: '3px' }}
              >
                info
              </Link>
            </div>
          </td>
        </tr>
      ))
    } else {
      eInvoiceData = (
        <tr>
          <td>Nema podataka za prikaz </td>
        </tr>
      );
    }
  }



  return (
    <div className='content'>
      <Container fluid>
        <Row style={{ marginLeft: '4px', marginRight: '4px' }}>
          <Col md={9}>
            <Card
              content={
                <Row>
                  <Col md={3}>
                    <Select
                      value={vendor}
                      onChange={onchangeSelect}
                      options={vendorArray}
                      getOptionValue={(option) => option.NavCode}
                      getOptionLabel={(option) => option.name}
                      style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000', height: '100%' }}
                    />
                  </Col>
                  <Col md={3}>
                    <input
                      className="form-control form-control-lg col-md-11"
                      type='date'
                      style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
                      onChange={(e) => setDateFrom(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <input
                      className="form-control form-control-lg col-md-11"
                      type='date'
                      style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
                      onChange={(e) => setDateUntil(e.target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <input type="button"
                      value="Test"
                      className='btn-fill col-md-12 pull-center btn btn-info'
                      onClick={() => getAllData()}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={3}>
            <Card
              content={
                <Col>Licence sa sim karticom: {vendorStats[0].Aktivno}</Col>
              }
            />
          </Col>
          <Col md={3}>
            <Card
              content={
                <Col>Licence bez sim kartice: {vendorStats[0].Neaktivno}</Col>
              }
            />
          </Col>
          <Col md={3}>
            <Card
              content={
                <Col>Ukupan broj: {vendorStats[0].Aktivno + vendorStats[0].Neaktivno}</Col>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              content={
                <Table>
                  <thead>
                    <tr>
                      <th>Datum aktivacije</th>
                      <th>Datum brisanja</th>
                      <th>Ime kompanije</th>
                      <th>Pib</th>
                      <th>Nav sifra prodavca</th>
                      <th>Serijski broj uređaja</th>
                      <th>Razlog brisanja</th>
                    </tr>
                  </thead>
                  <tbody>{deletedClientsAdminByVendor}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card
              content={
                <Table>
                  <thead>
                    <tr>
                      <th>Ime kompanije</th>
                      <th>PIB (VatId)</th>
                      <th>Login mail</th>
                      <th>Telefon kontakt osobe</th>
                      <th>Ime i prezime</th>
                      <th>U sistemu PDV-a</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{eInvoiceData}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(mapStateToProps, {})(withRouter(ClientsDistDuty));
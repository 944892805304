import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const CreateProduct = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [packageSizes, setPackageSizes] = useState({
    S: "",
    M: "",
    L: "",
  });

  // Function to update package size quantities
  const handlePackageSizeChange = (size, quantity) => {
    setPackageSizes({
      ...packageSizes,
      [size]: quantity,
    });
  };

  // Function to create the product
  const handleCreate = async (e) => {
    e.preventDefault();
    try {
      let answer = await Swal.fire({
        title: "Da li ste sigurni?",
        text: "Ova radnja ne može biti poništena!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Da, kreiraj!",
        cancelButtonText: "Odustani",
      });
      if (answer.isConfirmed) {
        const productData = {
          name,
          description,
          price,
          packageSizes,
        };

        const { data } = await axios.post(
          "/backendeshop/api/v1/product/create-product",
          productData
        );

        window.location.href = "/galebshop/products";
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Greška!", "Došlo je do greške prilikom kreiranja proizvoda.", "error");
    }
  };

  return (
    <div className="content">
      <div className="container-fluid m-3 p-3 dashboard">
        <div className="row">
          <div className="col-md-8">
            <h2>Kreiranje proizvoda</h2>
            <div className="m-1 w-75">
              <div className="mb-3">
                <input
                  type="text"
                  value={name}
                  placeholder="Naziv"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <textarea
                  type="text"
                  value={description}
                  placeholder="Napiši opis proizvoda"
                  className="form-control"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <input
                  type="number"
                  value={price}
                  placeholder="Cena"
                  className="form-control"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>

              <div className="mb-3">
                <p>Veličine paketa:</p>
                <div className="form-group">
                  <label>S:</label>
                  <input
                    type="number"
                    value={packageSizes.S}
                    className="form-control"
                    onChange={(e) => handlePackageSizeChange("S", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>M:</label>
                  <input
                    type="number"
                    value={packageSizes.M}
                    className="form-control"
                    onChange={(e) => handlePackageSizeChange("M", e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>L:</label>
                  <input
                    type="number"
                    value={packageSizes.L}
                    className="form-control"
                    onChange={(e) => handlePackageSizeChange("L", e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-3">
                <button className="btn-fill col-md-5 pull-center btn btn-primary" onClick={handleCreate}>
                  Kreiraj proizvod
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProduct;

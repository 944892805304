import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap';
import Card from '../Card/Card';
import { Accordion } from 'react-bootstrap';

const LogsByAgent = () => {
    const [logs, setLogs]= useState([])
    const [navCode, setNavCode]= useState()
    const [selectedStatus, setSelectedStatus] = useState('')
    const [dateFrom, setDateFrom] = useState('');
    const [dateUntil, setDateUntil] = useState('');
    
    
    console.log(selectedStatus)
 
    const SearchLogsByAgent = async ()=>{
        const search = {};
        if(selectedStatus){
            search.status = selectedStatus
        }
  
        if (navCode) {
          search.NavCode = navCode;
        }
    
        if (dateFrom) {
          search.dateFrom = dateFrom;
        }
    
        if (dateUntil) {
          search.dateUntil = dateUntil;
        }
        
        const response = await axios.post(`/api/utility/getAgentLogs`,search)
        const dataLogs = response.data
        console.log(search)
        setLogs(dataLogs)
    }
var tBody = (
    <tr>
    <td colSpan="3">Log sa tim podacima nije pronađena...</td>
  </tr>
)
if(logs.length > 0 ){
tBody = 
    logs.map(log => (
        <tr key={log._id}>
          <td>{log.dateOfCall.replace('T', ' ').replace('Z', '').replace(':00.000', '')}</td>
          <td style={{ wordBreak: 'break-word' }}>{log.comment}</td>
          <td>{log.dateOfStatusChange}</td>
          <td>{(() => {
              switch (log.problemType) {
                case '1':
                  return (<span>Uspavana licenca</span>);
                case '2':
                  return (<span>Avansni račun</span>);
                case '3':
                  return (<span>PDV grupe</span>);
                case '4':
                  return (<span>Izveštaji</span>);
                case '5':
                  return (<span>Kartično pl</span>);
                case '6':
                  return (<span>E-fakture</span>);
                case '7':
                  return (<span>Ostalo</span>);
                case '8':
                  return (<span>Obrada</span>);
                default:
                  return null;
              }
            })()}</td>
          <td>{log.deviceSerialNo}</td>
          <td>{log.logStatus}</td>
          <td>{log.callAnsweredBy}</td>
          
        </tr>

      ))}

    
  return (
    <Accordion>
    <Accordion.Item eventKey='0'>
      <Accordion.Header>
        <Container>
          <Row style={{ justifyContent: 'space-between' }}>
            <Col md={2}>
              Logovi po agentu
            </Col>
            <Col md={2}>
            </Col>
          </Row>
        </Container>
      </Accordion.Header>
      <Accordion.Body>
        <Row>
          <div className="clearfix"> </div>
          <Col md={2}>
            <input
              className="form-control form-control-lg col-md-11"
              type="text"
              placeholder="NavCode agenta"
              name="search"
              style={{ marginBottom: '5px', borderWidth: 'thin', borderColor: '#000000' }}
              onChange={(e) => setNavCode(e.target.value)}
            />
          </Col>
<Col md = {2}>
      <div className="form-group d-flex align-items-center ">
        <label className='d-flex'>Izaberite status:</label>
        <select
          id="statusDropdown"
          className="form-control d-flex"
          value={selectedStatus}
          onChange={e => setSelectedStatus(e.target.value)}
        >
             <option value=""> Sve</option>
         <option value="Resen">Resen</option>
          <option value="U toku">U toku</option>
        </select>
      </div>
    </Col>
    <Col md={2}>
              <input
                className="form-control form-control-lg col-md-11"
                type="date"
                placeholder="Datum od"
                name="dateFrom"
                style={{
                  marginBottom: '5px',
                  borderWidth: 'thin',
                  borderColor: '#000000',
                }}
                onChange={(e) => setDateFrom(e.target.value)}
              />
            </Col>
            <Col md={2}>
              <input
                className="form-control form-control-lg col-md-11"
                type="date"
                placeholder="Datum do"
                name="dateUntil"
                style={{
                  marginBottom: '5px',
                  borderWidth: 'thin',
                  borderColor: '#000000',
                }}
                onChange={(e) => setDateUntil(e.target.value)}
              />
            </Col>
          <Col md={2}>
              <button
                className="btn-fill col-md-12 pull-center btn btn-primary btn-fill"
                onClick={SearchLogsByAgent}
              >
                Pretraga
              </button>
              </Col>
          <Col md={12}>
          <Row>
            <Col md={6}>{`Ukupno logova ${logs.length}`}</Col>
          </Row>
            <Card
              title="Istorija poziva"
              category="informacije o istoriji poziva"
              ctTableFullWidth
              ctTableResponsive
              content={
                <Table striped hover>
                 <thead>
                          <tr>
                            <th>Vreme poziva</th>
                            <th>Komentar</th>
                            <th>Datum statusa</th>
                            <th>Problem</th>
                            <th>Serijski broj</th>
                            <th>Status</th>
                            <th>Agent</th>
                          </tr>
                        </thead>
                  <tbody>{tBody}</tbody>
                </Table>
              }
            />
          </Col>
        </Row>
        
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
  )
}

export default LogsByAgent